import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { ClockRotateLeftIcon } from '@c2fo/liquidity/icons';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { isPreferredOfferNearTermEnd } from '@/utils/preferredOfferRenewal';

const RenewalRequestIcon = ({
  takerMarket,
  iconStyles,
  fill = colors.red[500],
}: {
  takerMarket: TakerMarket;
  iconStyles?: string;
  fill?: string;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (!isPreferredOfferNearTermEnd(takerMarket)) {
    return null;
  }

  const isTabLayout = pathname.includes('supplier/markets/preferred');

  return (
    <Popover>
      <Popover.Trigger>
        <ClockRotateLeftIcon
          aria-label={t('preferredRenewalModal.request.action')}
          className={iconStyles}
          fill={fill}
        />
      </Popover.Trigger>
      <Popover.Content arrow side="bottom" variant={!isTabLayout ? 'light' : 'dark'}>
        <div className="text-sm">{t('taker.termEnd.renewalPopover')}</div>
      </Popover.Content>
    </Popover>
  );
};

export default RenewalRequestIcon;
