import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LabelWithBadge from '@/enterprise/components/LabelWithBadge';
import useTakerHistory from '@/enterprise/data/useTakerHistory';
import { dateRanges } from '@/enterprise/features/history/utils';
import useFeature from '@/lib/features';
import LinkTabs from '@/shared/components/LinkTabs';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useSelectedCurrency from '@/utils/useSelectedCurrency';

const ConsolidatedHistoryLayoutComponent = () => {
  const { t } = useTranslation();
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');
  const currency = useSelectedCurrency();

  const { last30Days: defaultRange } = dateRanges;

  const { data: pendingData } = useTakerHistory({
    startDate: defaultRange.startDate,
    endDate: '',
    sort: '-payDate',
    limit: 25,
    page: 1,
    currency,
    pendingClear: true,
  });

  if (!enableAwardsEnhancement) {
    return <Outlet />;
  }

  return (
    <div>
      <h1 className="mt-0 text-4xl">{t('taker.consolidatedHistory.general')}</h1>
      <div className="rounded-md bg-white shadow-md">
        <div className="border-b px-4">
          <LinkTabs
            items={[
              {
                key: 'approved',
                label: t('core.approved'),
                link: 'approved',
                matcher: 'approved',
              },
              {
                key: 'pending',
                label: (
                  <LabelWithBadge label={t('core.pendingApproval')} badgeCount={pendingData && pendingData.length} />
                ),
                link: 'pending',
                matcher: 'pending',
              },
            ]}
            variant="secondary"
          />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

const ConsolidatedHistoryLayout = () => (
  <QueryBoundaries>
    <ConsolidatedHistoryLayoutComponent />
  </QueryBoundaries>
);

export default ConsolidatedHistoryLayout;
