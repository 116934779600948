import { useTranslation } from 'react-i18next';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import NextMarketClearTimeCountdown from '@/enterprise/features/takerMarketTable/components/NextMarketClearTimeCountdown';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';

export const OfferReviewDetails = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();

  return (
    <ul className="ml-4 list-disc space-y-2">
      <li>
        {t('offerSubmitDialog.marketClearMessage')}
        <span className="ml-1">
          <NextMarketClearTimeCountdown takerMarket={takerMarket} />
        </span>
      </li>
      <li>{`${t('offerSubmitDialog.paymentInitiateMessage')} ${trimDateTimestamp(takerMarket.marketPayDate)}`}</li>
      <li>{t('offerSubmitDialog.steps.clearing')}</li>
      <li>{t('offerSubmitDialog.disclaimer')}</li>
    </ul>
  );
};
