import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from '@c2fo/liquidity';
import AdditionalAgreementsModal from '@/enterprise/components/AdditionalAgreementsModal';
import { useAgreementIds, useIsAgreementRequired } from '@/enterprise/data/useAgreements';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import { useAgreementsUrl } from '@/utils/useAgreementsUrl';

interface AgreementsProps {
  takersMarkets: TakerMarket[];
  onChange?: (value: boolean) => void;
}
export const Agreement = ({ onChange, takersMarkets }: AgreementsProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [isAdditionalAgreementsModalOpen, setIsAdditionalAgreementsModalOpen] = useState(false);
  const uniqueMarketUuids = Array.from(new Set(takersMarkets.map((takerMarket) => takerMarket.marketUuid)));
  const { data: agreements = [] } = useAgreementIds(uniqueMarketUuids);
  const isCheckboxRequired = useIsAgreementRequired(uniqueMarketUuids);
  const { trackEnterpriseEvent } = useReporting();

  const { privacyLink, termsOfUseLink: c2foAdditionalTermsConditionsUrl } = useAgreementsUrl();

  const showStandardAgreement = !uniqueMarketUuids.every((uuid) =>
    agreements.some((agreement) => agreement.marketId === uuid)
  );

  const trackEventPayload = {
    isCheckboxRequired,
    showStandardAgreement,
    c2foAdditionalTermsConditionsUrl,
    privacyLink,
    additionalAgreementsCount: agreements.length,
    agreements,
  };

  const openAdditionalAgreementsModal = () => {
    setIsAdditionalAgreementsModalOpen(true);
    trackEnterpriseEvent('agreements::open-additional-agreements-modal', trackEventPayload);
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;

    setChecked(newValue);

    trackEnterpriseEvent('agreements::checkbox-toggled', {
      ...trackEventPayload,
      isChecked: newValue,
    });

    if (onChange) {
      onChange(newValue);
    }
  };

  const translatedLinkTags = {
    supplierAgreementLink: (
      <a
        className="font-medium text-blue-500 underline"
        href={c2foAdditionalTermsConditionsUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        {/* NOTE: This text is a placeholder and will be swapped out with value in json file tag */}
        Supplier Agreements
      </a>
    ),
    privacyPolicyLink: (
      <a className="font-medium text-blue-500 underline" href={privacyLink} rel="noopener noreferrer" target="_blank">
        {/* NOTE: This text is a placeholder and will be swapped out with value in json file tag */}
        Privacy Policy
      </a>
    ),
    additionalAgreements: (
      // We need inline flowing text here, in a way that <button> with .inline class would not work.
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        tabIndex={0}
        className="font-medium text-blue-500 underline"
        onClick={openAdditionalAgreementsModal}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            openAdditionalAgreementsModal();
          }
        }}
      >
        {/* NOTE: This text is a placeholder and will be swapped out with value in json file tag */}
        Additional Agreements (2)
      </a>
    ),
  };

  return (
    <div>
      {isCheckboxRequired ? (
        <div className="flex gap-4">
          <Checkbox
            color="primary"
            id="termsCheckbox"
            checked={checked}
            onChange={onCheckboxChange}
            aria-labelledby="termsCheckboxLabel"
          />
          {/* Avoiding the use of the label prop to prevent the checkbox from highlighting on hover/click of agreement links */}
          <p id="termsCheckboxLabel" className="pr-8 text-base">
            <Trans
              i18nKey={
                showStandardAgreement ? 'agreements.additionalWithStandard' : 'agreements.additionalWithoutStandard'
              }
              t={t}
              components={translatedLinkTags}
              values={{ count: agreements?.length ?? 0 }}
            />
          </p>
          <AdditionalAgreementsModal
            open={isAdditionalAgreementsModalOpen}
            onClose={() => setIsAdditionalAgreementsModalOpen(false)}
            agreements={agreements}
          />
        </div>
      ) : (
        <>
          <p className="text-base">
            <Trans i18nKey="agreements.standard" t={t} components={translatedLinkTags} />
          </p>
        </>
      )}
    </div>
  );
};
