import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { parseIsoDateStringToDateCorrectTz } from '@/utils/parseIsoDateStringToDate';
import { usePreferredOffersNearTermEnd } from '@/utils/preferredOfferRenewal';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import RenewalRequestModal from './RenewalRequestModal';

const PreferredAlertBanner = ({ takerMarket: takerMarketFilter }: { takerMarket?: TakerMarket }) => {
  const isMarketDetail = !!takerMarketFilter;
  const { t } = useTranslation();
  const { asDateString } = useLocaleFormat();
  const { takerMarketsNearTermEnd } = usePreferredOffersNearTermEnd();
  const [openModal, setOpenModal] = useState(false);

  const takerMarketsForBanner = useMemo(() => {
    return (takerMarketFilter ? [takerMarketFilter] : takerMarketsNearTermEnd).filter((tm) => {
      // If for some reason this tm doesn't qualify as preferred, skip it
      if (tm.offerConfig.offerType !== 'PREFERRED_TERM' || !tm.disableAfterMarketClearsDate) {
        return false;
      }

      // If we don't have the data (e.g. SEA not enabled) then we must assume the renewal is not yet requested
      if (
        tm.offerConfig.isPreferredRenewalRequested === undefined ||
        tm.offerConfig.isPreferredRenewalRequested === null
      ) {
        return true;
      }

      // Otherwise, honor the value of isPreferredRenewalRequested
      return !tm.offerConfig.isPreferredRenewalRequested;
    });
  }, [takerMarketFilter, takerMarketsNearTermEnd]);

  const soonestEndDateString = takerMarketsForBanner.reduce((earliestDate, takerMarket) => {
    if (!takerMarket.disableAfterMarketClearsDate) {
      return earliestDate;
    }

    const disableAfterMarketClearsDate = parseIsoDateStringToDateCorrectTz(takerMarket.disableAfterMarketClearsDate);

    if (
      !earliestDate ||
      disableAfterMarketClearsDate.getTime() < parseIsoDateStringToDateCorrectTz(earliestDate).getTime()
    ) {
      return takerMarket.disableAfterMarketClearsDate;
    }

    return earliestDate;
  }, '');

  if (soonestEndDateString === '') {
    return null;
  }

  const formattedTermEndDate = asDateString(trimDateTimestamp(soonestEndDateString), {
    month: 'long',
    year: 'numeric',
  });

  const description = isMarketDetail
    ? t('preferredRenewal.banner.body.marketDetail', { formattedTermEndDate })
    : takerMarketsForBanner.length > 1
    ? t('preferredRenewal.banner.body.multipleOffers')
    : t('preferredRenewal.banner.body.singleOffer', { formattedTermEndDate });

  return (
    <div className="mb-6">
      <Alert
        title={t('preferredRenewal.banner.title')}
        description={description}
        type="error"
        variant="outlined"
        full
        action={{
          text: t('preferredRenewal.banner.action'),
          onClick: () => setOpenModal(true),
        }}
      />
      <RenewalRequestModal open={openModal} onClose={() => setOpenModal(false)} takerMarkets={takerMarketsForBanner} />
    </div>
  );
};

export default PreferredAlertBanner;
