import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import calendar from '@/enterprise/assets/calendar.svg?url';
import Skeleton from '@/enterprise/components/Skeleton';
import { Table, TableBody, TableCell, TableRow } from '@/enterprise/components/Table';
import usePayDateAnnouncements, {
  PayDateAnnouncement,
  PayDateAnnouncementMarket,
} from '@/enterprise/data/usePayDateAnnouncements';
import useTakerMarkets from '@/enterprise/data/useTakerMarkets';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import { groupBy, pluck } from '@/utils/common';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';

const PayDateAnnouncementsComponent = () => {
  const { t } = useTranslation();
  const { asDateString } = useLocaleFormat();
  const { data: takerMarkets = [] } = useTakerMarkets();
  const { data: payDateAnnouncements } = usePayDateAnnouncements(
    useCallback(
      (announcements: PayDateAnnouncement[]) => {
        // filter out disabled markets
        const enabledMarketAnnoucements = announcements
          .map((announcement) => ({
            ...announcement,
            markets: announcement.markets
              .map((market) => {
                const takerMarket = takerMarkets.find(
                  (takerMarket) =>
                    takerMarket.legacyMarketId === market.id &&
                    takerMarket.makerDivisionName === market.makerDivisionName
                );

                return {
                  ...market,
                  isEnabled: takerMarket?.marketIsEnabled ?? true,
                };
              })
              .filter((market) => market.isEnabled),
          }))
          .filter((announcement) => announcement.markets.length > 0);
        // group announcements by paydate
        const groupedByPaydate = groupBy(enabledMarketAnnoucements, 'payDate');
        const formattedAnnouncements = Object.values(groupedByPaydate).map((group) => {
          // merge all the markets for each pay date into a single array
          const markets = ([] as PayDateAnnouncementMarket[]).concat(...pluck(group, 'markets'));
          return {
            // show as last day if any are true
            isLastDay: group.some((g) => g.isLastDay),
            // unique by market.makerDivisionName
            markets: [...new Map(markets.map((market) => [market.makerDivisionName, market])).values()],
            // format date for display like Dec 26
            payDate: asDateString(trimDateTimestamp(group[0].payDate), { month: 'short', year: undefined }),
          };
        });

        // after they're merged from all the datacenters above, we may have more that 5 unique payDates
        // sort them by pay date and only return the nearest 5 dates
        return formattedAnnouncements.sort((a, b) => a.payDate.localeCompare(b.payDate)).slice(0, 5);
      },
      [asDateString, takerMarkets]
    )
  );

  return (payDateAnnouncements?.length ?? 0) > 0 ? (
    <div className="mt-6 space-y-4 rounded-md bg-white px-8 pb-4 pt-8 shadow-md">
      <div className="-ml-2 flex items-center space-x-3">
        <img src={calendar} alt="calendar" className="h-14 w-14" />
        <div>
          <div className="pb-1 text-xl font-medium">{t('payDateAnnouncements.header')}</div>
          <div>{t('payDateAnnouncements.subHeader')}</div>
        </div>
      </div>
      <Table>
        <TableBody>
          {payDateAnnouncements?.map((payDateAnnouncement) => (
            <TableRow
              key={payDateAnnouncement.payDate}
              className={cn({ 'font-medium': payDateAnnouncement.isLastDay })}
            >
              <TableCell className="w-[100px] first-of-type:pl-0 last-of-type:pr-0">
                {payDateAnnouncement.payDate}
              </TableCell>
              <TableCell className="whitespace-normal text-text-secondary first-of-type:pl-0 last-of-type:pr-0">
                {payDateAnnouncement.markets.map((market) => market.makerDivisionName).join(', ')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  ) : null;
};

const PayDateAnnouncements = () => (
  <QueryBoundaries LoadingComponent={() => <Skeleton className="mt-6" />}>
    <PayDateAnnouncementsComponent />
  </QueryBoundaries>
);

export default PayDateAnnouncements;
