import { Trans, useTranslation } from 'react-i18next';
import { RecurringRule } from '@/enterprise/data/useRecurringRules';
import useLocaleFormat from '@/utils/useLocaleFormat';

interface RuleDescriptionValues {
  daysPaidEarly: { long: string | null; short: string | null };
  dueDate: { long: string | null; short: string | null };
  invoiceAmount: { long: string | null; short: string | null };
  excludedInvoiceIds: { long: string | null; short: string | null };
  labelText: string | JSX.Element | null;
  rulesToRender: RecurringRule[];
}

const useRuleDescription = (rule: RecurringRule) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();

  const ruleDescriptionValues: RuleDescriptionValues = {
    daysPaidEarly: {
      long: null,
      short: null,
    },
    dueDate: {
      long: null,
      short: null,
    },
    invoiceAmount: {
      long: null,
      short: null,
    },
    excludedInvoiceIds: {
      long: null,
      short: null,
    },
    labelText: null,
    rulesToRender: [],
  };

  const rulesToRender = Object.entries(rule)
    .flatMap(([key]) => key)
    .filter((key) => ['dpe', 'dueDate', 'amount', 'excludedVoucherIds'].includes(key));

  // rule label text
  ruleDescriptionValues.labelText =
    rulesToRender.length === 1 ? (
      t('recurringRules.excludeInvoiceWhichMeetThisCriteria')
    ) : (
      <Trans
        i18nKey="recurringRules.excludeInvoiceWhichMeetTheseCriteria"
        t={t}
        components={{ style: <span className="font-bold"></span> }}
        values={{ operator: t('recurringRules.allOf') }}
      />
    );

  // days paid early rule description
  if (rule.dpe?.gteDpe) {
    ruleDescriptionValues.daysPaidEarly.short = t('recurringRules.dpe.gte.short', { gteDpe: rule.dpe?.gteDpe });
    ruleDescriptionValues.daysPaidEarly.long = t('recurringRule.whenTheDescription', {
      rule: t('recurringRules.dpe'),
      criteria:
        rule.dpe.gteDpe === 1
          ? t('recurringRules.dpe.gte.long', { gteDpe: rule.dpe.gteDpe })
          : t('recurringRules.dpe.gte.longPlural', { gteDpe: rule.dpe.gteDpe }),
    });
  } else if (rule.dpe?.lteDpe) {
    ruleDescriptionValues.daysPaidEarly.short = t('recurringRules.dpe.lte.short', { lteDpe: rule.dpe?.lteDpe });
    ruleDescriptionValues.daysPaidEarly.long = t('recurringRule.whenTheDescription', {
      rule: t('recurringRules.dpe'),
      criteria:
        rule.dpe.lteDpe === 1
          ? t('recurringRules.dpe.lte.long', { lteDpe: rule.dpe.lteDpe })
          : t('recurringRules.dpe.lte.longPlural', { lteDpe: rule.dpe.lteDpe }),
    });
  }

  // due date rule description
  if (rule.dueDate?.fromDueDate) {
    ruleDescriptionValues.dueDate.short = t('recurringRules.dueDate.gt.short', {
      fromDueDate: rule.dueDate?.fromDueDate,
    });
    ruleDescriptionValues.dueDate.long = t('recurringRule.whenTheDescription', {
      rule: t('recurringRules.dueDate'),
      criteria: t('recurringRules.dueDate.gt.long', { fromDueDate: rule.dueDate.fromDueDate }),
    });
  } else if (rule.dueDate?.toDueDate) {
    ruleDescriptionValues.dueDate.short = t('recurringRules.dueDate.lt.short', { toDueDate: rule.dueDate?.toDueDate });
    ruleDescriptionValues.dueDate.long = t('recurringRule.whenTheDescription', {
      rule: t('recurringRules.dueDate'),
      criteria: t('recurringRules.dueDate.lt.long', { toDueDate: rule.dueDate.toDueDate }),
    });
  }

  // invoice amount rule description
  if (rule.amount?.gteInvoiceAmount) {
    ruleDescriptionValues.invoiceAmount.short = t('recurringRules.invoiceAmount.gte.short', {
      gteInvoiceAmount: asCurrency(rule.amount?.gteInvoiceAmount, rule.currency),
    });
    ruleDescriptionValues.invoiceAmount.long = t('recurringRule.whenTheDescription', {
      rule: t('recurringRules.invoiceAmount'),
      criteria: t('recurringRules.invoiceAmount.gte.long', {
        gteInvoiceAmount: asCurrency(rule.amount.gteInvoiceAmount, rule.currency),
      }),
    });
  } else if (rule.amount?.lteInvoiceAmount) {
    ruleDescriptionValues.invoiceAmount.short = t('recurringRules.invoiceAmount.lte.short', {
      lteInvoiceAmount: asCurrency(rule.amount?.lteInvoiceAmount, rule.currency),
    });
    ruleDescriptionValues.invoiceAmount.long = t('recurringRule.whenTheDescription', {
      rule: t('recurringRules.invoiceAmount'),
      criteria: t('recurringRules.invoiceAmount.lte.long', {
        lteInvoiceAmount: asCurrency(rule.amount.lteInvoiceAmount, rule.currency),
      }),
    });
  }

  // invoice ids rule description
  if (rule.excludedVoucherIds && rule.excludedVoucherIds.length) {
    ruleDescriptionValues.excludedInvoiceIds.short = t('recurringRules.invoiceId.short', {
      excludedInvoiceIds: `"${rule.excludedVoucherIds.join('", "')}"`,
    });
    ruleDescriptionValues.excludedInvoiceIds.long = t('recurringRules.invoiceId.long', {
      excludedInvoiceIds: `"${rule.excludedVoucherIds.join('", "')}"`,
    });
  }

  return { ...ruleDescriptionValues, rulesToRender };
};

export default useRuleDescription;
