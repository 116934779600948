import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { isAfter } from 'date-fns';
import { useEffectOnce } from 'react-use';
import { useMarketLayoutOutletContext } from '@/enterprise/components/MarketLayout';
import Skeleton from '@/enterprise/components/Skeleton';
import { TableSortDirection } from '@/enterprise/components/Table';
import useClearHistory, { useClearHistoryStats, usePendingClears } from '@/enterprise/data/useClearHistory';
import useTakerMarketStats from '@/enterprise/data/useTakerMarketStats';
import CreditNoteModal from '@/enterprise/features/history/CreditNoteModal';
import History from '@/enterprise/features/history/History';
import RangeHeader from '@/enterprise/features/history/RangeHeader';
import { dateRanges } from '@/enterprise/features/history/utils';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import { useToken } from '@/utils/token';
import useUrlState from '@/utils/useUrlState';

interface MarketHistoryState {
  creditNoteId: string | null;
  endDate: string;
  includeFuturePayDates: boolean;
  limit: number;
  page: number;
  pendingSort: string;
  pendingSortDirection: TableSortDirection;
  startDate: string;
  sort: string;
  sortDirection: TableSortDirection;
}

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;
const defaultRange = dateRanges.last30Days;

const defaultState = {
  creditNoteId: null,
  endDate: defaultRange.endDate,
  includeFuturePayDates: true,
  limit: 100,
  page: 1,
  pendingSort: 'created',
  pendingSortDirection: 'desc' as TableSortDirection,
  startDate: defaultRange.startDate,
  sort: 'payDate',
  sortDirection: 'desc' as TableSortDirection,
} satisfies MarketHistoryState;

function MarketHistory() {
  const { trackEnterpriseEvent } = useReporting();
  const { token } = useToken();
  const {
    urlParams: { marketId, takerId },
  } = useMarketLayoutOutletContext();
  const [searchParams] = useSearchParams();
  const [urlState, setUrlState] = useUrlState<MarketHistoryState>(defaultState);
  const {
    creditNoteId = defaultState.creditNoteId,
    endDate = defaultRange.endDate,
    includeFuturePayDates = defaultState.includeFuturePayDates,
    limit = defaultState.limit,
    page = defaultState.page,
    pendingSort = defaultState.pendingSort,
    pendingSortDirection = defaultState.pendingSortDirection,
    sort = defaultState.sort,
    sortDirection = defaultState.sortDirection,
    startDate = defaultRange.startDate,
  } = urlState;
  const [creditNoteDownloadUrl, setCreditNoteDownloadUrl] = useState<string | null>(null);
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');
  const endDateForHistory = enableAwardsEnhancement && includeFuturePayDates ? '' : endDate;

  useEffect(() => {
    if (creditNoteId) {
      setCreditNoteDownloadUrl(
        `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/retrieve-award-file/${creditNoteId}?token=${token}`
      );
    }
  }, [marketId, takerId, token, creditNoteId]);

  // Unsure how this is triggered, tracking to determine usage or just ported functionality from Legacy
  useEffectOnce(() => {
    // Using searchParams since unsure what types of query params are being used
    const download = searchParams.get('download');
    const payDate = searchParams.get('payDate');

    if (download) {
      datadogRum.addAction('market-history::paydate::direct-download', { download, payDate });
    }

    if (download && payDate) {
      // immediately initiate a download
      window.location.href = `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/archive/${payDate}/csv?token=${token}`;
    }
  });

  const { data: market } = useTakerMarketStats({ marketId, takerId });

  const {
    data: paginatedClears = [],
    isFetching: fetchingPaginatedClears,
    isPending: isPendingPaginatedClears,
  } = useClearHistory(takerId, marketId, {
    startDate,
    endDate: endDateForHistory,
    order: sort,
    orderDirection: sortDirection,
    page: page,
    limit,
  });

  const hasClearHistory = !!paginatedClears?.length;

  // Download endpoint needs an end date for the award archive, need to include latest pay date as end date
  const { data: latestPayDateData, isFetching: fetchingLatestPayDate } = useClearHistory(
    takerId,
    marketId,
    {
      startDate,
      endDate: endDateForHistory,
      order: 'payDate',
      orderDirection: 'desc',
      page: 1,
      limit: 1,
    },
    { enabled: enableAwardsEnhancement && hasClearHistory && includeFuturePayDates }
  );

  const latestPayDate = latestPayDateData?.[0]?.payDate;

  const { data: chartData } = useClearHistory(
    takerId,
    marketId,
    {
      startDate,
      endDate: endDateForHistory,
      order: 'payDate',
      orderDirection: 'asc',
    },
    { enabled: hasClearHistory }
  );

  // If the feature flag is enabled, we exclude pending clears from the stats
  const excludePending = enableAwardsEnhancement;

  const { data: stats } = useClearHistoryStats(
    takerId,
    marketId,
    excludePending,
    {
      startDate,
      endDate: endDateForHistory,
    },
    { enabled: hasClearHistory }
  );

  const { data: pendingData, isFetching: fetchingPendingData } = usePendingClears(takerId, marketId, {
    order: pendingSort,
    orderDirection: pendingSortDirection,
  });

  const useLatestPaydate = includeFuturePayDates && latestPayDate && isAfter(latestPayDate, endDate);

  const awardFileDownloadLink = useMemo(
    () =>
      `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/archive/csv?startDate=${startDate}&endDate=${
        useLatestPaydate ? latestPayDate : endDate
      }&token=${token}`,
    [token, takerId, marketId, startDate, endDate, useLatestPaydate, latestPayDate]
  );

  const closeCreditNoteModal = () => {
    setCreditNoteDownloadUrl(null);
    setUrlState({ ...urlState, creditNoteId: null });
  };

  const handleCreditNoteDownload = () => {
    window.location.href = creditNoteDownloadUrl!;
    trackEnterpriseEvent('history-credit-note-request::submitted');
    closeCreditNoteModal();
  };

  return (
    <>
      <div className="rounded-md bg-white shadow-md">
        {market && (
          <>
            <RangeHeader
              isDownloadDisabled={!hasClearHistory || fetchingLatestPayDate}
              startDate={startDate}
              endDate={endDate}
              awardFileDownloadLink={awardFileDownloadLink}
              includeFuturePayDates={enableAwardsEnhancement && includeFuturePayDates}
            />
            <History
              currency={market.currency}
              startDate={startDate}
              endDate={endDate}
              onDateRangeChange={(startDate, endDate, includeFuturePayDates) => {
                setUrlState({ ...urlState, startDate, endDate, includeFuturePayDates, page: 1 });
              }}
              sort={sortDirection === 'desc' ? `-${sort}` : sort}
              onSortChange={(sortKey) => {
                const [, desc, sort] = sortKey.match(/^(-)?(.*)$/) ?? [];
                setUrlState({ ...urlState, sort, sortDirection: desc ? 'desc' : 'asc', page: 1 });
                trackEnterpriseEvent('history-sort::clicked', { key: sortKey });
              }}
              page={page}
              onPageChange={(page) => {
                setUrlState({ ...urlState, page });
                trackEnterpriseEvent('history-page::clicked');
              }}
              limit={limit}
              onLimitChange={(limit) => {
                setUrlState({ ...urlState, limit, page: 1 });
                trackEnterpriseEvent('history-page-size::clicked', { size: limit });
              }}
              chartData={chartData ?? []}
              awardedData={paginatedClears ?? undefined}
              isFetchingAwardedData={fetchingPaginatedClears}
              isPendingAwardedData={isPendingPaginatedClears}
              pendingData={pendingData}
              isFetchingPendingData={fetchingPendingData}
              stats={stats ?? undefined}
              historyType="market"
              rangeHasClearHistory={hasClearHistory}
              includeFuturePayDates={includeFuturePayDates}
              onIncludeFuturePayDatesChange={(value) => {
                setUrlState({ ...urlState, includeFuturePayDates: value });
                trackEnterpriseEvent('history-include-future-pay-dates::clicked', { value });
              }}
            />
          </>
        )}
      </div>
      <CreditNoteModal
        handleDownload={handleCreditNoteDownload}
        onClose={closeCreditNoteModal}
        open={!!creditNoteDownloadUrl}
      />
    </>
  );
}

export default function MarketHistoryPage() {
  return (
    <QueryBoundaries LoadingComponent={() => <Skeleton />}>
      <MarketHistory />
    </QueryBoundaries>
  );
}
