import { Navigate, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useReporting } from '@/reporting';

const NavigateWithRedirect = ({ to }: { to: string }) => {
  const { trackMultiExperienceEvent } = useReporting();
  const { pathname } = useLocation();

  useEffectOnce(() => {
    trackMultiExperienceEvent('user::redirected', {
      requestedPath: pathname,
    });
  });

  return <Navigate to={to} />;
};

export default NavigateWithRedirect;
