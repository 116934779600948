import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MarketTypeSelector, { MarketTypeSelectorItem } from '@/enterprise/components/MarketTypeSelector';
import Skeleton from '@/enterprise/components/Skeleton';
import useTakerMarkets from '@/enterprise/data/useTakerMarkets';
import useTakerMarketsGroups from '@/enterprise/data/useTakerMarketsGroups';
import { useIntercom } from '@/reporting/Intercom';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import TabActions from './components/TabActions';
import NoAvailableMarkets from './NoAvailableMarkets';
import tabLinks from './tabLinks';

const useSendTabDataToIntercom = (tabs: MarketTypeSelectorItem[]) => {
  const { setIntercomSettings } = useIntercom();

  useEffect(() => {
    if (tabs) {
      const tabKeys = tabs.map((t) => t.key);

      setIntercomSettings({
        name_your_rate_tab: tabKeys.includes('NAME_YOUR_RATE'),
        fixed_rate_tab: tabKeys.includes('FIXED'),
        preferred_tab: tabKeys.includes('PREFERRED'),
        variable_rate_tab: tabKeys.includes('BENCHMARK'),
        inactive_tab: tabKeys.includes('INACTIVE'),
      });
    }
  }, [setIntercomSettings, tabs]);
};

const getTabTitle = (translatedLabel: string, takerMarketCount: number) => {
  return takerMarketCount > 0 ? `${translatedLabel} (${takerMarketCount})` : translatedLabel;
};

const TakerMarketTabsComponent = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const data = useTakerMarketsGroups();
  const { data: takerMarkets } = useTakerMarkets();

  const { rollup: nyrRollup, takerMarkets: nyrTakerMarkets } = data['NAME_YOUR_RATE'];

  const tabItems: MarketTypeSelectorItem[] = [
    {
      key: 'NAME_YOUR_RATE',
      active: pathname === tabLinks.NAME_YOUR_RATE,
      amount: nyrRollup.amount,
      count: nyrRollup.count,
      label: getTabTitle(t('core.nameYourRate'), nyrRollup.count),
      link: tabLinks.NAME_YOUR_RATE,
      newDivisionCount: nyrRollup.newDivisionCount,
      takerMarkets: nyrTakerMarkets,
    },
  ];

  if (data['BENCHMARK'].rollup.count > 0) {
    const { rollup, takerMarkets } = data['BENCHMARK'];
    tabItems.push({
      key: 'BENCHMARK',
      active: pathname === tabLinks.BENCHMARK,
      amount: rollup.amount,
      count: rollup.count,
      label: getTabTitle(t('core.variableRate'), rollup.count),
      link: tabLinks.BENCHMARK,
      newDivisionCount: rollup.newDivisionCount,
      takerMarkets,
    });
  }

  if (data['PREFERRED'].rollup.count > 0) {
    const { rollup, takerMarkets } = data['PREFERRED'];
    tabItems.push({
      key: 'PREFERRED',
      active: pathname === tabLinks.PREFERRED,
      amount: rollup.amount,
      count: rollup.count,
      label: getTabTitle(t('core.preferredRate'), rollup.count),
      link: tabLinks.PREFERRED,
      newDivisionCount: rollup.newDivisionCount,
      takerMarkets,
    });
  }

  if (data['FIXED'].rollup.count > 0) {
    const { rollup, takerMarkets } = data['FIXED'];
    tabItems.push({
      key: 'FIXED',
      active: pathname === tabLinks.FIXED,
      amount: rollup.amount,
      count: rollup.count,
      label: getTabTitle(t('core.fixedRate'), rollup.count),
      link: tabLinks.FIXED,
      newDivisionCount: rollup.newDivisionCount,
      takerMarkets,
    });
  }

  if (data['INACTIVE'].rollup.count > 0) {
    const { rollup, takerMarkets } = data['INACTIVE'];
    tabItems.push({
      key: 'INACTIVE',
      active: pathname === tabLinks.INACTIVE,
      amount: rollup.amount,
      count: rollup.count,
      label: getTabTitle(t('core.inactiveMarkets'), rollup.count),
      link: tabLinks.INACTIVE,
      newDivisionCount: rollup.newDivisionCount,
      takerMarkets,
    });
  }

  useSendTabDataToIntercom(tabItems);

  const activeTab = tabItems.find((item) => item.active)?.key ?? 'NAME_YOUR_RATE';

  // if the supplier does not have any associated taker markets then render a message
  // that displays there are no available markets and directs them to add customers
  if (!takerMarkets?.length) {
    return <NoAvailableMarkets />;
  }

  return (
    <IntercomDataTarget target="earlyPaymentMarkets">
      <h3 className="mb-6 mt-10 font-medium">{t('core.earlyPaymentMarkets')}</h3>
      <div>
        <MarketTypeSelector items={tabItems} />
        <div className="rounded-md border-2 border-transparent bg-white shadow-md lg:rounded-t-none lg:border-lightBlue-500">
          <TabActions activeTab={activeTab} />
          <Outlet />
        </div>
      </div>
    </IntercomDataTarget>
  );
};

const TakerMarketTabs = () => (
  <QueryBoundaries LoadingComponent={() => <Skeleton className="mt-6" />}>
    <TakerMarketTabsComponent />
  </QueryBoundaries>
);

export default TakerMarketTabs;
