import { useTranslation } from 'react-i18next';
import { Toggle, useSnackbar } from '@c2fo/liquidity';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useToggleOffer, { ToggleOfferParams } from '@/enterprise/data/useToggleOffer';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';

const VariableRateParticipationToggle = ({
  disabled = false,
  openEditOfferModal,
  takerMarket,
}: {
  openEditOfferModal: (takerMarket: TakerMarket) => void;
  takerMarket: TakerMarket;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions([takerMarket]);
  const { mutateAsync: toggleOffer, isPending } = useToggleOffer();

  const isDisabledDueToClosedMarket = !takerMarket.offerConfig.isEnabled && takerMarket.marketIsEnabled === false;
  const toggleDisabled = disabled || isPending || !canEditOffers || isDisabledDueToClosedMarket;

  const toggleParticipationOff = async () => {
    const submitValues = {
      marketUuid: takerMarket.offerConfig.marketUuid,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
      seaUuid: takerMarket.offerConfig.uuid ?? undefined,
      frequency: takerMarket.offerConfig.frequency,
    } satisfies ToggleOfferParams;

    return await toggleOffer(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('offerSubmitDialog.participationUpdated') });
        trackEnterpriseEvent('participation::variable-rate::submitted', {
          ...submitValues,
          offerConfig: {
            ...takerMarket.offerConfig,
            isEnabled: !takerMarket.offerConfig.isEnabled,
          },
        });
      },
      onError: () => showSnackbar({ message: t('offerSubmitDialog.participationError') }),
    });
  };

  const onClick = async () => {
    // if offer is turned on, toggle participation off without showing anything
    // if offer is turned off, send to the set variable rate offer modal
    if (takerMarket.offerConfig.isEnabled) {
      await toggleParticipationOff();
    } else {
      openEditOfferModal(takerMarket);
    }
  };

  return (
    <Toggle
      accessibilityLabel={t('core.toggleParticipation')}
      checked={takerMarket.offerConfig.isEnabled ?? false}
      disabled={toggleDisabled}
      loading={isPending}
      onCheckedChange={onClick}
      size="lg"
    />
  );
};

export default VariableRateParticipationToggle;
