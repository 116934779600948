import { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject, useLocation } from 'react-router-dom';
import accountSettingsRouteConfig from '@/accountSettings/Router';
import { enterpriseBasename } from '@/constants';
import ConsolidatedHistoryLayout from '@/enterprise/components/ConsolidatedHistoryLayout';
import HomepageLayout from '@/enterprise/components/HomepageLayout';
import InvoiceLayout from '@/enterprise/components/InvoiceLayout';
import MarketHistoryLayout from '@/enterprise/components/MarketHistoryLayout';
import MarketLayout from '@/enterprise/components/MarketLayout';
import DraftOffer from '@/enterprise/features/draftOffer/DraftOffer';
import { PendingConsolidatedHistory } from '@/enterprise/features/history/PendingConsolidatedHistory';
import { PendingMarketHistory } from '@/enterprise/features/history/PendingMarketHistory';
import FixedRateMarketTable from '@/enterprise/features/takerMarketTable/FixedRateMarketTable';
import InactiveMarketTable from '@/enterprise/features/takerMarketTable/InactiveMarketTable';
import NameYourRateMarketTable from '@/enterprise/features/takerMarketTable/NameYourRateMarketTable';
import PreferredRateMarketTable from '@/enterprise/features/takerMarketTable/PreferredRateMarketTable';
import VariableRateMarketTable from '@/enterprise/features/takerMarketTable/VariableRateMarketTable';
import TakerMarketTabs from '@/enterprise/features/takerMarketTabs/TakerMarketTabs';
import AwardedInvoices from '@/enterprise/pages/AwardedInvoices';
import ConsolidatedHistory from '@/enterprise/pages/ConsolidatedHistory';
import EligibleInvoices from '@/enterprise/pages/EligibleInvoices';
import IneligibleInvoices from '@/enterprise/pages/IneligibleInvoices';
import InvoiceAdjustments from '@/enterprise/pages/InvoiceAdjustments';
import MarketHistory from '@/enterprise/pages/MarketHistory';
import MarketSearch from '@/enterprise/pages/MarketSearch';
import RecurringRulesManager from '@/enterprise/pages/RecurringRulesManager';
import findYourCustomerRouteConfig from '@/findYourCustomer/Router';
import { FeatureFlagEnabledRoute, FeatureFlagProtectedRoute } from '@/shared/components/FeatureFlagProtectedRouting';
import FullPageLoader from '@/shared/components/FullPageLoader';
import NavigateWithRedirect from '@/shared/components/NavigateWithRedirect';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import useNewExperienceEnabled from '@/utils/useNewExperienceEnabled';

const App = lazy(() => import('@/enterprise/App'));

export const EnterpriseRedirect = () => {
  return <Navigate to={`/${enterpriseBasename}/markets`} />;
};

const NewExperienceEnabledGuard = () => {
  const { pathname } = useLocation();
  const newExperienceEnabled = useNewExperienceEnabled();
  const allowedEnterpriseRoute = asEnterpriseLink('history');

  // for new experience enabled users, /history is the only allowed route
  if (newExperienceEnabled && !pathname.includes(allowedEnterpriseRoute)) {
    return <NavigateWithRedirect to={allowedEnterpriseRoute} />;
  }

  return <Outlet />;
};

const enterpriseRouteConfig: RouteObject = {
  path: enterpriseBasename,
  element: (
    <Suspense fallback={<FullPageLoader />}>
      <App />
    </Suspense>
  ),
  children: [
    {
      element: <NewExperienceEnabledGuard />,
      children: [
        { index: true, element: <EnterpriseRedirect /> },
        {
          path: 'markets',
          element: <HomepageLayout />,
          children: [
            {
              element: <TakerMarketTabs />,
              children: [
                {
                  index: true,
                  element: <NameYourRateMarketTable />,
                },
                {
                  path: 'inactive',
                  element: <InactiveMarketTable />,
                },
                {
                  path: 'fixed',
                  element: <FixedRateMarketTable />,
                },
                {
                  path: 'preferred',
                  element: <PreferredRateMarketTable />,
                },
                {
                  path: 'variable',
                  element: <VariableRateMarketTable />,
                },
                {
                  // catch all route for /markets/:route that does not exist
                  path: '*',
                  element: <NavigateWithRedirect to="/markets" />,
                },
              ],
            },
          ],
        },
        {
          path: 'markets/search',
          element: (
            <FeatureFlagEnabledRoute
              featureFlag="enterprise-ui_enableSeaFindYourCustomer"
              redirectTo={asEnterpriseLink('find-your-customers')}
            >
              <MarketSearch />
            </FeatureFlagEnabledRoute>
          ),
        },
        {
          path: 'markets/:marketId/division/:takerId',
          element: <MarketLayout />,
          children: [
            {
              // index route for /markets/:marketId/division/:takerId
              index: true,
              element: <NavigateWithRedirect to="invoices/eligible" />,
            },
            {
              path: 'invoices',
              element: <InvoiceLayout />,
              children: [
                {
                  // index route for /markets/:marketId/division/:takerId/invoices
                  index: true,
                  element: <NavigateWithRedirect to="eligible" />,
                },
                {
                  path: 'adjustments',
                  element: <InvoiceAdjustments />,
                },
                {
                  path: 'awarded',
                  element: <AwardedInvoices />,
                },
                {
                  path: 'eligible',
                  element: <EligibleInvoices />,
                },
                {
                  path: 'ineligible',
                  element: <IneligibleInvoices />,
                },
                {
                  // catch all route for /markets/:marketId/division/:takerId/invoices/:route that does not exist
                  path: '*',
                  element: <NavigateWithRedirect to="eligible" />,
                },
              ],
            },
            {
              path: 'history',
              // ff on, tab format. ff off, just Outlet with MarketLayout context passed.
              element: <MarketHistoryLayout />,
              children: [
                {
                  index: true,
                  element: (
                    <FeatureFlagEnabledRoute
                      featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                      redirectTo="approved"
                    >
                      <MarketHistory />
                    </FeatureFlagEnabledRoute>
                  ),
                },
                {
                  path: 'approved',
                  // ff on, Approved. ff off, Division page.
                  element: (
                    <FeatureFlagProtectedRoute
                      featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                      redirectTo=".."
                    >
                      <MarketHistory />
                    </FeatureFlagProtectedRoute>
                  ),
                },
                {
                  path: 'pending',
                  // ff on, Pending. ff off, Division page.
                  element: (
                    <FeatureFlagProtectedRoute
                      featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                      redirectTo=".."
                    >
                      <PendingMarketHistory />
                    </FeatureFlagProtectedRoute>
                  ),
                },
              ],
            },
            {
              // catch all route for /markets/:marketId/division/:takerId/:route that does not exist
              path: '*',
              element: <NavigateWithRedirect to="invoices/eligible" />,
            },
          ],
        },
        {
          path: 'history',
          element: <ConsolidatedHistoryLayout />,
          children: [
            {
              index: true,
              element: (
                <FeatureFlagEnabledRoute
                  featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                  redirectTo="approved"
                >
                  <ConsolidatedHistory />
                </FeatureFlagEnabledRoute>
              ),
            },
            {
              path: 'approved',
              element: (
                <FeatureFlagProtectedRoute
                  featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                  redirectTo="/supplier/history"
                >
                  <ConsolidatedHistory />
                </FeatureFlagProtectedRoute>
              ),
            },
            {
              path: 'pending',
              element: (
                <FeatureFlagProtectedRoute
                  featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                  redirectTo="/supplier/history"
                >
                  <PendingConsolidatedHistory />
                </FeatureFlagProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'recurring-rules',
          element: <RecurringRulesManager />,
        },
        {
          path: 'offer/:draftOfferUuid',
          element: <DraftOffer />,
        },
        findYourCustomerRouteConfig,
        accountSettingsRouteConfig,
      ],
    },
  ],
};

export default enterpriseRouteConfig;
