import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  cn,
  Label,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
  Popover,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
  useSnackbar,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import { useReporting } from '@/reporting';
import useExchangeRate from '@/shared/data/useExchangeRate';
import useBuyers from '../data/useBuyers';
import useCreateBuyersLinkingRequests, {
  CreateBuyersLinkingRequestsInput,
} from '../data/useCreateBuyersLinkingRequests';
import { CustomerCard } from './CustomerCard';
import { CustomerIcon } from './CustomerIcon';

interface LinkToYourCustomersModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedCustomerUuids: string[];
  onSuccess: () => void;
}

interface LinkCustomerInput {
  vendorId: string;
  currency?: string;
  outstandingAr?: string;
  dba?: string;
  additionalInformation?: string;
}

type CustomerDataMap = Record<string, LinkCustomerInput>;

const defaultValues: LinkCustomerInput = {
  vendorId: '',
  currency: 'USD',
  outstandingAr: '',
  dba: '',
  additionalInformation: '',
};

export const LinkToYourCustomersModal = ({
  open,
  setOpen,
  selectedCustomerUuids,
  onSuccess,
}: LinkToYourCustomersModalProps) => {
  const { t } = useTranslation();
  const { trackMultiExperienceEvent } = useReporting();
  const { data: allCustomers } = useBuyers();
  const { data: exchangeRates } = useExchangeRate('USD');
  const { mutateAsync: createBuyersLinkingRequests, isPending } = useCreateBuyersLinkingRequests();
  const showSnackbar = useSnackbar();

  const currencies = useMemo(() => Object.keys(exchangeRates ?? {}), [exchangeRates]);

  const formSteps = selectedCustomerUuids.length > 1 ? [...selectedCustomerUuids, 'review'] : selectedCustomerUuids;
  const [currentFormStepIndex, setCurrentFormStepIndex] = useState(0);
  const isReadyToSubmit = currentFormStepIndex === formSteps.length - 1;
  const currentStep = formSteps[currentFormStepIndex];
  const isReviewStep = currentStep === 'review';
  const currentCustomerUuid = isReviewStep ? undefined : currentStep;

  const currentCustomer = useMemo(
    () => allCustomers?.find((customer) => customer.uuid === currentCustomerUuid),
    [currentCustomerUuid, allCustomers]
  );

  const isBackHidden = formSteps.length === 1 || currentFormStepIndex === 0;

  const [customerData, setCustomerData] = useState<CustomerDataMap>({});

  const { register, getValues, reset, handleSubmit, formState, setValue, watch } = useForm<LinkCustomerInput>({
    defaultValues,
  });

  const onNext = async () => {
    const updatedCustomerData = currentCustomerUuid
      ? {
          ...customerData,
          [currentCustomerUuid]: getValues(),
        }
      : customerData;
    setCustomerData(updatedCustomerData);

    if (isReadyToSubmit) {
      try {
        const payload: CreateBuyersLinkingRequestsInput = Object.keys(updatedCustomerData).map((uuid) => ({
          buyerUuid: uuid,
          vendorIds: [updatedCustomerData[uuid].vendorId],
          currency: updatedCustomerData[uuid].currency,
          approximateAmountReceivable: updatedCustomerData[uuid].outstandingAr
            ? parseInt(updatedCustomerData[uuid].outstandingAr, 10)
            : undefined,
          businessName: updatedCustomerData[uuid].dba,
          additionalInformation: updatedCustomerData[uuid].additionalInformation,
        }));
        await createBuyersLinkingRequests(payload);

        trackMultiExperienceEvent('find-your-customers::link-your-customers::submit::success', payload);

        onSuccess();
        setOpen(false);
        setCustomerData({});
      } catch (error) {
        showSnackbar({ message: t('core.error') });
        trackMultiExperienceEvent('find-your-customers::link-your-customers::submit::error');
      }

      return;
    }

    setCurrentFormStepIndex((prevIndex) => prevIndex + 1);
  };

  const onBack = () => {
    const updatedCustomerData = currentCustomerUuid
      ? {
          ...customerData,
          [currentCustomerUuid]: getValues(),
        }
      : customerData;
    setCustomerData(updatedCustomerData);
    setCurrentFormStepIndex((prevIndex) => prevIndex - 1);
  };

  /**
   * Reset the form state values when the current customer uuid changes.
   */
  useEffect(() => {
    if (currentCustomerUuid) {
      reset(customerData[currentCustomerUuid] ?? defaultValues);
    }
  }, [currentCustomerUuid, customerData, reset]);

  /**
   * Reset form step when selected customers change externally.
   */
  useEffect(() => {
    setCurrentFormStepIndex(0);
    reset(defaultValues);
    setCustomerData({});
  }, [reset, selectedCustomerUuids]);

  const formNode = (
    <form onSubmit={handleSubmit(onNext)} className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <Label size="sm" htmlFor="vendorId">
          <span className="flex items-center gap-1">
            <span>{t('findYourCustomers.form.vendorId.title')}</span>
            <Popover>
              <Popover.Trigger>
                <InfoCircleIcon fill={colors.gray[700]} />
              </Popover.Trigger>
              <Popover.Content arrow>
                <p className="max-w-96">{t('findYourCustomers.form.vendorId.tooltip')}</p>
              </Popover.Content>
            </Popover>
            <span>({t('core.required')})</span>
          </span>
        </Label>
        <TextInput
          data-testid="vendorIdInput"
          className="w-full"
          hasError={!!formState.errors.vendorId}
          id="vendorId"
          {...register('vendorId', { required: true })}
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <Label size="sm" id="currencyLabel">
            {t('findYourCustomers.form.currency.title')}
          </Label>
          <Select onValueChange={(value) => setValue('currency', value)} value={watch('currency')}>
            <SelectTrigger aria-labelledby="currencyLabel">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {currencies.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="col-span-2">
          <Label size="sm" htmlFor="outstandingAr">
            {t('findYourCustomers.form.ar.title')}
          </Label>
          <TextInput
            type="number"
            className="w-full"
            hasError={!!formState.errors.outstandingAr}
            id="outstandingAr"
            {...register('outstandingAr')}
          />
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Label size="sm" htmlFor="dba">
          {t('findYourCustomers.form.dba.title')}
        </Label>
        <TextInput className="w-full" hasError={!!formState.errors.dba} id="dba" {...register('dba')} />
      </div>

      <div className="flex flex-col gap-1">
        <Label size="sm" htmlFor="additionalInformation">
          {t('findYourCustomers.form.additionalInformation.title')}
        </Label>
        <TextInput
          className="w-full"
          hasError={!!formState.errors.additionalInformation}
          id="additionalInformation"
          {...register('additionalInformation')}
        />
      </div>
    </form>
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="sm">
      <ModalTitle>{t('findYourCustomers.linking.modal.title')}</ModalTitle>
      <ModalContent className="flex flex-col gap-8">
        {isReviewStep ? (
          <div className="flex flex-col gap-1">
            <h2 className="m-0 p-0 text-xl">{t('findYourCustomers.linking.modal.review.subtitle')}</h2>
            <p className="m-0 p-0 text-sm text-text-secondary">
              {t('findYourCustomers.linking.modal.review.description')}
            </p>
          </div>
        ) : (
          <div className="flex flex-col-reverse gap-4 sm:flex-row sm:items-center">
            <div className="flex w-full shrink-0 flex-col items-center justify-between gap-2 rounded-lg border p-2 sm:w-[30%]">
              <div className="flex min-h-[80px] min-w-[80px] items-center justify-center">
                <CustomerIcon buyerName={currentCustomer?.buyerName ?? '-'} buyerLogo={currentCustomer?.buyerLogo} />
              </div>
              <p className="m-0 w-full border-t p-0 pt-2 text-xs font-semibold text-text-secondary">
                {currentCustomer?.buyerName}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <h2 className="m-0 p-0 text-xl">{t('findYourCustomers.linking.modal.add.subtitle')}</h2>
              <p className="m-0 p-0 text-sm text-text-secondary">
                {t('findYourCustomers.linking.modal.add.description')}
              </p>
            </div>
          </div>
        )}

        {isReviewStep ? (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {Object.keys(customerData).map((uuid) => {
              const customer = allCustomers?.find((customer) => customer.uuid === uuid);
              return (
                <CustomerCard
                  key={uuid}
                  customerName={customer?.buyerName ?? ''}
                  customerLogoUrl={customer?.buyerLogo}
                  onClick={() => setCurrentFormStepIndex(formSteps.indexOf(uuid))}
                />
              );
            })}
          </div>
        ) : (
          formNode
        )}
      </ModalContent>
      <ModalActions className="sm:items-center sm:justify-between">
        <Button
          variant="secondary"
          onClick={onBack}
          disabled={isBackHidden}
          className={cn('min-w-28', { invisible: isBackHidden })}
        >
          {t('core.back')}
        </Button>
        <div className={cn('flex gap-1', { invisible: selectedCustomerUuids.length === 1 })}>
          {formSteps.map((step, index) => (
            <span
              key={step}
              className={cn('h-2 w-2 rounded-full bg-gray-400 transition-colors', {
                'bg-primary-500': index === currentFormStepIndex,
              })}
            ></span>
          ))}
        </div>
        <Button
          variant="primary"
          onClick={handleSubmit(onNext)}
          className="min-w-28"
          loading={isPending}
          disabled={isPending}
        >
          {isReadyToSubmit ? t('core.submit') : t('core.next')}
        </Button>
      </ModalActions>
    </Modal>
  );
};
