import { SEAInvoiceRulesUpsertCriteriaBody, SEARecurringRuleArgument } from '@/enterprise/data/useCreateRecurringRule';
import { RecurringRule, RecurringRuleCategory, RecurringRuleCriteria } from '@/enterprise/data/useRecurringRules';
import { TakerMarket } from '../../../data/useTakerMarkets';
import { RecurringRuleFormCriteriaValue } from '../RecurringRulesForm';

export type RuleRecord = Record<RecurringRuleCriteria, string>;

/**
 * maps an array of form criteria to a rule record object
 * input = [{ type: 'gteDpe', value: '8' }, { type: 'lteDpe', value: '24' }]
 * output = { gteDpe: '8', lteDpe: '24' }
 */
export const mapFormCriteriaToRuleRecord = (formCriteria: RecurringRuleFormCriteriaValue[]): RuleRecord => {
  return formCriteria.reduce((ruleRecord: RuleRecord, formCriteria: RecurringRuleFormCriteriaValue) => {
    ruleRecord[formCriteria.type] = formCriteria.value;
    return ruleRecord;
  }, {} as RuleRecord);
};

/**
 * maps a formatted recurring rule object to an object containing arrays of form criteria by rule category
 * input = { gteDpe: 8, lteDpe: 24, lteInvoiceAmount: 1000 }
 * output = {
 *   dpe: [{ type: 'gteDpe', value: '8' }, { type: 'lteDpe', value: '24' }],
 *   amount: [{ type: 'lteInvoiceAmount', value: '1000' }]
 * }
 */
export const mapRecurringRuleToFormCriteria = (
  recurringRule: RecurringRule
): Record<RecurringRuleCategory, RecurringRuleFormCriteriaValue[]> => {
  const formCritera = {} as Record<RecurringRuleCategory, RecurringRuleFormCriteriaValue[]>;

  if (recurringRule.dpe) {
    formCritera.dpe = [
      ...(recurringRule.dpe.gteDpe ? [{ type: 'gteDpe', value: recurringRule.dpe.gteDpe.toString() }] : []),
      ...(recurringRule.dpe.lteDpe ? [{ type: 'lteDpe', value: recurringRule.dpe.lteDpe.toString() }] : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.dueDate) {
    formCritera.dueDate = [
      ...(recurringRule.dueDate.fromDueDate
        ? [{ type: 'fromDueDate', value: recurringRule.dueDate.fromDueDate.toString() }]
        : []),
      ...(recurringRule.dueDate.toDueDate
        ? [{ type: 'toDueDate', value: recurringRule.dueDate.toDueDate.toString() }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.amount) {
    formCritera.amount = [
      ...(recurringRule.amount.gteInvoiceAmount
        ? [{ type: 'gteInvoiceAmount', value: recurringRule.amount.gteInvoiceAmount.toString() }]
        : []),
      ...(recurringRule.amount.lteInvoiceAmount
        ? [{ type: 'lteInvoiceAmount', value: recurringRule.amount.lteInvoiceAmount.toString() }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.excludedVoucherIds) {
    formCritera.invoiceId = [
      ...(recurringRule.excludedVoucherIds
        ? [{ type: 'excludedVoucherIds', value: recurringRule.excludedVoucherIds.join(', ') }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  return formCritera;
};

/**
 * maps a rule record object to the format and data types expected by the SEA API
 * input = { gteDpe: '8', lteDpe: '24' }
 * output = {dpe: { gte: 8, lte: 24 }}
 */
export const mapRuleRecordToSEASubmit = (
  ruleRecord: Partial<RuleRecord>,
  selectedTakerMarkets: TakerMarket[],
  existingRules: RecurringRule[],
  fromCurrency: string
): SEARecurringRuleArgument => {
  const criteria: SEAInvoiceRulesUpsertCriteriaBody = {};

  if (ruleRecord.lteDpe || ruleRecord.gteDpe) {
    criteria.dpe = {
      ...(ruleRecord.lteDpe && { lte: Number(ruleRecord.lteDpe) }),
      ...(ruleRecord.gteDpe && { gte: Number(ruleRecord.gteDpe) }),
    };
  }

  if (ruleRecord.lteInvoiceAmount || ruleRecord.gteInvoiceAmount) {
    criteria.invoiceAmount = {
      ...(ruleRecord.lteInvoiceAmount && { lte: Number(ruleRecord.lteInvoiceAmount) }),
      ...(ruleRecord.gteInvoiceAmount && { gte: Number(ruleRecord.gteInvoiceAmount) }),
      fromCurrency: fromCurrency,
    };
  }

  if (ruleRecord.fromDueDate || ruleRecord.toDueDate) {
    criteria.dueDate = {
      ...(ruleRecord.fromDueDate && { from: ruleRecord.fromDueDate }),
      ...(ruleRecord.toDueDate && { to: ruleRecord.toDueDate }),
    };
  }

  if (ruleRecord.excludedVoucherIds) {
    criteria.voucherIds = {
      ...(ruleRecord.excludedVoucherIds && {
        exclude: ruleRecord.excludedVoucherIds.split(',').map((id) => id.trim()),
      }),
    };
  }

  return {
    takersMarkets: selectedTakerMarkets.map((takerMarket) => ({
      marketUuid: takerMarket.marketUuid,
      takerUuid: takerMarket.takerDivisionUuid,
      takerId: takerMarket.takerDivisionId,
      marketId: takerMarket.legacyMarketId,
      makerOrganizationUuid: takerMarket.makerOrganizationUuid,
    })),
    criteria,
    existingRules,
  };
};

type RuleKey =
  | 'lteDpe'
  | 'gteDpe'
  | 'lteInvoiceAmount'
  | 'gteInvoiceAmount'
  | 'fromDueDate'
  | 'toDueDate'
  | 'excludedVoucherIds';

export const mapSeaRuleArgumentToRuleArray = (rule: SEARecurringRuleArgument): { type: RuleKey }[] => {
  const formattedRecurringRule: {
    gteDpe?: number;
    lteDpe?: number;
    fromDueDate?: string;
    toDueDate?: string;
    gteInvoiceAmount?: number;
    lteInvoiceAmount?: number;
    excludedVoucherIds?: string[];
  } = {};

  if (rule.criteria?.dpe?.gte) {
    formattedRecurringRule.gteDpe = rule.criteria.dpe.gte;
  }

  if (rule.criteria?.dpe?.lte) {
    formattedRecurringRule.lteDpe = rule.criteria.dpe.lte;
  }

  if (rule.criteria?.dueDate?.from) {
    formattedRecurringRule.fromDueDate = rule.criteria.dueDate.from;
  }

  if (rule.criteria?.dueDate?.to) {
    formattedRecurringRule.toDueDate = rule.criteria.dueDate.to;
  }

  if (rule.criteria?.invoiceAmount?.gte) {
    formattedRecurringRule.gteInvoiceAmount = rule.criteria.invoiceAmount.gte;
  }

  if (rule.criteria?.invoiceAmount?.lte) {
    formattedRecurringRule.lteInvoiceAmount = rule.criteria.invoiceAmount.lte;
  }

  if (rule.criteria?.voucherIds?.exclude) {
    formattedRecurringRule.excludedVoucherIds = rule.criteria.voucherIds.exclude;
  }

  return Object.keys(formattedRecurringRule)
    .filter((key) =>
      [
        'lteDpe',
        'gteDpe',
        'lteInvoiceAmount',
        'gteInvoiceAmount',
        'fromDueDate',
        'toDueDate',
        'excludedVoucherIds',
      ].includes(key)
    )
    .map((key) => ({ type: key as RuleKey }));
};
