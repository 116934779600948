import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/enterprise/data/useTakerMarketsGroups';

const getMarketType = (takerMarket: TakerMarket) => {
  const marketType: TakerMarketGroupType =
    takerMarket.offerConfig.offerType === 'PREFERRED_TERM'
      ? 'PREFERRED'
      : takerMarket.marketIsEnabled === false
      ? 'INACTIVE'
      : takerMarket.marketPricingType === 'BENCHMARK'
      ? 'BENCHMARK'
      : takerMarket.marketPricingType === 'STATIC'
      ? 'FIXED'
      : 'NAME_YOUR_RATE';

  return marketType;
};

export default getMarketType;
