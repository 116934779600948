import { ReactNode } from 'react';
import Reauthenticate from '@/lib/Reauthenticate';
import { useToken } from '@/utils/token';

const TokenGuard = ({ children }: { children: ReactNode }) => {
  const { token, tokenContent } = useToken();
  const nowSeconds = Math.round(Date.now() / 1000);
  const tokenIsExpired = !token || (tokenContent ? tokenContent.exp <= nowSeconds : true);

  return tokenIsExpired ? <Reauthenticate /> : children;
};

export default TokenGuard;
