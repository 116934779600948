import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';

type SEAInvoiceRulesDeleteRequestBody = paths['/invoice-rules']['delete']['requestBody']['content']['application/json'];

/**
 * hook for deleting a recurring rule and all its criteria
 */
export interface DeleteRecurringRuleArgument {
  id: string;
  makerOrganizationUuid: string;
  marketId: number;
  marketUuid: string;
  takerId: number;
  takerUuid: string;
}

const useDeleteRecurringRule = () => {
  const { trackEnterpriseEvent } = useReporting();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteRecurringRule'],
    mutationFn: (argument: DeleteRecurringRuleArgument | DeleteRecurringRuleArgument[]) => {
      const argumentsArray = Array.isArray(argument) ? argument : [argument];

      return apiClient.delete(`api/supplier-experience/invoice-rules`, {
        json: {
          groupingKey: 'SUPPLIER_MARKET',
          rules: argumentsArray.map((argument) => ({
            uuid: argument.id,
            makerOrganizationUuid: argument.makerOrganizationUuid,
          })),
          supplierMarkets: argumentsArray.map((argument) => ({
            marketUuid: argument.marketUuid,
            supplierDivisionUuid: argument.takerUuid,
          })),
        } satisfies SEAInvoiceRulesDeleteRequestBody,
      });
    },
    onSuccess: (_, variables) => {
      const variablesArray = Array.isArray(variables) ? variables : [variables];

      // Send trackEvent for each rule argument that came in the delete request
      variablesArray.forEach((argument) => {
        trackEnterpriseEvent('recurring-rules::deleted', {
          makerOrganizationUuid: argument.makerOrganizationUuid,
          takerMarkets: [
            {
              marketId: argument.marketId,
              takerId: argument.takerUuid,
              takerUuid: argument.takerId,
              marketUuid: argument.marketUuid,
            },
          ],
        });
      });

      queryClient.refetchQueries({
        queryKey: ['recurring-rules'],
      });
    },
  });
};

export default useDeleteRecurringRule;
