import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { Button } from '@c2fo/liquidity';
import { AngleRightIcon } from '@c2fo/liquidity/icons';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import AwardArchiveDownload from './AwardArchiveDownload';
import { getRangeKey, getRangeLabel } from './utils';

export interface RangeHeaderProps {
  startDate: string;
  endDate: string;
  latestPayDate?: string;
  currencies?: string[];
  isDownloadDisabled: boolean;
  /**
   * The link to download the award file.
   * If this is provided, it will show a "download CSV" link in the left sidebar.
   */
  awardFileDownloadLink?: string;
  /**
   * The customers to display. If provided, the customer filter will be displayed.
   * If not provided, the customer filter will not be displayed.
   */
  customers?: Array<{ value: string; label: string }>;
  /**
   * The selected customers.
   */
  selectedCustomers?: string[];
  /**
   *  Indicates whether future pay dates are included in history.
   */
  includeFuturePayDates: boolean;
}

export default function RangeHeader({
  startDate,
  endDate,
  latestPayDate,
  awardFileDownloadLink,
  customers = [],
  selectedCustomers,
  isDownloadDisabled,
  includeFuturePayDates,
}: RangeHeaderProps) {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const rangeKey = useMemo(() => getRangeKey(startDate, endDate), [startDate, endDate]);
  const useLatestPaydate = includeFuturePayDates && latestPayDate && isAfter(latestPayDate, endDate);

  return (
    <div className="border-b px-8 py-4">
      <div className="flex flex-col items-center sm:flex-row">
        <div className="flex flex-1 items-center gap-2">
          <div>
            <h4 className="m-0 font-normal">
              {t(...getRangeLabel(rangeKey))}
              <AngleRightIcon className="inline-block h-6 w-6" />
              <span>
                {startDate} {t('core.to')} {endDate}
                {includeFuturePayDates && '*'}
              </span>
            </h4>
            {includeFuturePayDates && (
              <span className="text-xs text-text-secondary">*{t('taker.history.includesFuturePayDates')}</span>
            )}
          </div>
        </div>
        <div>
          {/* market history download csv link */}
          {awardFileDownloadLink && (
            <Button
              disabled={isDownloadDisabled}
              variant="primary"
              size="sm"
              onClick={() => {
                window.location.href = awardFileDownloadLink;
                trackEnterpriseEvent('history-award-download::clicked');
              }}
            >
              {t('core.downloadCSV')}
            </Button>
          )}
          {/* consolidated history download csv link */}
          {!!customers?.length && (
            <QueryBoundaries>
              <AwardArchiveDownload
                startDate={startDate}
                endDate={useLatestPaydate ? latestPayDate : endDate}
                makerIds={selectedCustomers?.length ? selectedCustomers : customers.map((c) => c.value)}
                disabled={isDownloadDisabled}
              />
            </QueryBoundaries>
          )}
        </div>
      </div>
    </div>
  );
}
