import { useSuspenseQuery } from '@tanstack/react-query';
import tpfApiClient from '@/lib/tpfApiClient';

// https://fss-tpf-supplier-api.c2fo.com/v2/tpf-api/product-info

export type CapFinClientStatus =
  | 'AWAITING_C2FO_STATUS'
  | 'AWAITING_CLIENT_STATUS'
  | 'BANK_ACCOUNT_ACTION_REQUIRED'
  | 'BANK_ACCOUNT_START'
  | 'FIRST_TIME_STATUS'
  | 'IS_TERM_SHEET_ACCEPTED'
  | 'NOT_STARTED_STATUS'
  | 'READY_FOR_RF_LOANS_STATUS'
  | 'REJECTED';

interface CapFinClient {
  name: string;
  messageHeading: string;
  messageText: string;
  actionButtonText: string;
  actionButtonUrl: string;
  isActionButtonDisabled: false;
  creditLimit: number;
  outstandingAmount: number;
  arBalance: number;
  isRejected: boolean;
  status: CapFinClientStatus;
  productType?: string;
  divisionUuid?: string;
}

export interface ProductInfo {
  showLending: boolean;
  hasRFClients: boolean;
  isRejected: boolean;
  lending: unknown[]; // unsure what the shape of the data within this array is
  rf: CapFinClient[];
  hasUSDMarket: boolean;
}

const fetchProductInfo = async (): Promise<ProductInfo> => {
  return tpfApiClient.get(`v2/tpf-api/product-info`).json<ProductInfo>();
};

const useProductInfo = <TData = ProductInfo>(select?: (data: ProductInfo) => TData) => {
  return useSuspenseQuery({
    queryKey: ['product-info'],
    queryFn: () => fetchProductInfo(),
    select,
  });
};

export default useProductInfo;
