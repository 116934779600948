import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import AccountSettings from '@/accountSettings/pages/AccountSettings';
import NotificationSettings from '@/accountSettings/pages/NotificationSettings';
import RightToBeForgotten from '@/accountSettings/pages/RightToBeForgotten';
import { FeatureFlagProtectedRoute } from '@/shared/components/FeatureFlagProtectedRouting';
import FullPageLoader from '@/shared/components/FullPageLoader';
import NavigateWithRedirect from '@/shared/components/NavigateWithRedirect';

const App = lazy(() => import('@/accountSettings/App'));

export const accountSettingsBasename = 'account';

const accountSettingsRouteConfig: RouteObject = {
  path: accountSettingsBasename,
  element: (
    <FeatureFlagProtectedRoute featureFlag="enterprise-ui_enableAccountSettings">
      <Suspense fallback={<FullPageLoader />}>
        <App />
      </Suspense>
    </FeatureFlagProtectedRoute>
  ),
  children: [
    {
      index: true,
      element: <NavigateWithRedirect to="settings" />,
    },
    {
      path: 'settings',
      element: <AccountSettings />,
    },
    {
      path: 'notifications',
      element: <NotificationSettings />,
    },
    {
      path: 'right-to-be-forgotten',
      element: <RightToBeForgotten />,
    },
    { path: '*', element: <NavigateWithRedirect to="settings" /> },
  ],
};

export default accountSettingsRouteConfig;
