import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { Button, Chip, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';
import { EligibleInvoiceFetchFilters } from '@/enterprise/data/useEligibleInvoices';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';

const navParams = qs.stringify({
  limit: 100,
  order: 'amount',
  orderDirection: 'desc',
  page: 1,
  filter: { included: [false] } satisfies EligibleInvoiceFetchFilters,
});

const ManualExclusionChip = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const { trackEnterpriseEvent } = useReporting();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const {
    takerExcludedInvoiceAmount: excludedAmount,
    takerExcludedInvoiceCount: excludedCount,
    currency,
  } = takerMarket;

  if (excludedAmount <= 0) {
    return null;
  }

  const handleOpenModal = () => {
    setOpenModal(true);
    trackEnterpriseEvent('manual-exclusion-modal::opened', {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal onClose={handleCloseModal} open={openModal} size="sm">
        <ModalTitleInverse>
          <span className="flex flex-col">
            <span>{t('core.manualExclusion')}</span>
            <span className="text-base font-normal text-text-secondary">{getTakerMarketName(takerMarket)}</span>
          </span>
        </ModalTitleInverse>
        <ModalContent>
          {excludedCount === 1 && (
            <div>
              {t('taker.divisionExcludedInvoiceDescription', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          {excludedCount > 1 && (
            <div>
              {t('taker.divisionExcludedInvoiceDescriptionPlural', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
        </ModalContent>
        <ModalActions>
          <Button onClick={handleCloseModal} variant="secondary">
            {t('core.close')}
          </Button>
          <Link
            onClick={() => trackEnterpriseEvent('manual-exclusion-modal::redirected')}
            to={asEnterpriseLink(
              `markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible?${navParams}`
            )}
          >
            <Button variant="cta">{t('core.viewExcludedInvoices')}</Button>
          </Link>
        </ModalActions>
      </Modal>
      <Chip
        label={
          <div className="text-left">
            <div>{t('core.manualExclusion')}</div>
            <div>
              {t('invoiceSettings.excludedAmount', {
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          </div>
        }
        size="xs"
        type="warning"
        variant="outlined"
        className="mt-1"
        onClick={handleOpenModal}
      />
    </>
  );
};

export default ManualExclusionChip;
