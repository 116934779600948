import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import Skeleton from '@/enterprise/components/Skeleton';
import useTakerMarkets, { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useExchangeRates from '@/shared/data/useExchangeRates';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import LearnMore from './components/LearnMore';
import StatsTable from './components/StatsTable';
import normalizeStats from './utils/normalizeStats';

const useAggregateTakerMarketStats = () => {
  // get the exchange rates for market currencies
  const { data: exchangeRates } = useExchangeRates();
  // map the market stats to exchange rates
  const { data: takerMarketStats } = useTakerMarkets(
    useCallback((takerMarkets: TakerMarket[]) => normalizeStats(takerMarkets, exchangeRates), [exchangeRates])
  );

  return takerMarketStats;
};

const AggregateStatsBannerComponent = () => {
  const { t } = useTranslation();
  const selectedCurrency = useSelectedCurrency();
  const { asCurrency, asNumber } = useLocaleFormat();
  const takerMarketStats = useAggregateTakerMarketStats();
  const { listenToAllMarketStats } = useServerSideEventListeners();
  const listeningForMarketStats = listenToAllMarketStats();

  const view =
    takerMarketStats?.aggregateStats.eligibleInvoiceCount === 0
      ? 'noAvailableAr'
      : takerMarketStats?.aggregateStats.hasNeverPlacedOffer
      ? 'hasNeverPlacedOffer'
      : takerMarketStats?.aggregateStats.eligibleInvoiceCount === takerMarketStats?.aggregateStats.acceptedInvoiceCount
      ? 'fullyClearing'
      : 'notFullyClearing';

  return (
    <>
      {takerMarketStats && takerMarketStats.aggregateStats.numberOfTakerMarkets > 1 && (
        <div>
          <div className="rounded-md bg-secondary-500 p-8 text-white shadow-md">
            <div className="text-center">
              {listeningForMarketStats ? (
                <>
                  <h3 className="m-0">{t('taker.marketFeedback.calculatingMarkets')}</h3>
                  <p className="text-2xl">{t('taker.marketFeedback.valuesWillBeUpdatedShortly')}</p>
                </>
              ) : (
                <>
                  {view === 'hasNeverPlacedOffer' && (
                    <h3 className="m-0">
                      {t('aggregateStats.header.hasNeverPlacedOffer', {
                        buyerName: takerMarketStats.primaryBuyer.makerOrganizationName,
                      })}
                    </h3>
                  )}
                  {view === 'noAvailableAr' && (
                    <>
                      <h3 className="m-0">{t('aggregateStats.header.noAvailableAr')}</h3>
                      <p className="text-2xl">{t('aggregateStats.subheader.noAvailableAr')}</p>
                    </>
                  )}
                  {view === 'fullyClearing' && (
                    <>
                      <h3 className="m-0">{t('aggregateStats.header.fullyClearing')}</h3>
                      <p className="text-2xl">{t('aggregateStats.subheader.fullyClearing')}</p>
                    </>
                  )}
                  {view === 'notFullyClearing' && (
                    <>
                      <h3 className="m-0">{t('aggregateStats.header.notFullyClearing')}</h3>
                      <p className="text-2xl">{t('aggregateStats.subheader.notFullyClearing')}</p>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="py-6">
              {view === 'noAvailableAr' && (
                <div className="flex justify-center">
                  <Link to="search">
                    <Button variant="cta" inverse>
                      {t('core.seeCustomerList')}
                    </Button>
                  </Link>
                </div>
              )}
              {view === 'hasNeverPlacedOffer' && (
                <div className="flex w-full justify-center gap-24">
                  <div className="text-center">
                    <h3 className="m-0">
                      {asNumber({
                        value: takerMarketStats.aggregateStats.eligibleInvoiceCount,
                      })}
                    </h3>
                    <p className="capitalize">{t('maker.invoices')}</p>
                  </div>
                  <div className="text-center">
                    <h3 className="m-0">
                      {asCurrency(
                        takerMarketStats.byCurrency[selectedCurrency].eligibleInvoiceAmount,
                        selectedCurrency
                      )}
                    </h3>
                    <p className="capitalize">{t('core.totalInvoiceAmount')}</p>
                  </div>
                  <div className="text-center">
                    <h3 className="m-0">
                      {`${asNumber({
                        value: takerMarketStats.byCurrency[selectedCurrency].eligibleDpeWeightedAvg,
                        decimals: 0,
                      })} ${t('core.days')}`}
                    </h3>
                    <p className="capitalize">{t('core.averageAcceleration')}</p>
                  </div>
                </div>
              )}
              {!['hasNeverPlacedOffer', 'noAvailableAr'].includes(view) && (
                <StatsTable currency={selectedCurrency} stats={takerMarketStats} />
              )}
            </div>
            <LearnMore />
          </div>
        </div>
      )}
    </>
  );
};

const AggregateStatsBanner = () => (
  <QueryBoundaries LoadingComponent={() => <Skeleton />}>
    <IntercomDataTarget target="enterpriseMarketOverview">
      <AggregateStatsBannerComponent />
    </IntercomDataTarget>
  </QueryBoundaries>
);

export default AggregateStatsBanner;
