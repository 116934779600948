import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { enterpriseAppName, enterpriseBasename, smbAppName } from '@/constants';
import apiClient from '@/lib/apiClient';
import { getSessionTrackingData } from '@/reporting/sessionTrackingData';
import { useToken } from '@/utils/token';
import useNewExperienceEnabled from '@/utils/useNewExperienceEnabled';
import useDataDog from './DataDog';
import { EnterpriseTrackEventNames, SmbTrackEventNames, MultiExperienceTrackEventNames } from './trackEventNames';
import { C2foPayload, ClientMeta, ReportAttributes } from './types';

export const mutationKeyTrack = ['track'];

export function useReporting() {
  const { pathname } = useLocation();
  const { tokenContent } = useToken();
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? null;
  const sessionUuid = tokenContent?.payload?.user?.sessionUuid ?? null;
  const { addAction: dataDogAddAction } = useDataDog();
  const newExperienceEnabled = useNewExperienceEnabled();
  const sessionTrackingData = useMemo(() => getSessionTrackingData(), []);
  const uri = window?.location?.href ?? null;
  const uriQueryParams = window?.location?.search ?? null;
  const uriStateName = window?.location?.pathname ?? null;
  const clientMeta = useMemo<ClientMeta>(
    () => ({
      createdDateTime: new Date(),
      sessionUuid,
      uriStateName,
      uri,
      uriQueryParams,
      ...sessionTrackingData,
    }),
    [sessionUuid, sessionTrackingData, uri, uriQueryParams, uriStateName]
  );

  const { mutateAsync: postTrack } = useMutation({
    mutationKey: mutationKeyTrack,
    mutationFn: async (payload: C2foPayload) => {
      // during development, log out what will be sent to the analytics api
      if (import.meta.env.MODE === 'development') {
        console.log('track event', { [payload.eventName]: payload });
      }

      if (import.meta.env.VITE_ENABLE_ANALYTICS === 'true') {
        return apiClient.post('api/analytics/track', {
          json: payload,
        });
      }
    },
  });

  /**
   * Internal method, do not export this directly.
   */
  const track = useCallback(
    async (
      namespace: 'enterprise' | 'early-pay',
      event: EnterpriseTrackEventNames | SmbTrackEventNames | MultiExperienceTrackEventNames,
      attributes?: ReportAttributes
    ) => {
      if (authServiceUuid) {
        const namespacedEvent = `${namespace}::${event}`;

        const properties: ReportAttributes = {
          clientMeta,
          ...(attributes && { eventMeta: attributes }),
          ...(tokenContent?.payload.impersonationSubject?.uuid && {
            impersonationSubjectUuid: tokenContent.payload.impersonationSubject.uuid,
          }),
          newExperienceEnabled,
        };

        const payload: C2foPayload = {
          appId: namespace === 'enterprise' ? enterpriseAppName : smbAppName,
          authServiceUuid,
          eventName: namespacedEvent,
          properties,
          sendToAtlasQueue: true,
          sessionUuid: clientMeta.sessionUuid,
        };

        try {
          dataDogAddAction(namespacedEvent, { ...properties });
          await postTrack({ ...payload, properties: { ...properties, sessionUuid: clientMeta.sessionUuid } });
        } catch (error) {
          // Don't block app functionality if tracking fails for some reason.
        }
      }
    },
    [authServiceUuid, clientMeta, dataDogAddAction, newExperienceEnabled, postTrack, tokenContent]
  );

  const trackEnterpriseEvent = useCallback(
    (event: EnterpriseTrackEventNames, attributes?: ReportAttributes) => {
      track('enterprise', event, attributes);
    },
    [track]
  );

  const trackSmbEvent = useCallback(
    (event: SmbTrackEventNames, attributes?: ReportAttributes) => {
      track('early-pay', event, attributes);
    },
    [track]
  );

  const trackMultiExperienceEvent = useCallback(
    (event: MultiExperienceTrackEventNames, attributes?: ReportAttributes) => {
      const namespace = pathname.includes(enterpriseBasename) ? 'enterprise' : 'early-pay';
      track(namespace, event, attributes);
    },
    [track, pathname]
  );

  return { trackEnterpriseEvent, trackSmbEvent, trackMultiExperienceEvent };
}
