import { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useEffectOnce } from 'react-use';
import useRecentAwards, { Award } from '@/enterprise/data/useRecentAwards';
import { useReporting } from '@/reporting';
import useUserDetails from '@/shared/data/useUserDetails';
import { useToken } from '@/utils/token';

const QUALTRICS_ID = import.meta.env.VITE_QUALTRICS_ID;
const QUALTRICS_INTERCEPT_ID_NPS = import.meta.env.VITE_QUALTRICS_INTERCEPT_ID_NPS;
const ENV = import.meta.env.VITE_ENV;

declare global {
  interface Window {
    QSI: {
      API: {
        run: (id: string, options: Record<string, unknown>) => void;
        load: () => void;
        unload: () => void;
      };
    };
  }
}

function injectQualtricsScript(id: string): void {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.innerText = `
  (function(){var g=function(e,h,f,g){
this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
try{(new g(100,"r","QSI_S_${id}","https://${id
    .replace(/_/g, '')
    .toLowerCase()}-c2focx.siteintercept.qualtrics.com/SIE/?Q_ZID=${id}")).start()}catch(i){}})();
  `;

  document.body.appendChild(script);
}

export function useQualtrics() {
  const { data: userDetailsData } = useUserDetails();
  const emailAddress = userDetailsData?.me?.emailAddress ?? '';
  const internalUserDomains = ['@c2fo.com', '@wfcfund.com', '@priorityvendor.com', '@synthetics.dtdg.co'];
  const hasRecentlyCompletedNpsSurvey = Cookies.get('last_nps_run');
  const { trackMultiExperienceEvent } = useReporting();
  const { data: hasParticipated = false } = useRecentAwards(
    useCallback((awards: Award[]) => {
      return (awards?.length ?? 0) > 0;
    }, [])
  );

  const isC2foUser = internalUserDomains.find((host) => emailAddress.endsWith(host));

  const triggerNPSSurvey = useCallback(
    (config?: { forceTrigger?: boolean }) => {
      const { forceTrigger = false } = config ?? {};

      if (!window.QSI) {
        console.error('Qualtrics implementation error: window.QSI is not available');
        return;
      }

      if (!forceTrigger && (isC2foUser || hasRecentlyCompletedNpsSurvey || !hasParticipated)) {
        trackMultiExperienceEvent('user::nps:survey::skipped');
        return;
      }

      // Note: This is how it was done in NSE, not entirely sure why we need to unload and load again
      window.QSI.API.unload();
      window.QSI.API.load();

      // The intercept config in Qualtrics has the following check: If Cookie qualtrics_trigger Equal to NPS
      Cookies.set('qualtrics_trigger', 'NPS', { expires: new Date(Date.now() + 2000) });

      Cookies.set('last_nps_run', 'true', { expires: 90 }); // days

      window.QSI.API.run(QUALTRICS_INTERCEPT_ID_NPS, {
        app: 'enterprise-ui',
      });

      trackMultiExperienceEvent('user::nps:survey::shown');
    },
    [hasParticipated, hasRecentlyCompletedNpsSurvey, isC2foUser, trackMultiExperienceEvent]
  );

  return { triggerNPSSurvey };
}

let isQualtricsScriptLoaded = false;

export function InitQualtrics() {
  const { tokenContent } = useToken();
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? '';

  useEffectOnce(() => {
    if (QUALTRICS_ID && isQualtricsScriptLoaded === false) {
      injectQualtricsScript(QUALTRICS_ID);
      isQualtricsScriptLoaded = true;
    }
  });

  useEffect(() => {
    // Settings cookie for Qualtrics, it reads from the cookie and sends it with each survey response
    Cookies.set('qualtrics_userId', authServiceUuid, { secure: true });
    Cookies.set('qualtrics_env', ENV ?? 'dev');
  }, [authServiceUuid]);

  return null;
}
