import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';
import { useReporting } from '@/reporting';

interface BulkInvoiceActionModalProps {
  open: boolean;
  onClose: () => void;
  bulkActionType: 'exclude' | 'include' | null;
  takerMarketName: string;
  toggleBulkInvoiceInclusionExclusion: (action: 'include' | 'exclude') => void;
  filterSelected: boolean;
}

const BulkInvoiceActionButton = (
  props: Omit<
    BulkInvoiceActionModalProps,
    'takerMarketName' | 'includedInvoiceAmount' | 'excludedInvoiceAmount' | 'open'
  >
) => {
  const { bulkActionType, toggleBulkInvoiceInclusionExclusion, onClose } = props;
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();

  // type guard if bulkActionType is sent as null for some reason
  if (!bulkActionType) {
    return null;
  }

  const buttonProps = {
    className: 'w-full text-sm sm:w-auto sm:text-lg',
    onClick: () => {
      toggleBulkInvoiceInclusionExclusion(bulkActionType);
      trackEnterpriseEvent(`invoice-${bulkActionType}-all::clicked`);
      onClose();
    },
  };

  return bulkActionType === 'include' ? (
    <Button variant="cta" {...buttonProps}>
      {t('core.includeInvoices')}
    </Button>
  ) : (
    <Button variant="destructive" {...buttonProps}>
      {t('core.excludeInvoices')}
    </Button>
  );
};

const BulkInvoiceActionModal = ({ takerMarketName, open, ...props }: BulkInvoiceActionModalProps) => {
  const { t } = useTranslation();

  const config = {
    exclude: {
      info: t('core.bulkExcludeInvoicesInfo'),
      title: t('core.excludeInvoices'),
      warning: props.filterSelected
        ? t('core.bulkExcludeInvoicesWarningWithFilter')
        : t('core.bulkExcludeInvoicesWarningWithoutFilter'),
    },
    include: {
      info: t('core.bulkIncludeInvoicesInfo'),
      title: t('core.includeInvoices'),
      warning: props.filterSelected
        ? t('core.bulkIncludeInvoicesWarningWithFilter')
        : t('core.bulkIncludeInvoicesWarningWithoutFilter'),
    },
  };

  // type guard if bulkActionType is sent as null for some reason
  if (!props.bulkActionType) {
    return null;
  }

  return (
    <Modal size="sm" open={open} onClose={props.onClose}>
      <ModalTitleInverse>{config[props.bulkActionType].title}</ModalTitleInverse>
      <ModalContent className="space-y-4">
        <div>
          {config[props.bulkActionType].info} {takerMarketName}
        </div>
        <div>{config[props.bulkActionType].warning}</div>
      </ModalContent>

      <ModalActions>
        <Button variant="secondary" onClick={props.onClose}>
          {t('core.cancel')}
        </Button>
        <BulkInvoiceActionButton {...props} />
      </ModalActions>
    </Modal>
  );
};

export default BulkInvoiceActionModal;
