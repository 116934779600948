import { EnterpriseRedirect } from '@/enterprise/Router';
import { SmbRedirect } from '@/smb/Router';
import useNewExperienceEnabled from '@/utils/useNewExperienceEnabled';

const ExperienceRedirect = () => {
  const newExperienceEnabled = useNewExperienceEnabled();

  if (newExperienceEnabled) {
    return <SmbRedirect />;
  }

  return <EnterpriseRedirect />;
};

export default ExperienceRedirect;
