import { useTranslation } from 'react-i18next';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useOfferDiscountAPRDisplay } from '../takerMarketTable/utils/useOfferDiscountAPRDisplay';

const useFixedRateDetails = () => {
  const { t } = useTranslation();
  const { offerDiscountAPRDisplay } = useOfferDiscountAPRDisplay();

  const getFixedRateDetails = (takerMarket: TakerMarket) => {
    const formattedMarketRate = takerMarket.staticMarketRate
      ? offerDiscountAPRDisplay(takerMarket.staticMarketRate, t('core.aprAbbreviation'))
      : t('core.na');

    return {
      marketRate: takerMarket.staticMarketRate,
      formattedMarketRate,
    };
  };

  return {
    getFixedRateDetails,
  };
};

export default useFixedRateDetails;
