import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import Skeleton from '@/enterprise/components/Skeleton';
import useRecurringRules from '@/enterprise/data/useRecurringRules';
import useTakerMarkets from '@/enterprise/data/useTakerMarkets';
import RequestInformationModal from '@/enterprise/features/recurringRules/components/RequestInformationModal';
import NoActiveRules from '@/enterprise/features/recurringRules/NoActiveRules';
import RecurringRulesForm, {
  RecurringRuleFormValues,
  RecurringRulesFormProps,
} from '@/enterprise/features/recurringRules/RecurringRulesForm';
import RulesTable from '@/enterprise/features/recurringRules/RulesTable';
import SuggestedRules from '@/enterprise/features/recurringRules/SuggestedRules';
import useEnableRecurringRules from '@/enterprise/features/recurringRules/utils/useEnableRecurringRules';
import { useReporting } from '@/reporting';
import NavigateWithRedirect from '@/shared/components/NavigateWithRedirect';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import useRestrictions from '@/utils/useRestrictions';

const RecurringRulesManagerComponent = () => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const recurringRules = useRecurringRules();
  const { data: takerMarkets = [] } = useTakerMarkets();
  const [recurringRulesFormOpen, setRecurringRulesFormOpen] = useState<boolean>(false);
  const [ruleModalProps, setRuleModalProps] = useState<Pick<RecurringRulesFormProps, 'defaultValues' | 'mode'> | null>(
    null
  );
  const [showRequestInformationModal, setShowRequestInformationModal] = useState(false);
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);

  const showRecurringRulesModal = (defaultValues?: Partial<RecurringRuleFormValues>) => {
    setRuleModalProps({ defaultValues });
    setRecurringRulesFormOpen(true);
  };

  const onNewRuleClick = () => {
    showRecurringRulesModal();
    trackEnterpriseEvent('recurring-rules::opened', {
      referrer: 'rules-manager',
      source: 'cta',
    });
  };

  const closeRecurringRulesModal = () => {
    setRecurringRulesFormOpen(false);
    trackEnterpriseEvent('recurring-rules::closed');
  };

  const hasActiveRules = recurringRules.length > 0;

  return (
    <>
      <RecurringRulesForm
        onClose={closeRecurringRulesModal}
        open={recurringRulesFormOpen}
        referrer="rulesManager"
        takerMarkets={takerMarkets}
        {...ruleModalProps}
      />
      <RequestInformationModal
        open={showRequestInformationModal}
        onClose={() => setShowRequestInformationModal(false)}
      />
      <div className="flex flex-col gap-10">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <h1 className="m-0 pb-4 text-4xl font-medium sm:pb-0">{t('recurringRules.title')}</h1>
          <Button className="w-full sm:w-fit" onClick={onNewRuleClick} variant="cta" disabled={!canEditRecurringRules}>
            {t('recurringRules.createRecurringRule')}
          </Button>
        </div>
        {!hasActiveRules && <NoActiveRules />}
        {hasActiveRules && <RulesTable />}
        <SuggestedRules showRecurringRulesModal={showRecurringRulesModal} />
      </div>
    </>
  );
};

const LoadingSkeleton = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="m-0 pb-4 text-4xl font-medium sm:pb-0">{t('recurringRules.title')}</h1>
      <div className="mt-16 space-y-4">
        <Skeleton height="h-80" />
        <div className="grid gap-10 pt-10 md:grid-cols-2 lg:gap-16">
          <Skeleton height="h-52" />
          <Skeleton height="h-52" />
        </div>
      </div>
    </>
  );
};

const RecurringRulesManager = () => {
  const enableRecurringRules = useEnableRecurringRules();

  if (!enableRecurringRules) {
    return <NavigateWithRedirect to={asEnterpriseLink('markets')} />;
  }

  return (
    <QueryBoundaries LoadingComponent={LoadingSkeleton}>
      <RecurringRulesManagerComponent />
    </QueryBoundaries>
  );
};

export default RecurringRulesManager;
