import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '@/enterprise/components/Skeleton';
import useTakerHistory from '@/enterprise/data/useTakerHistory';
import useTakerHistoryStats from '@/enterprise/data/useTakerHistoryStats';
import useTakerMarkets from '@/enterprise/data/useTakerMarkets';
import History from '@/enterprise/features/history/History';
import RangeHeader from '@/enterprise/features/history/RangeHeader';
import { dateRanges } from '@/enterprise/features/history/utils';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import { toUniqueListBy } from '@/utils/common';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import useUrlState from '@/utils/useUrlState';

interface ConsolidatedHistoryUrlState {
  startDate: string;
  endDate: string;
  sort: string;
  limit: number;
  page: number;
  makerIds: string[];
  includeFuturePayDates: boolean;
}

const { last30Days: defaultRange } = dateRanges;
const defaultState = {
  startDate: defaultRange.startDate,
  endDate: defaultRange.endDate,
  sort: '-payDate',
  limit: 25,
  page: 1,
  makerIds: [],
  includeFuturePayDates: true,
} satisfies ConsolidatedHistoryUrlState;

function ConsolidatedHistoryComponent() {
  const { trackEnterpriseEvent } = useReporting();
  const currency = useSelectedCurrency();
  const [urlState, setUrlState] = useUrlState<ConsolidatedHistoryUrlState>(defaultState);
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');

  const params = useMemo(() => {
    return {
      startDate: urlState.startDate ?? defaultRange.startDate,
      endDate: urlState.endDate ?? defaultRange.endDate,
      sort: urlState.sort ?? defaultState.sort,
      limit: urlState.limit ?? defaultState.limit,
      page: urlState.page ?? defaultState.page,
      // useUrlState returns as numbers, components are typed to expect strings
      // Maintain consistency by converting to strings, but data model is number
      // Ideally, UI components should also be typed to numbers
      makerIds: (Array.isArray(urlState.makerIds) ? urlState.makerIds : [urlState.makerIds])?.map(String),
      includeFuturePayDates: enableAwardsEnhancement
        ? urlState.includeFuturePayDates ?? defaultState.includeFuturePayDates
        : false,
    };
  }, [urlState, enableAwardsEnhancement]);

  const endDateForHistory = enableAwardsEnhancement && params.includeFuturePayDates ? '' : params.endDate;

  const {
    data: tableData = [],
    isPending: isPendingTableData,
    isFetching: isFetchingTableData,
  } = useTakerHistory({
    ...params,
    currency,
    pendingClear: false,
    endDate: endDateForHistory,
  });

  // Download endpoint needs an end date for the award archive, need to include latest pay date as end date
  const { data: latestPayDateData, isFetching: fetchingLatestPayDate } = useTakerHistory(
    {
      startDate: params.startDate,
      endDate: endDateForHistory,
      sort: '-payDate',
      page: 1,
      limit: 1,
      currency,
      pendingClear: false,
    },
    { enabled: enableAwardsEnhancement && tableData.length > 0 && params.includeFuturePayDates }
  );

  const latestPayDate = latestPayDateData?.[0]?.payDate;

  const { data: pendingHistory, isFetching: isFetchingPendingHistory } = useTakerHistory(
    {
      ...params,
      currency,
      pendingClear: true,
      endDate: endDateForHistory,
    },
    { enabled: !enableAwardsEnhancement }
  );

  const rangeHasClearHistory = (tableData?.length ?? 0) > 0 || (pendingHistory?.length ?? 0) > 0;

  const { data: chartData = [] } = useTakerHistory(
    {
      currency,
      ...params,
      endDate: endDateForHistory,
      ...(enableAwardsEnhancement && { pendingClear: false }),
    },
    { enabled: rangeHasClearHistory }
  );

  // If the feature flag is enabled, we exclude pending clears from the stats
  const excludePending = enableAwardsEnhancement;

  const { data: stats } = useTakerHistoryStats(
    {
      currency,
      startDate: params.startDate,
      endDate: endDateForHistory,
      makerIds: params.makerIds,
      excludePending,
    },
    { enabled: rangeHasClearHistory }
  );

  const { data: customers = [] } = useTakerMarkets((data) =>
    toUniqueListBy(
      [
        ...new Set(
          data.map(({ makerDivisionId, makerDivisionName }) => ({
            value: `${makerDivisionId}`,
            label: makerDivisionName,
          }))
        ),
      ],
      'label'
    )
  );

  return (
    <div className="rounded-md bg-white shadow-md">
      <RangeHeader
        customers={customers}
        endDate={params.endDate}
        selectedCustomers={params.makerIds}
        startDate={params.startDate}
        isDownloadDisabled={!stats?.clearedInvoiceAmount || fetchingLatestPayDate}
        includeFuturePayDates={enableAwardsEnhancement && params.includeFuturePayDates}
        latestPayDate={latestPayDate}
      />
      <History
        currency={currency}
        startDate={params.startDate}
        endDate={params.endDate}
        onDateRangeChange={(startDate, endDate, includeFuturePayDates) => {
          setUrlState({
            ...urlState,
            startDate,
            endDate,
            includeFuturePayDates: enableAwardsEnhancement ? includeFuturePayDates : false,
            page: 1,
          });
        }}
        sort={params.sort}
        onSortChange={(sort) => {
          setUrlState({ ...urlState, sort, page: 1 });
          trackEnterpriseEvent('history-sort::clicked', { key: sort });
        }}
        page={params.page}
        onPageChange={(page) => {
          setUrlState({ ...urlState, page });
          trackEnterpriseEvent('history-page::clicked');
        }}
        limit={params.limit}
        onLimitChange={(limit) => {
          setUrlState({ ...urlState, limit });
          trackEnterpriseEvent('history-page-size::clicked', { size: limit });
        }}
        chartData={chartData}
        awardedData={tableData}
        isFetchingAwardedData={isFetchingTableData}
        isPendingAwardedData={isPendingTableData}
        pendingData={pendingHistory}
        isFetchingPendingData={isFetchingPendingHistory}
        stats={stats}
        customers={customers}
        selectedCustomers={params.makerIds}
        onCustomersChange={(makerIds) => setUrlState({ ...urlState, makerIds, page: 1 })}
        historyType="consolidated"
        rangeHasClearHistory={rangeHasClearHistory}
        includeFuturePayDates={params.includeFuturePayDates}
        onIncludeFuturePayDatesChange={(value) => {
          setUrlState({ ...urlState, includeFuturePayDates: value });
          trackEnterpriseEvent('history-include-future-pay-dates::clicked', { value });
        }}
      />
    </div>
  );
}

export default function ConsolidatedHistory() {
  const { t } = useTranslation();
  const [isHistoryEnhancementEnabled] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');

  return (
    <>
      {!isHistoryEnhancementEnabled && <h1 className="mt-0 text-4xl">{t('taker.consolidatedHistory.general')}</h1>}
      <QueryBoundaries LoadingComponent={() => <Skeleton />}>
        <ConsolidatedHistoryComponent />
      </QueryBoundaries>
    </>
  );
}
