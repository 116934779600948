/**
 * Takes a number string expected to be a rate in any locale & converts to number format compatible with expected domain rate format
 * 10,7 becomes 10.7
 * 100 000,71 becomes 100000.71
 */
const localeRateToDomain = (value: string | null) => {
  return value
    ? value
        .replace(/[.,](?=.*[.,])/g, '') // remove all commas/periods except for last occurrence
        .replace(/\s/g, '') // remove all whitespace
        .replace(/,/g, '.') // replace last comma with period for intl decimals if applicable
    : null;
};

export default localeRateToDomain;
