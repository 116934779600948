import { enterpriseBasename, smbBasename } from '@/constants';

/**
 * prepends enterprise basename to provided link
 * if no link is provided, returns enterprise homepage
 */
export const asEnterpriseLink = (link?: string) => {
  return link ? `/${enterpriseBasename}/${link}` : `/${enterpriseBasename}`;
};

/**
 * prepends smb basename to provided link
 * if no link is provided, returns smb homepage
 */
export const asSmbLink = (link?: string) => {
  return link ? `/${smbBasename}/${link}` : `/${smbBasename}`;
};

/**
 * prepends enterprise or smb basename to provided link based on current experience
 * if no link is provided, returns current experience homepage
 */
export const asMultiExperienceLink = (link?: string) => {
  const { pathname } = window.location;
  return pathname.includes('enterprise') ? asEnterpriseLink(link) : asSmbLink(link);
};
