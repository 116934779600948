import { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { enterpriseBasename } from '@/constants';
import FullPageLoader from '@/shared/components/FullPageLoader';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getToken from '@/utils/token';

/**
 * Impersonation URL
 * https://app.c2fo.com/enterprise/auth/impersonate?authorization=${impersonateToken}&draftId=${draftId}&referrer=${referrer}
 */
const AuthImpersonation = () => {
  const [searchParams] = useSearchParams();
  const [readyToNavigate, setReadyToNavigate] = useState(false);

  const authorization = searchParams.get('authorization');
  const draftOfferId = searchParams.get('draftId');

  useEffectOnce(() => {
    try {
      if (!authorization) {
        throw new Error('No impersonation token provided via query param');
      }

      // Our token handling logic already looks for 'authorization' in query params and then sets it when no token yet exists.
      getToken();

      setReadyToNavigate(true);
    } catch (error) {
      console.error(error);
      setReadyToNavigate(true);
    }
  });

  if (readyToNavigate && draftOfferId != null) {
    return <Navigate to={asEnterpriseLink(`offer/${draftOfferId}`)} />;
  }

  if (readyToNavigate && draftOfferId == null) {
    return <Navigate to={`/${enterpriseBasename}`} />;
  }

  return <FullPageLoader />;
};

export default AuthImpersonation;
