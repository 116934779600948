import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, cn } from '@c2fo/liquidity';
import invoices from '@/enterprise/assets/invoicesgrey.svg?url';
import NoDataState from '@/enterprise/components/NoDataState';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/enterprise/components/Table';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useTakerMarketsGroups from '@/enterprise/data/useTakerMarketsGroups';
import NameYourRateParticipationToggle from '@/enterprise/features/nameYourRate/ParticipationToggle';
import NameYourRateSetOffer from '@/enterprise/features/nameYourRate/SetOffer';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useIsNewDivision from '@/utils/useIsNewDivision';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import useEnableRecurringRules from '../recurringRules/utils/useEnableRecurringRules';
import useGetRecurringRulesForTakerMarkets from '../recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import ManualExclusionChip from './components/ManualExclusionChip';
import NextMarketClearTimeCountdown from './components/NextMarketClearTimeCountdown';
import OfferDiscountAPRDisplay from './components/OfferDiscountAPRDisplay';
import RecurringRuleExclusionChip from './components/RecurringRuleExclusionChip';
import sortTakerMarketTable, { Sort, SortKey } from './utils/sortTakerMarketTable';

const NameYourRateMarketTable = () => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const navigate = useNavigate();
  const { getRestrictions } = useRestrictions();
  const { isNewDivision } = useIsNewDivision();
  const { asCurrency, asNumber } = useLocaleFormat();
  const { listenToMarketStats } = useServerSideEventListeners();
  const [editOffer, setEditOffer] = useState<TakerMarket | null>(null);
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const takerMarketsGroups = useTakerMarketsGroups();
  const activeTakerMarkets = sortTakerMarketTable(takerMarketsGroups['NAME_YOUR_RATE'].takerMarkets, sort);
  const enableRecurringRules = useEnableRecurringRules();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();

  const isExpirationDateShown = activeTakerMarkets?.some((takerMarket) => takerMarket.disableAfterMarketClearsDate);
  const firstTakerMarketWithRule = activeTakerMarkets?.filter((takerMarket) => {
    const [recurringRule] = getRecurringRulesForTakerMarkets([takerMarket]);
    return !!recurringRule;
  })[0];

  const handleSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'makerOrganizationName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const tableHeadColumns: { accessorKey: SortKey; translation: string }[] = [
    {
      accessorKey: 'participation',
      translation: t('core.participation'),
    },
    {
      accessorKey: 'makerOrganizationName',
      translation: t('core.division'),
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      translation: t('taker.dictionary.availableAR.label'),
    },
    {
      accessorKey: 'marketPayDate',
      translation: t('taker.dictionary.payDate.label'),
    },
    ...(isExpirationDateShown
      ? [
          {
            accessorKey: 'disableAfterMarketClearsDate' as SortKey,
            translation: t('taker.dictionary.expirationDate.label'),
          },
        ]
      : []),
    {
      accessorKey: 'offerConfig',
      translation: t('taker.dictionary.offer.label'),
    },
    {
      accessorKey: 'acceptedInvoiceAmount',
      translation: t('taker.dictionary.clearing.label'),
    },
    {
      accessorKey: 'notAcceptedInvoiceAmount',
      translation: t('taker.dictionary.nonClearing.label'),
    },
    {
      accessorKey: 'acceptedEarn',
      translation: t('taker.dictionary.discount.label'),
    },
    {
      accessorKey: 'eligibleDpeWeightedAvg',
      translation: t('taker.dictionary.dpe.label'),
    },
  ];

  const openEditOfferModal = (takerMarket: TakerMarket) => {
    setEditOffer(takerMarket);
    trackEnterpriseEvent('offer::clicked');
  };

  const closeEditOfferModal = () => setEditOffer(null);

  return (
    <>
      {activeTakerMarkets && activeTakerMarkets.length > 0 ? (
        <>
          <NameYourRateSetOffer open={!!editOffer} takerMarket={editOffer} onClose={closeEditOfferModal} />
          <div className="w-full overflow-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  {tableHeadColumns.map(({ accessorKey, translation }) => (
                    <TableHead
                      key={accessorKey}
                      onClick={() => handleSort(accessorKey)}
                      {...(['makerOrganizationName'].includes(accessorKey) && { className: 'max-w-[224px]' })}
                      {...(['participation'].includes(accessorKey) && { className: 'w-[170px]' })}
                    >
                      <TableSortArrow
                        accessorKey={accessorKey}
                        sort={sort}
                        {...(!['makerOrganizationName', 'participation'].includes(accessorKey) && { textRight: true })}
                      >
                        {translation}
                      </TableSortArrow>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {activeTakerMarkets?.map((takerMarket, index) => {
                  const listeningForMarketStat = listenToMarketStats([
                    {
                      marketUuid: takerMarket.marketUuid,
                      takerId: takerMarket.takerDivisionId,
                    },
                  ]);
                  const [recurringRule] = getRecurringRulesForTakerMarkets([takerMarket]);
                  const { canEditOffers } = getRestrictions([takerMarket]);

                  /**
                   * determine intercom target for table row
                   * if first market has both a rule and is first eligible division, recurring rules intercom target will be used
                   */
                  const showIntercomRecurringRulesTarget =
                    enableRecurringRules && firstTakerMarketWithRule && firstTakerMarketWithRule.id === takerMarket.id;
                  let intercomTarget = showIntercomRecurringRulesTarget ? 'rulesHomepageDivision' : undefined;

                  if (index === 0 && !showIntercomRecurringRulesTarget) {
                    intercomTarget = 'firstEligibleDivision';
                  }

                  return (
                    <TableRow
                      key={takerMarket.id}
                      data-intercom-target={intercomTarget}
                      className={cn('transition-all duration-100', takerMarket.id === editOffer?.id && 'bg-gray-100')}
                    >
                      <TableCell>
                        {/* participation can only be toggled for a taker market that has had a taker config set */}
                        {takerMarket.offerConfig.id && <NameYourRateParticipationToggle takerMarket={takerMarket} />}
                        {takerMarket.offerConfig.id && !takerMarket.marketIsEnabled && (
                          <div className="pt-1 text-xs text-gray-300">{t('closedMarketMessaging.futureOffer')}</div>
                        )}
                      </TableCell>
                      <TableCell className="flex flex-row">
                        {isNewDivision(takerMarket.userAddedToDivision) && (
                          <div className="-ml-4 mr-2 flex h-6 items-center">
                            <div className="h-2 w-2 rounded-full bg-lightBlue-500">
                              <span className="sr-only">{t('core.newDivision')}</span>
                            </div>
                          </div>
                        )}
                        <div className="max-w-[280px] truncate">
                          <Link
                            className="font-bold"
                            to={`../markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
                          >
                            {getTakerMarketName(takerMarket)}
                          </Link>
                          <div className="flex gap-0.5 text-sm font-medium text-gray-600">
                            <span>{takerMarket.currency}</span>
                            <span className="text-lg leading-4">&sdot;</span>
                            <NextMarketClearTimeCountdown takerMarket={takerMarket} />
                          </div>
                          <div
                            className="items-center truncate text-sm font-medium uppercase text-gray-600"
                            title={getTakerMarketDivisionTitle(takerMarket)?.title}
                          >
                            {getTakerMarketDivisionTitle(takerMarket)?.content}
                          </div>
                          {enableRecurringRules && (
                            <RecurringRuleExclusionChip rule={recurringRule} takerMarket={takerMarket} />
                          )}
                          {!recurringRule && <ManualExclusionChip takerMarket={takerMarket} />}
                        </div>
                      </TableCell>
                      <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                        {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                      </TableCell>
                      <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                        {takerMarket.marketIsEnabled &&
                          takerMarket.marketPayDate &&
                          trimDateTimestamp(takerMarket.marketPayDate)}
                      </TableCell>
                      {isExpirationDateShown && (
                        <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                          {takerMarket.disableAfterMarketClearsDate
                            ? trimDateTimestamp(takerMarket.disableAfterMarketClearsDate)
                            : t('core.na')}
                        </TableCell>
                      )}
                      {/* first offer, taker market has never had a taker config set */}
                      {!takerMarket.offerConfig.id ? (
                        <TableCell colSpan={5} className={cn('text-center', { 'blur-sm': listeningForMarketStat })}>
                          {/* first offer */}
                          <div className="m-auto max-w-lg space-y-2">
                            <div className="text-sm">
                              {takerMarket.eligibleInvoiceAmount <= 0
                                ? t('taker.firstOfferDescriptionWithNoAp', {
                                    eligibleInvoiceAmount: asCurrency(
                                      takerMarket.eligibleInvoiceCount,
                                      takerMarket.currency
                                    ),
                                    makerName: takerMarket.makerDivisionName,
                                    currency: takerMarket.currency,
                                  })
                                : t(
                                    takerMarket.eligibleInvoiceCount === 1
                                      ? 'taker.firstOfferDescriptionSingular'
                                      : 'taker.firstOfferDescription',
                                    {
                                      eligibleInvoiceCount: asNumber({
                                        value: takerMarket.eligibleInvoiceCount,
                                        decimals: 0,
                                      }),
                                      eligibleInvoiceAmount: asCurrency(
                                        takerMarket.eligibleInvoiceCount,
                                        takerMarket.currency
                                      ),
                                      makerName: takerMarket.makerDivisionName,
                                      currency: takerMarket.currency,
                                      eligibleDpeWeightedAvg: asNumber({
                                        value: takerMarket.eligibleDpeWeightedAvg,
                                        decimals: 0,
                                      }),
                                    }
                                  )}
                            </div>
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`../markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
                              >
                                <Button size="sm" variant="secondary">
                                  {t('core.viewInvoices')}
                                </Button>
                              </Link>
                              <Button
                                onClick={() => openEditOfferModal(takerMarket)}
                                disabled={!canEditOffers || listeningForMarketStat}
                                size="sm"
                                variant="primary"
                              >
                                {t('experiments.initialOfferButtonText.set')}
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      ) : (
                        <>
                          <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                            <OfferDiscountAPRDisplay
                              isDiscountBidding={takerMarket.offerConfig.isDiscountBidding}
                              maxDiscount={takerMarket.offerConfig.maxDiscount}
                              maxApr={takerMarket.offerConfig.maxApr}
                              onClick={() => openEditOfferModal(takerMarket)}
                              readOnly={!canEditOffers}
                              disabled={listeningForMarketStat}
                            />
                          </TableCell>
                          {takerMarket.marketIsEnabled && !takerMarket.offerConfig.isEnabled ? (
                            <TableCell colSpan={3} className="text-center text-sm font-medium text-red-500">
                              {t('taker.marketsGridNotParticipating')}
                            </TableCell>
                          ) : (
                            <>
                              <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                                {asCurrency(takerMarket.acceptedInvoiceAmount, takerMarket.currency)}
                              </TableCell>
                              <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                                {asCurrency(takerMarket.notAcceptedInvoiceAmount, takerMarket.currency)}
                              </TableCell>
                              <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                                {asCurrency(takerMarket.acceptedEarn, takerMarket.currency)}
                              </TableCell>
                            </>
                          )}
                          <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                            {asNumber({ value: takerMarket.eligibleDpeWeightedAvg, decimals: 0 })}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </>
      ) : (
        <NoDataState
          className="mb-16"
          icon={<img src={invoices} alt="invoices" className="h-28 w-28" />}
          title={t('closedMarketMessaging.noActiveMarkets')}
          message={t('closedMarketMessaging.nameYourRate')}
          action={
            <Button onClick={() => navigate(asEnterpriseLink('markets/search'))}>{t('taker.marketSearch.cta')}</Button>
          }
        />
      )}
    </>
  );
};

export default NameYourRateMarketTable;
