import { keepPreviousData, useQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/
// api/c2fo/taker/182496/market/01dbe635-f4dd-415e-85d3-8bf6a2f52e01/invoices/ineligible
// ?filter={}&page={page:1,limit:100}&sort=-originalAmount

export interface IneligibleInvoice {
  id: number;
  adjustmentAmount: number | null;
  amount: number;
  created: string;
  companyId: string;
  currency: string;
  dueDate: string;
  invoiceId: string;
  isCoveringAdjustments: boolean;
  isPastDue: boolean;
  isIneligibleAr: boolean;
  isIneligibleEp: boolean;
  isReserved: boolean;
  makerExcluded: boolean;
  makerId: number;
  marketId: number;
  originalAmount: number;
  takerExcluded: boolean;
  takerExcludedForToday: boolean;
  takerId: number;
  voucherId: string;
  estimatedDepositDate: string;
  companyName: string;
  isAdjusted: boolean;
}

type IneligibleInvoiceOriginalAmount = ',25000' | '25000,50000' | '50000,75000' | '75000,';
type IneligibleInvoiceStatus = 'isAdjusted' | 'isMakerExcluded' | 'isPastDue';
export type IneligibleInvoiceSort =
  | 'dueDate'
  | '-dueDate'
  | 'makerExcluded'
  | '-makerExcluded'
  | 'originalAmount'
  | '-originalAmount'
  | 'voucherId'
  | '-voucherId';

export interface IneligibleInvoiceFetchFilters {
  amount?: IneligibleInvoiceOriginalAmount[];
  status?: IneligibleInvoiceStatus[];
}

export interface IneligibleInvoiceFetchOptions {
  page: { page: number; limit: number };
  sort: IneligibleInvoiceSort;
  filter?: IneligibleInvoiceFetchFilters;
}

export const buildIneligibleInvoiceFetchFilter = (filterOptions: IneligibleInvoiceFetchFilters) => {
  if (!filterOptions) {
    return;
  }

  const filter = {
    ...(filterOptions.status && { status__eq: filterOptions.status }),
    ...(filterOptions.amount && { originalAmount__between: filterOptions.amount }),
  };

  return { filter };
};

const fetchIneligibleInvoices = async ({
  marketId,
  takerId,
  options,
}: {
  marketId: string;
  takerId: number;
  options: Partial<IneligibleInvoiceFetchOptions>;
}): Promise<IneligibleInvoice[]> => {
  const params = qs.stringify({
    page: options.page ?? { page: 1, limit: 100 },
    sort: options.sort ?? '-originalAmount',
    ...(options.filter && buildIneligibleInvoiceFetchFilter(options.filter)),
  });

  const data = await apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/invoices/ineligible?${params}`)
    .json<IneligibleInvoice[]>();

  return data || [];
};

const useIneligibleInvoices = <TData = IneligibleInvoice[]>(
  params: {
    marketId: string;
    takerId: number;
    options: Partial<IneligibleInvoiceFetchOptions>;
  },
  select?: (data: IneligibleInvoice[]) => TData
) => {
  const { marketId, takerId, options } = params;

  return useQuery({
    // This is intentionally not useSuspenseQuery, loading states are handled in consumers.
    enabled: !!marketId && !!takerId,
    placeholderData: keepPreviousData,
    queryKey: ['ineligible-invoices', marketId, takerId, options],
    queryFn: () =>
      fetchIneligibleInvoices({
        marketId,
        takerId,
        options,
      }),
    select,
  });
};

export default useIneligibleInvoices;
