import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInfo, Popover, Spinner } from '@c2fo/liquidity';
import { EnterpriseOfferType } from '@/generated/gql/graphql';
import { enterpriseBasename } from '@/constants';
import successSvg from '@/enterprise/assets/success.svg?url';
import { useConfirmAgreementsWhenRequired } from '@/enterprise/data/useAgreements';
import useDraftOffer from '@/enterprise/data/useDraftOffer';
import useSubmitOfferFromDraft from '@/enterprise/data/useSubmitOfferFromDraft';
import { Agreement } from '@/enterprise/features/agreements/Agreement';
import RuleChips from '@/enterprise/features/recurringRules/components/RuleChips';
import useGetRecurringRulesForTakerMarkets from '@/enterprise/features/recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import { RecurringRulesCountChip } from '@/enterprise/features/takerMarketTable/components/RecurringRuleExclusionChip';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import { DraftOfferProps } from '../common/DraftOffer.types';
import { DraftOfferTemplate } from '../common/DraftOfferTemplate';
import { useTakerMarketsInDraftOffer } from '../common/useTakerMarketsInDraftOffer';

export const DraftOfferPriceDiscovery = ({ draftOfferUuid }: DraftOfferProps) => {
  const { data: draftOfferData } = useDraftOffer(draftOfferUuid);
  const { takerMarkets: takerMarketsInOffer, stats } = useTakerMarketsInDraftOffer(draftOfferUuid);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { trackEnterpriseEvent } = useReporting();
  const { mutateAsync: submitDraftOffer, isPending: isSubmittingDraftOffer } = useSubmitOfferFromDraft(draftOfferUuid);
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired(takerMarketsInOffer);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();

  const draftOfferTrackEventData: {
    draftOfferUuid: string;
    type: EnterpriseOfferType;
  } = {
    draftOfferUuid,
    type: 'PRICE_DISCOVERY',
  };

  useEffectOnce(() => {
    trackEnterpriseEvent('draft-offer::viewed', draftOfferTrackEventData);
  });

  const todayDetailsOfferRowContent: { title: ReactNode; content: ReactNode }[] = [
    {
      title: t('draftOffer.labels.totalAr'),
      content: stats.formattedEligibleInvoiceAmount,
    },
    {
      title: t('draftOffer.labels.yourRateAndDiscount'),
      content: (
        <span className="block text-right uppercase">
          {stats.formattedRate} (-{stats.formattedDiscountAmount})
        </span>
      ),
    },
    {
      title: t('draftOffer.labels.estimatedDeposit'),
      content: stats.formattedEstimatedDepositAmount,
    },
    {
      title: t('draftOffer.labels.estimatedDepositDate'),
      content: stats.estimatedDepositDate,
    },
    {
      title: t('draftOffer.labels.invoicesIncluded'),
      content: stats.eligibleInvoiceCount,
    },
  ];

  const ongoingOfferRowContent: { title: ReactNode; content: ReactNode }[] = [
    {
      title: t('draftOffer.labels.offerType'),
      content: t('core.nameYourRate'),
    },
    {
      title: t('draftOffer.labels.rate'),
      content: <span className="uppercase">{stats.formattedRate}</span>,
    },
  ];

  const onCancel = () => {
    trackEnterpriseEvent('draft-offer::cancel', draftOfferTrackEventData);
    navigate(`/${enterpriseBasename}`);
  };

  const onSubmit = async () => {
    try {
      setIsSubmitError(false);

      trackEnterpriseEvent('draft-offer::submit', draftOfferTrackEventData);

      if (!draftOfferUuid || !draftOfferData) {
        throw new Error('Not enough draft offer data available to submit the offer.');
      }

      try {
        await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
      } catch {
        setIsAgreementsErrorNotChecked(true);
        return;
      }

      await submitDraftOffer();

      setIsSuccessModalOpen(true);

      trackEnterpriseEvent('draft-offer::submit::success', draftOfferTrackEventData);
    } catch (error) {
      console.error(error);
      setIsSubmitError(true);
      trackEnterpriseEvent('draft-offer::submit::error', {
        ...draftOfferTrackEventData,
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  };

  const onViewDetails = () => {
    trackEnterpriseEvent('draft-offer::view-details', draftOfferTrackEventData);
    navigate(`/${enterpriseBasename}`);
  };

  return (
    <div>
      <DraftOfferTemplate
        cardSlot={
          <div className="space-y-8">
            <div className="space-y-4">
              <div className="space-y-2">
                <p className="text-base font-bold ">{t('draftOffer.todaysDetails')}</p>
                <p className="text-sm text-secondary-300">{t('draftOffer.reviewCurrentInvoices')}</p>
              </div>
              <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
                {todayDetailsOfferRowContent.map((rowData, i) => (
                  <div key={i} className="flex items-center justify-between gap-4 p-4">
                    <p className="text-sm">{rowData.title}</p>
                    <p className="text-sm font-semibold">{rowData.content}</p>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="space-y-4">
              <div className="space-y-2">
                <p className="text-base font-bold ">{t('draftOffer.ongoingOffer')}</p>
                <p className="text-sm text-secondary-300">{t('draftOffer.ongoingOfferDescription')}</p>
              </div>
              <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
                {ongoingOfferRowContent.map((rowData, i) => (
                  <div key={i} className="flex items-center justify-between gap-4 p-4">
                    <p className="text-sm">{rowData.title}</p>
                    <p className="text-sm font-semibold">{rowData.content}</p>
                  </div>
                ))}
              </div>
              <div className="space-y-2">
                <p className="text-base font-bold ">{t('core.customers')}</p>
              </div>
              <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
                {takerMarketsInOffer.map((tm) => {
                  const [recurringRule] = getRecurringRulesForTakerMarkets([tm]);

                  return (
                    <div key={tm.id} className="items-center justify-between gap-4 p-4 md:flex">
                      <p className="text-sm font-semibold">
                        <span className="flex flex-col gap-3">
                          <span key={tm.id}>
                            <span className="block">{tm.makerDivisionName}</span>
                            <span className="block text-xs font-normal">{getTakerMarketDivisionTitle(tm)?.title}</span>
                          </span>
                        </span>
                      </p>
                      {recurringRule && (
                        <div className="flex items-center justify-between gap-2 p-2 pl-0">
                          <Popover>
                            <Popover.Trigger>
                              <RecurringRulesCountChip count={recurringRule.count} />
                            </Popover.Trigger>
                            <Popover.Content
                              variant="dark"
                              arrow
                              side="top"
                              className="max-w-sm sm:max-w-xl"
                              collisionPadding={16}
                            >
                              <QueryBoundaries
                                LoadingComponent={() => (
                                  <div className="flex items-center justify-center">
                                    <Spinner className="h-12 w-12 fill-white" />
                                  </div>
                                )}
                              >
                                <RuleChips inverse recurringRule={recurringRule} withLabel />
                              </QueryBoundaries>
                            </Popover.Content>
                          </Popover>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {isSubmitError && <Alert type="error" variant="filled" description={t('draftOffer.error')} full />}
              {isAgreementsErrorNotChecked && (
                <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
              )}
              <Agreement
                takersMarkets={takerMarketsInOffer}
                onChange={(v) => {
                  setIsAgreementsChecked(v);
                  setIsAgreementsErrorNotChecked(false);
                }}
              />
            </div>
          </div>
        }
        actionSlot={
          <>
            <Button className="w-1/3" onClick={onCancel} variant="secondary">
              {t('core.cancel')}
            </Button>
            <Button
              className="w-1/3"
              onClick={onSubmit}
              variant="cta"
              disabled={isSubmittingDraftOffer}
              loading={isSubmittingDraftOffer}
            >
              {t('draftOffer.submit')}
            </Button>
          </>
        }
      />

      {/* Success Modal */}
      <Modal open={isSuccessModalOpen} size="sm">
        <ModalTitleInfo>{t('draftOffer.success.title')}</ModalTitleInfo>
        <ModalContent center>
          <img src={successSvg} alt="success" />
          <p className="max-w-xs text-center text-base">{t('draftOffer.success.body')}</p>
        </ModalContent>
        <ModalActions className="sm:justify-center">
          <Button variant="cta" onClick={onViewDetails} className="sm:w-1/2">
            {t('draftOffer.viewDetails')}
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
};
