import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import accountSettingsRouteConfig from '@/accountSettings/Router';
import { smbBasename } from '@/constants';
import findYourCustomerRouteConfig from '@/findYourCustomer/Router';
import useFeature from '@/lib/features';
import { FeatureFlagProtectedRoute } from '@/shared/components/FeatureFlagProtectedRouting';
import FullPageLoader from '@/shared/components/FullPageLoader';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';

const SmbApp = lazy(() => import('@/smb/App'));

export const SmbRedirect = () => {
  const [enableSmbExperience] = useFeature('enterprise-ui_enableSmbExperience');

  if (!enableSmbExperience) {
    return <Navigate to={asEnterpriseLink('history')} />;
  }

  return <Navigate to={`/${smbBasename}`} />;
};

const smbRouteConfig: RouteObject = {
  path: smbBasename,
  element: (
    <FeatureFlagProtectedRoute featureFlag="enterprise-ui_enableSmbExperience">
      <Suspense fallback={<FullPageLoader />}>
        <SmbApp />
      </Suspense>
    </FeatureFlagProtectedRoute>
  ),
  children: [
    {
      index: true,
      element: <div>smb homepage</div>,
    },
    findYourCustomerRouteConfig,
    accountSettingsRouteConfig,
  ],
};

export default smbRouteConfig;
