import { ReactNode, useLayoutEffect } from 'react';
import i18n, { SUPPORTED_LOCALES } from '@/i18n';
import useExchangeRate from '@/shared/data/useExchangeRate';
import useUserDetails from '@/shared/data/useUserDetails';

const isSupportedLanguage = (code: string) => SUPPORTED_LOCALES.find((locale) => locale.code === code)?.code;

const Hydrate = ({ children }: { children: ReactNode }) => {
  // hydrate usd exchange rates
  // these are used in several areas of the app for conversions
  useExchangeRate('USD');

  // hydrate user details
  const { data: user } = useUserDetails();
  const userLocale = user?.me?.personalInfo?.locale;

  useLayoutEffect(() => {
    // set app preferences
    // language
    if (userLocale && !!isSupportedLanguage(userLocale)) {
      i18n.changeLanguage(userLocale);
    }

    // TODO: default currency

    // TODO: other preferences
  }, [userLocale]);

  return children;
};

export default Hydrate;
