import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useInterval } from 'react-use';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { useReporting } from '@/reporting';
import personclock from '@/shared/assets/personclock.svg?url';
import reauthenticate from '@/utils/reauthenticate';
import { getTokenContent, refreshToken } from '@/utils/token';

export default function InactivityMonitor() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { trackMultiExperienceEvent } = useReporting();
  const { getLastActiveTime } = useIdleTimer();

  const extendSession = async () => {
    try {
      await refreshToken();
    } catch (error) {
      trackMultiExperienceEvent('refresh-token::error', {
        type: 'session-extend',
      });
      reauthenticate();
    }

    setShowModal(false);
  };

  useInterval(async () => {
    const tokenContent = getTokenContent();
    const lastActiveTime = getLastActiveTime();
    const nowSeconds = Math.round(Date.now() / 1000);

    if (!tokenContent || !lastActiveTime) {
      return;
    }

    if (tokenContent.exp <= nowSeconds) {
      setShowModal(false);
      trackMultiExperienceEvent('inactivity-monitor-logout');
      reauthenticate();
      return;
    }

    // Refreshing the token if it is about to expire in 5 minutes.
    // For example: If a token has expiration time of 15 minutes, then we will refresh it at 10th minute.
    if (tokenContent.exp - nowSeconds <= 300) {
      const lastActiveSeconds = Math.round(lastActiveTime.getTime() / 1000);

      if (lastActiveSeconds > tokenContent.iat && showModal === false) {
        await extendSession();
      } else {
        if (!showModal) {
          trackMultiExperienceEvent('inactivity-monitor-logout::shown');
          setShowModal(true);
        }
      }
    }
  }, 1000);

  return (
    <Modal open={showModal} onClose={extendSession} size="sm">
      <ModalTitleInfo data-testid="inactivity.modal.header">{t('inactivity.stillThere')}</ModalTitleInfo>
      <ModalContent center>
        <img src={personclock} alt="Person Clock" className="mx-auto" />
        <p>{t('inactivity.logoutWarning')}</p>
      </ModalContent>

      <ModalActions className="sm:justify-center">
        <Button variant="cta" onClick={extendSession} className="sm:min-w-96">
          {t('inactivity.extendSession')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
