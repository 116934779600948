import { TakerMarket } from '@/enterprise/data/useTakerMarkets';

interface TakerMarketsByMakerOrg {
  [makerOrganizationUuid: string]: {
    [currency: string]: { marketId: number; marketUuid: string; takerId: number }[];
  };
}

const groupTakerMarketsByMakerOrg = (takerMarkets: TakerMarket[] = []): TakerMarketsByMakerOrg =>
  takerMarkets.reduce(
    (
      takerMarketsByMakerOrg: TakerMarketsByMakerOrg,
      { currency, makerOrganizationUuid, takerDivisionId, legacyMarketId, marketUuid }: TakerMarket
    ) => {
      const takerMarketMeta = {
        marketId: legacyMarketId,
        marketUuid,
        takerId: takerDivisionId,
      };
      const makerOrganizationUuidFound = Object.keys(takerMarketsByMakerOrg).includes(makerOrganizationUuid);

      if (makerOrganizationUuidFound) {
        if (takerMarketsByMakerOrg[makerOrganizationUuid][currency]) {
          takerMarketsByMakerOrg[makerOrganizationUuid][currency].push(takerMarketMeta);
        } else {
          takerMarketsByMakerOrg[makerOrganizationUuid][currency] = [takerMarketMeta];
        }
      } else {
        takerMarketsByMakerOrg[makerOrganizationUuid] = { [currency]: [takerMarketMeta] };
      }

      return takerMarketsByMakerOrg;
    },
    {}
  );

export default groupTakerMarketsByMakerOrg;
