import { useMutation, useQueries, useSuspenseQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import {
  Agreement,
  AgreementAuditInput,
  GetSupplierAgreementsQuery,
  GetSupplierAgreementsQueryVariables,
} from '@/generated/gql/graphql';
import agreementApiClient from '@/lib/agreementApiClient';
import { gqlClient } from '@/lib/gqlClient';
import { useReporting } from '@/reporting';
import { useToken } from '@/utils/token';
import useTakerMarkets, { TakerMarket } from './useTakerMarkets';

// Response from agreement API for content query with CMS Id
export interface CMSResponse {
  cmsId: string;
  title: string;
  copy: string;
  updatedDate: string;
  updatedLabel: string;
}

// Response from supplier agreement gql joined with content from agreement API
export type AgreementWithContent = CMSResponse & Agreement;

const GET_AGREEMENTS = graphql(`
  query GetSupplierAgreements($marketUuids: [ID!]) {
    agreements(filter: { marketId: { in: $marketUuids } }) {
      edges {
        node {
          id
          cmsId
          marketId
        }
      }
    }
  }
`);

const fetchAgreementIds = (variables: GetSupplierAgreementsQueryVariables): Promise<GetSupplierAgreementsQuery> => {
  return gqlClient.request(GET_AGREEMENTS, variables);
};

// useQuery wrapper for fetching agreement CMS IDs if exist for array of market UUIDs
export const useAgreementIds = (marketUuids: string[]) => {
  const { data: distinctMarketUuids = [] } = useTakerMarkets((takerMarkets) =>
    Array.from(new Set(takerMarkets.map((takerMarket) => takerMarket.marketUuid)))
  );

  return useSuspenseQuery({
    queryKey: ['supplier-agreements', [...distinctMarketUuids.sort()]],
    queryFn: () => fetchAgreementIds({ marketUuids: distinctMarketUuids }),
    staleTime: Infinity,
    select: (data) =>
      data.agreements.edges.map(({ node }) => node).filter((node) => marketUuids.includes(node.marketId)),
  });
};

export const useIsAgreementRequired = (marketUuids: string[]) => {
  const { data: agreements } = useAgreementIds(marketUuids);
  return agreements?.length && agreements.length > 0;
};

export interface AgreementByCmsId {
  cmsId: string;
  marketIds: string[];
  agreementIds: string[];
}

export const agreementsByCmsId = (agreements: Agreement[]) => {
  const agreementsByCmsId: AgreementByCmsId[] = agreements.reduce<AgreementByCmsId[]>(
    (acc, { cmsId, marketId, id }) => {
      const matchingAgreement = acc.find((agreement) => agreement.cmsId === cmsId);
      if (!matchingAgreement) {
        acc.push({ cmsId, marketIds: [marketId], agreementIds: [id] });
      } else {
        matchingAgreement?.marketIds.push(marketId);
        matchingAgreement?.agreementIds.push(id);
      }

      return acc;
    },
    []
  );

  return agreementsByCmsId;
};

async function fetchAgreementContent(cmsId: string): Promise<CMSResponse> {
  const url = `en-us/${cmsId}`;

  const res = await agreementApiClient.get(url).json<CMSResponse>();

  return {
    ...res,
    cmsId,
  };
}

// useQuery wrapper for fetching agreement content by CMS ID
export const useAgreementContent = (cmsIds: string[], enabled = true) => {
  return useQueries({
    queries: cmsIds
      ? cmsIds?.map((cmsId) => ({
          enabled: enabled && !!cmsId,
          queryKey: ['agreement-content', cmsId],
          queryFn: () => fetchAgreementContent(cmsId!),
        }))
      : [],
  });
};

const CONFIRM_AGREEMENTS = graphql(`
  mutation ConfirmSupplierAgreements($agreementAudits: [AgreementAuditInput!]!) {
    createManyAgreementAudits(input: { agreementAudits: $agreementAudits }) {
      dateAccepted
    }
  }
`);

export const useConfirmAgreements = () => {
  const { tokenContent } = useToken();
  const isImpersonation = !!tokenContent?.payload.impersonationSubject;
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? '';
  const { trackEnterpriseEvent } = useReporting();

  return useMutation({
    mutationKey: ['confirm-agreements'],
    mutationFn: (agreements: Agreement[]) => {
      const agreementAudits = agreements.reduce<AgreementAuditInput[]>((acc, agreement) => {
        const audit: AgreementAuditInput = {
          authServiceUuid,
          agreementId: agreement.id,
          /**
           * We spent a lot of time tracing through agreement APIs to understand the revision field.
           * Agreements, at time of comment, do not have actual version identifiers. The closest thing it has is a date in s3 when the agreement was last uploaded, and a date when the last db updated occurred via internal Retool app and by who (but not what content changed). Historically, in the NSE/SMB, we would send the "updatedDate" from the content (formerly CMS managed, currently s3 file) as the revision. This is problematic because it forces the user to download all of the agreement content even if they never actually open the modal to look at it. Further, we're not sure if the "updatedDate" we get from the content is always updated when new revisions are made (e.g. db update vs new content file in s3).
           *
           * Therefore, for now, we will just always set this to the current date time, so in a worse case scenario we could use this to back into which specific agreement was accepted if there are multiple historical versions and the need arises. In the future, this would ideally become a version number.
           */
          revision: new Date().toISOString(),
        };

        return [...acc, audit];
      }, []);

      return gqlClient.request(CONFIRM_AGREEMENTS, { agreementAudits });
    },
    onSuccess: (_, agreements) => {
      trackEnterpriseEvent('agreements::submit::success', {
        isImpersonation,
        additionalAgreementsCount: agreements.length,
        agreements,
      });
    },
  });
};

export const useConfirmAgreementsWhenRequired = (takerMarkets: TakerMarket[]) => {
  const uniqueMarketUuids = Array.from(new Set(takerMarkets.map((takerMarket) => takerMarket.marketUuid)));
  const isAgreementConfirmationRequired = useIsAgreementRequired(uniqueMarketUuids);
  const { data: agreements } = useAgreementIds(uniqueMarketUuids);
  const { mutateAsync: confirmAgreements } = useConfirmAgreements();

  const validateAndConfirmAgreementsConditionally = async ({
    isAgreementsChecked,
  }: {
    isAgreementsChecked: boolean;
  }) => {
    /**
     * If agreements are not required, we can proceed without any checks and exit early.
     * The calling component can proceed with it's flow without any additional checks.
     */
    if (!isAgreementConfirmationRequired || !agreements || agreements.length === 0) {
      return;
    }

    /**
     * If agreements are required, but the user has not checked the checkbox, we throw an error.
     * The calling component should handle this error and display an error message to the user.
     */
    if (isAgreementConfirmationRequired && !isAgreementsChecked) {
      throw new Error('User must accept agreements to proceed');
    }

    /**
     * If agreements are required and the user has checked the checkbox, we proceed with the confirmation.
     * The calling component should handle the success and error states accordingly.
     */
    try {
      await confirmAgreements(agreements);
    } catch (error) {
      throw new Error('Failed to submit agreement confirmation');
    }
  };

  return {
    validateAndConfirmAgreementsConditionally,
  };
};
