import { useSuspenseQuery } from '@tanstack/react-query';
import { fetchExchangeRates, ExchangeRate } from '@/shared/data/useExchangeRates';

export const fetchExchangeRate = async (currency: string): Promise<ExchangeRate | undefined> => {
  const response = await fetchExchangeRates({ currencies: [currency] });
  return response[currency];
};

const useExchangeRate = (currency: string) => {
  return useSuspenseQuery({
    queryKey: ['exchange-rates', currency],
    queryFn: () => fetchExchangeRate(currency),
  });
};

export default useExchangeRate;
