import { useCallback } from 'react';
import useUserDetails from '@/shared/data/useUserDetails';

/**
 * determines if user has been added to a new division since last login
 * by comparing the user's last login to when user was added to division
 *
 * returns true if user has never logged in before or date when user was
 * added to division is after last login
 */
const useIsNewDivision = () => {
  const { data: lastLogin } = useUserDetails((userDetails) => userDetails.me?.loginHistory?.[1]?.created);

  const isNewDivision = useCallback(
    (addedToDivision?: string) => {
      // user has not logged in before, assume new division
      if (!lastLogin) {
        return true;
      }

      // no date to check for when user was added to division, assume not new division
      if (!addedToDivision) {
        return false;
      }

      // added to division after last login
      return addedToDivision >= lastLogin;
    },
    [lastLogin]
  );

  return { isNewDivision };
};

export default useIsNewDivision;
