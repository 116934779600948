import { Chip, cn } from '@c2fo/liquidity';
import { RecurringRule } from '@/enterprise/data/useRecurringRules';
import useRuleDescription from '@/enterprise/features/recurringRules/utils/useRuleDescription';

type RuleChipConfig = {
  [key in keyof Pick<RecurringRule, 'dpe' | 'dueDate' | 'amount' | 'excludedVoucherIds'>]: {
    label: string;
  };
};

interface RuleChipsProps {
  recurringRule: RecurringRule;
  bold?: boolean;
  inverse?: boolean;
  withLabel?: boolean;
}

const RuleChips = ({ bold, inverse, recurringRule, withLabel }: RuleChipsProps) => {
  const { daysPaidEarly, dueDate, invoiceAmount, excludedInvoiceIds, labelText, rulesToRender } =
    useRuleDescription(recurringRule);

  const ruleChipConfig: RuleChipConfig = {
    dpe: { label: daysPaidEarly.short ?? '' },
    dueDate: { label: dueDate.short ?? '' },
    amount: { label: invoiceAmount.short ?? '' },
    excludedVoucherIds: { label: excludedInvoiceIds.short ?? '' },
  };

  return rulesToRender.length > 0 ? (
    <div className="space-y-2">
      {withLabel && (
        <div className={cn('text-sm text-text-secondary', { 'font-medium': bold, 'text-white': inverse })}>
          {labelText}
        </div>
      )}
      <div className="flex flex-wrap items-center gap-2">
        {rulesToRender.map((key) => {
          const config = ruleChipConfig[key as keyof typeof ruleChipConfig]!;

          return <Chip key={key} label={config.label} type="info" variant="outlined" />;
        })}
      </div>
    </div>
  ) : null;
};

export default RuleChips;
