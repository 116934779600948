const LabelWithBadge = ({ label, badgeCount }: { label: string; badgeCount?: number }) => {
  if (badgeCount && badgeCount > 0) {
    return (
      <div className="inline-flex gap-1">
        {label}
        <div className="flex h-4 w-4 justify-center rounded-full bg-lightBlue-500 text-xs text-black">{badgeCount}</div>
      </div>
    );
  }

  return label;
};

export default LabelWithBadge;
