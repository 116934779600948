import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, useSnackbar } from '@c2fo/liquidity';
import Modal from '@/enterprise/components/Modal';
import { useConfirmAgreementsWhenRequired } from '@/enterprise/data/useAgreements';
import useGlobalPriceDiscoveryOffer from '@/enterprise/data/useGlobalPriceDiscoveryOffer';
import useTakerMarketsGroups from '@/enterprise/data/useTakerMarketsGroups';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';
import { Agreement } from '../agreements/Agreement';

const NameYourRateSetGlobalParticipation = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const {
    mutateAsync: submitGlobalPriceDiscoveryParticipation,
    isPending: submitGlobalPriceDiscoveryParticipationLoading,
  } = useGlobalPriceDiscoveryOffer();
  const {
    NAME_YOUR_RATE: { takerMarkets },
  } = useTakerMarketsGroups();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions(takerMarkets);
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired(takerMarkets);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const onSubmit = async () => {
    if (!canEditOffers) {
      return;
    }

    try {
      await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
    } catch {
      setIsAgreementsErrorNotChecked(true);
      return;
    }

    const submitValues = { isEnabled: true };

    await submitGlobalPriceDiscoveryParticipation(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdate') });
        trackEnterpriseEvent('global-participation::submitted', submitValues);
        onClose();
      },
      onError: () => showSnackbar({ message: t('globalOfferSubmitDialog.offerUpdateError') }),
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      cancelText={t('core.cancel')}
      onCancel={onClose}
      okButtonVariant="cta"
      okText={t('core.submit')}
      onOk={onSubmit}
      okButtonDisabled={submitGlobalPriceDiscoveryParticipationLoading}
      loading={submitGlobalPriceDiscoveryParticipationLoading}
      title={t('offer.setParticipation')}
      fullWidthButtons
    >
      <div className="relative space-y-4 bg-white text-text-primary">
        {/* info */}
        <div className="divide-y divide-gray-300 rounded-md bg-gray-100 p-4 font-medium">
          <div>{t('offer.participateInFutureClearsMessage')}</div>
        </div>

        {/* understand info */}
        <div>
          <ul className="ml-4 list-disc space-y-2">
            <li>{t('allOfferDialogs.description2')}</li>
            <li>{t('allOfferDialogs.description3')}</li>
            <li>{t('offerSubmitDialog.disclaimer')}</li>
          </ul>
        </div>

        <Agreement
          takersMarkets={takerMarkets}
          onChange={(v) => {
            setIsAgreementsChecked(v);
            setIsAgreementsErrorNotChecked(false);
          }}
        />
        {isAgreementsErrorNotChecked && (
          <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
        )}
      </div>
    </Modal>
  );
};

export default NameYourRateSetGlobalParticipation;
