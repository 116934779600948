import { ExchangeRates } from '@/shared/data/useExchangeRates';

interface ConvertCurrencyParams {
  /**
   * amount to be converted
   */
  amount: number;
  /**
   * list of exchange rates to derive conversion value
   */
  exchangeRates: ExchangeRates;
  /**
   * currency to convert from
   */
  from: string;
  /**
   * currency to convert to
   */
  to: string;
}

const convertCurrency = ({ amount, exchangeRates, from, to }: ConvertCurrencyParams) => {
  const exchangeRateMap = exchangeRates?.[from]?.[to] ?? 1;
  return amount * exchangeRateMap;
};

export default convertCurrency;
