import ReactDOM from 'react-dom/client';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Snackbar, SnackbarProvider, Tooltip } from '@c2fo/liquidity';
import queryClient from '@/lib/queryClient';
import FullPageLoader from '@/shared/components/FullPageLoader';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import SomethingsWrong from '@/shared/pages/SomethingsWrong';
import i18n from './i18n';
import Router from './Router';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <div className="flex min-h-screen flex-col bg-canvas antialiased">
          <QueryBoundaries ErrorComponent={() => <SomethingsWrong />} LoadingComponent={() => <FullPageLoader />}>
            <SnackbarProvider>
              <Tooltip.Provider delayDuration={0}>
                <Router />
                <ReactQueryDevtools initialIsOpen={false} position="bottom" buttonPosition="bottom-right" />
                <Snackbar vertical="bottom" horizontal="left" />
              </Tooltip.Provider>
            </SnackbarProvider>
          </QueryBoundaries>
        </div>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);
