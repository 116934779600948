import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Label,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitleInverse,
  TextInput,
  useSnackbar,
} from '@c2fo/liquidity';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';
import useSrm from '@/shared/data/useSrm';
import useUserDetails from '@/shared/data/useUserDetails';

export interface RequestModalProps {
  open?: boolean;
  onClose?: () => void;
}

export default function RequestDemoModal({ open, onClose }: RequestModalProps) {
  const srm = useSrm();
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const { data: userDetails } = useUserDetails((data) => {
    const { primaryPhoneNumber } = data.me?.personalInfo ?? {};
    return { primaryPhoneNumber };
  });
  const [phone, setPhone] = useState(userDetails?.primaryPhoneNumber ?? '');
  const srmEmailAddress = srm?.emailAddress ?? 'customerservice@c2fo.com';

  const { mutate: submitRequest } = useMutation({
    mutationKey: ['requestDemo'],
    mutationFn: (srmEmailAddress: string) =>
      apiClient.post(`api/c2fo/user-source/request-demo`, {
        json: { srmEmailAddress },
      }),
    onSuccess: () => {
      onClose?.();
      trackEnterpriseEvent('srm::emailed', { email: srmEmailAddress });
      showSnackbar({ message: t('demoRequest.contactedSoon') });
    },
    onError: () => {
      showSnackbar({ message: t('demoRequest.error') });
    },
  });

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <ModalTitleInverse>{t('demoRequest.verifyPhone')}</ModalTitleInverse>
      <ModalContent>
        <div className="space-y-6">
          <div>{t('demoRequest.contactedSoon')}</div>
          <div className="space-y-1">
            <Label htmlFor="phone">{t('profile.primaryPhone')}</Label>
            <TextInput
              id="phone"
              className="w-3/5"
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
          </div>
        </div>
      </ModalContent>
      <ModalActions>
        <Button variant="primary" onClick={() => submitRequest(srmEmailAddress)}>
          {t('core.submit')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
