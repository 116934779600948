import { useLayoutEffect } from 'react';
import reauthenticate from '@/utils/reauthenticate';

const Reauthenticate = () => {
  useLayoutEffect(() => {
    reauthenticate();
  }, []);

  return null;
};

export default Reauthenticate;
