import { useMemo } from 'react';
import useEnableRecurringRules from '@/enterprise/features/recurringRules/utils/useEnableRecurringRules';
import useGetRecurringRulesForTakerMarkets from '@/enterprise/features/recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import useExchangeRates from '@/shared/data/useExchangeRates';
import convertCurrency from '@/utils/convertCurrency';
import useIsNewDivision from '@/utils/useIsNewDivision';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import useTakerMarkets, { TakerMarket } from './useTakerMarkets';

export type TakerMarketGroupType = 'NAME_YOUR_RATE' | 'BENCHMARK' | 'FIXED' | 'PREFERRED' | 'INACTIVE';
interface TakerMarketGroupRollup {
  count: number;
  amount: number;
  newDivisionCount: number;
  excludedAmount: number;
  rulesExcludedAmount: number;
}
export type TakerMarketGroupData = Record<
  TakerMarketGroupType,
  {
    takerMarkets: TakerMarket[];
    takerMarketsWithRules: TakerMarket[];
    takerMarketsWithoutRules: TakerMarket[];
    rollup: TakerMarketGroupRollup;
  }
>;

const defaultRollup: TakerMarketGroupRollup = {
  count: 0,
  amount: 0,
  newDivisionCount: 0,
  excludedAmount: 0,
  rulesExcludedAmount: 0,
};

const useTakerMarketsGroups = (): TakerMarketGroupData => {
  const { data: takerMarkets = [] } = useTakerMarkets();
  const { isNewDivision } = useIsNewDivision();
  const { data: exchangeRates = {} } = useExchangeRates();
  const selectedCurrency = useSelectedCurrency();
  const enableRecurringRules = useEnableRecurringRules();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();

  const takerMarketTabData = useMemo(() => {
    const data: TakerMarketGroupData = {
      NAME_YOUR_RATE: {
        takerMarkets: [],
        takerMarketsWithRules: [],
        takerMarketsWithoutRules: [],
        rollup: { ...defaultRollup },
      },
      BENCHMARK: {
        takerMarkets: [],
        takerMarketsWithRules: [],
        takerMarketsWithoutRules: [],
        rollup: { ...defaultRollup },
      },
      FIXED: {
        takerMarkets: [],
        takerMarketsWithRules: [],
        takerMarketsWithoutRules: [],
        rollup: { ...defaultRollup },
      },
      PREFERRED: {
        takerMarkets: [],
        takerMarketsWithRules: [],
        takerMarketsWithoutRules: [],
        rollup: { ...defaultRollup },
      },
      INACTIVE: {
        takerMarkets: [],
        takerMarketsWithRules: [],
        takerMarketsWithoutRules: [],
        rollup: { ...defaultRollup },
      },
    };

    takerMarkets.forEach((takerMarket) => {
      const hasRule = getRecurringRulesForTakerMarkets([takerMarket]).length > 0;
      const isRuleExcluded = enableRecurringRules && hasRule;
      const convertedEligibleInvoiceAmount = convertCurrency({
        amount: takerMarket.eligibleInvoiceAmount,
        from: takerMarket.currency,
        to: selectedCurrency,
        exchangeRates,
      });
      const convertedExcludedInvoiceAmount = convertCurrency({
        amount: takerMarket.takerExcludedInvoiceAmount,
        from: takerMarket.currency,
        to: selectedCurrency,
        exchangeRates,
      });
      const incrementNewDivisionCount = isNewDivision(takerMarket.userAddedToDivision) ? 1 : 0;

      /**
       * Each TM should only ever go into a single tab
       */
      if (takerMarket.offerConfig.isEnabled && takerMarket.offerConfig.offerType === 'PREFERRED_TERM') {
        // Preferred will always take precedence, even over "inactive" state
        data.PREFERRED.takerMarkets.push(takerMarket);
        data.PREFERRED.rollup.count += 1;
        data.PREFERRED.rollup.amount += convertedEligibleInvoiceAmount;
        data.PREFERRED.rollup.newDivisionCount += incrementNewDivisionCount;
        if (isRuleExcluded) {
          data.PREFERRED.takerMarketsWithRules.push(takerMarket);
          data.PREFERRED.rollup.rulesExcludedAmount += convertedExcludedInvoiceAmount;
        } else {
          data.PREFERRED.takerMarketsWithoutRules.push(takerMarket);
          data.PREFERRED.rollup.excludedAmount += convertedExcludedInvoiceAmount;
        }
      } else if (takerMarket.marketIsEnabled === false) {
        data.INACTIVE.takerMarkets.push(takerMarket);
        data.INACTIVE.rollup.count += 1;
        data.INACTIVE.rollup.amount += convertedEligibleInvoiceAmount;
        data.INACTIVE.rollup.newDivisionCount += incrementNewDivisionCount;
        if (isRuleExcluded) {
          data.INACTIVE.takerMarketsWithRules.push(takerMarket);
          data.INACTIVE.rollup.rulesExcludedAmount += convertedExcludedInvoiceAmount;
        } else {
          data.INACTIVE.takerMarketsWithoutRules.push(takerMarket);
          data.INACTIVE.rollup.excludedAmount += convertedExcludedInvoiceAmount;
        }
      } else if (takerMarket.marketPricingType === 'BENCHMARK') {
        data.BENCHMARK.takerMarkets.push(takerMarket);
        data.BENCHMARK.rollup.count += 1;
        data.BENCHMARK.rollup.amount += convertedEligibleInvoiceAmount;
        data.BENCHMARK.rollup.newDivisionCount += incrementNewDivisionCount;
        if (isRuleExcluded) {
          data.BENCHMARK.takerMarketsWithRules.push(takerMarket);
          data.BENCHMARK.rollup.rulesExcludedAmount += convertedExcludedInvoiceAmount;
        } else {
          data.BENCHMARK.takerMarketsWithoutRules.push(takerMarket);
          data.BENCHMARK.rollup.excludedAmount += convertedExcludedInvoiceAmount;
        }
      } else if (takerMarket.marketPricingType === 'STATIC') {
        data.FIXED.takerMarkets.push(takerMarket);
        data.FIXED.rollup.count += 1;
        data.FIXED.rollup.amount += convertedEligibleInvoiceAmount;
        data.FIXED.rollup.newDivisionCount += incrementNewDivisionCount;
        if (isRuleExcluded) {
          data.FIXED.takerMarketsWithRules.push(takerMarket);
          data.FIXED.rollup.rulesExcludedAmount += convertedExcludedInvoiceAmount;
        } else {
          data.FIXED.takerMarketsWithoutRules.push(takerMarket);
          data.FIXED.rollup.excludedAmount += convertedExcludedInvoiceAmount;
        }
      } else {
        // Default to NAME_YOUR_RATE
        data.NAME_YOUR_RATE.takerMarkets.push(takerMarket);
        data.NAME_YOUR_RATE.rollup.count += 1;
        data.NAME_YOUR_RATE.rollup.amount += convertedEligibleInvoiceAmount;
        data.NAME_YOUR_RATE.rollup.newDivisionCount += incrementNewDivisionCount;
        if (isRuleExcluded) {
          data.NAME_YOUR_RATE.takerMarketsWithRules.push(takerMarket);
          data.NAME_YOUR_RATE.rollup.rulesExcludedAmount += convertedExcludedInvoiceAmount;
        } else {
          data.NAME_YOUR_RATE.takerMarketsWithoutRules.push(takerMarket);
          data.NAME_YOUR_RATE.rollup.excludedAmount += convertedExcludedInvoiceAmount;
        }
      }
    });

    return data;
  }, [
    exchangeRates,
    isNewDivision,
    selectedCurrency,
    takerMarkets,
    enableRecurringRules,
    getRecurringRulesForTakerMarkets,
  ]);

  return takerMarketTabData;
};

export default useTakerMarketsGroups;
