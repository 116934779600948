import { useTranslation } from 'react-i18next';
import RadioInput from '@/enterprise/components/RadioInput';

export interface InvoiceReinclusionProps {
  excludedInvoicesCount: number;
  excludedInvoicesAmount: string;
  handleInvoices: 'include' | 'exclude' | null;
  handleInvoicesChange: (value: 'include' | 'exclude') => void;
}

const InvoiceReinclusion = ({
  excludedInvoicesCount,
  excludedInvoicesAmount,
  handleInvoices,
  handleInvoicesChange,
}: InvoiceReinclusionProps) => {
  const { t } = useTranslation();

  if (!excludedInvoicesCount) {
    return null;
  }

  return (
    <div className="pt-4">
      <div className="font-bold">{t('recurringRules.currentlyExcludedInvoices')}</div>
      <div>
        {excludedInvoicesCount === 1
          ? t('recurringRules.excludedInvoiceDescription', {
              count: excludedInvoicesCount,
              amount: excludedInvoicesAmount,
            })
          : t('recurringRules.excludedInvoiceDescriptionPlural', {
              count: excludedInvoicesCount,
              amount: excludedInvoicesAmount,
            })}
        <div>{t('recurringRules.handleInvoicesLabel')}</div>
      </div>
      <div className="space-y-4 pt-4">
        <RadioInput
          checked={handleInvoices === 'include'}
          label={t('core.includeInvoices')}
          description={t('recurringRules.handleInvoicesInclude')}
          onChange={() => handleInvoicesChange('include')}
          value="include"
        />
        <RadioInput
          checked={handleInvoices === 'exclude'}
          label={t('core.excludeInvoices')}
          description={t('recurringRules.handleInvoicesExclude')}
          onChange={() => handleInvoicesChange('exclude')}
          value="exclude"
        />
      </div>
    </div>
  );
};

export default InvoiceReinclusion;
