import { EligibleInvoice } from '@/enterprise/data/useEligibleInvoices';
import addOrRemoveFromArray from '@/utils/addOrRemoveFromArray';

// EXCLUDE INVOICE CHECKS
export const isIncluded = (invoice: EligibleInvoice) =>
  invoice.takerExcluded === false && invoice.takerExcludedForToday === false;

export const isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment = (invoice: EligibleInvoice) =>
  invoice.coversUnmatched && invoice.amount > 0;

// INCLUDE INVOICE CHECKS
export const isExcluded = (invoice: EligibleInvoice) =>
  invoice.takerExcluded === true || invoice.takerExcludedForToday === true;

// SELECTED INVOICE REDUCER
export interface SelectedInvoiceState {
  enableExcludeButton: boolean;
  enableIncludeButton: boolean;
  isBulk: boolean;
  selectedInvoices: EligibleInvoice[];
  selectedInvoiceAmount: number;
  selectedInvoiceCount: number;
}

export type SelectedInvoiceAction =
  | {
      type: 'invoiceClicked';
      payload: { invoice: EligibleInvoice };
    }
  | {
      type: 'selectAll';
      payload: { invoices: EligibleInvoice[] };
    }
  | {
      type: 'unselectAll';
    };

export const selectedInvoiceReducer = (state: SelectedInvoiceState, action: SelectedInvoiceAction) => {
  const { type } = action;

  switch (type) {
    case 'invoiceClicked': {
      const { invoice } = action.payload;
      const invoices = addOrRemoveFromArray(state.selectedInvoices, invoice);

      const selectedInvoices = invoices;
      const selectedInvoiceAmount = invoices.reduce((acc, curr) => (acc += curr?.amount ?? 0), 0);
      const selectedInvoiceCount = invoices.length;
      const enableExcludeButton = invoices.some((invoice) => !invoice.takerExcluded);
      const enableIncludeButton = invoices.some((invoice) => invoice.takerExcluded);

      return {
        ...state,
        enableExcludeButton,
        enableIncludeButton,
        isBulk: false,
        selectedInvoices,
        selectedInvoiceAmount,
        selectedInvoiceCount,
      };
    }

    case 'selectAll': {
      const { invoices } = action.payload;

      const selectedInvoices = invoices?.filter(
        (invoice) => !isPartiallyAdjustedInvoiceCoveringUnmatchedAdjustment(invoice)
      );
      const selectedInvoiceAmount = selectedInvoices.reduce((acc, curr) => (acc += curr?.amount ?? 0), 0);
      const selectedInvoiceCount = selectedInvoices.length;
      const enableExcludeButton = selectedInvoices.some((invoice) => !invoice.takerExcluded);
      const enableIncludeButton = selectedInvoices.some((invoice) => invoice.takerExcluded);

      return {
        ...state,
        enableExcludeButton,
        enableIncludeButton,
        isBulk: true,
        selectedInvoices,
        selectedInvoiceAmount,
        selectedInvoiceCount,
      };
    }

    case 'unselectAll': {
      return {
        ...state,
        enableExcludeButton: false,
        enableIncludeButton: false,
        isBulk: false,
        selectedInvoices: [],
        selectedInvoiceAmount: 0,
        selectedInvoiceCount: 0,
      };
    }

    default: {
      return state;
    }
  }
};
