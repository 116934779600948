import { useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';
import { buildIneligibleInvoiceFetchFilter, IneligibleInvoiceFetchOptions } from './useIneligibleInvoices';

// https://fss-gateway.c2fo.com/
// api/c2fo/taker/3563147/market/61af100a-d391-4afe-832b-4d4a0e0bb8a1/invoices/ineligible/stats
// ?filter=%7B%7D

export interface IneligibleInvoiceStats {
  count: number | null;
  subTotal: number | null;
}

const fetchIneligibleInvoiceStats = async ({
  marketId,
  takerId,
  options,
}: {
  marketId: string;
  takerId: number;
  options: Partial<IneligibleInvoiceFetchOptions>;
}): Promise<IneligibleInvoiceStats> => {
  const params = qs.stringify({
    ...(options?.filter && buildIneligibleInvoiceFetchFilter(options.filter)),
  });

  const getUrl = params
    ? `api/c2fo/taker/${takerId}/market/${marketId}/invoices/ineligible/stats?${params}`
    : `api/c2fo/taker/${takerId}/market/${marketId}/invoices/ineligible/stats`;

  return apiClient.get(getUrl).json<IneligibleInvoiceStats>();
};

const useIneligibleInvoiceStats = (params: {
  marketId: string;
  takerId: number;
  options: Partial<IneligibleInvoiceFetchOptions>;
}) => {
  const { marketId, takerId, options } = params;

  return useSuspenseQuery({
    queryKey: ['ineligible-invoice-stats', marketId, takerId, options.filter],
    queryFn: () =>
      !!marketId && !!takerId
        ? fetchIneligibleInvoiceStats({
            marketId,
            takerId,
            options,
          })
        : null,
  });
};

export default useIneligibleInvoiceStats;
