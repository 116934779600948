import { RecurringRule } from '@/enterprise/data/useRecurringRules';
import useRuleDescription from '@/enterprise/features/recurringRules/utils/useRuleDescription';

const RulePreview = ({ rule }: { rule: RecurringRule }) => {
  const { labelText, daysPaidEarly, dueDate, invoiceAmount, excludedInvoiceIds } = useRuleDescription(rule);

  return rule ? (
    <div className="space-y-2 bg-canvas p-4 text-sm">
      <div>{labelText}</div>
      <ul className="ml-4 list-disc space-y-0.5 font-bold">
        {daysPaidEarly.long && <li>{daysPaidEarly.long}</li>}
        {dueDate.long && <li>{dueDate.long}</li>}
        {invoiceAmount.long && <li>{invoiceAmount.long}</li>}
        {excludedInvoiceIds.long && <li>{excludedInvoiceIds.long}</li>}
      </ul>
    </div>
  ) : null;
};

export default RulePreview;
