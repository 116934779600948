import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import colors from '@c2fo/liquidity/colors';
import { MessageIcon, SyncIcon } from '@c2fo/liquidity/icons';
import useDataDog from '@/reporting/DataDog';
import Intercom, { useIntercom } from '@/reporting/Intercom';
import connectivity from '@/shared/assets/connectivity.svg?url';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import TrustpilotWidget from '@/shared/components/TrustpilotWidget';

const loginUrl = import.meta.env.VITE_LOGIN_URL;

const SomethingsWrong = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const { addError } = useDataDog();
  const { pathname } = useLocation();

  useEffect(() => {
    addError('top level error page', {
      pathName: pathname,
    });
  }, [addError, pathname]);

  return (
    <div className="flex min-h-screen flex-col items-center bg-canvas p-0 antialiased lg:px-8">
      <div className="flex flex-1 flex-col space-y-12 lg:flex-row lg:items-center lg:space-y-0">
        <img src={connectivity} alt="Connectivity Warning" className="max-w-sm px-8 pt-8 lg:p-0" />
        <div className="max-w-2xl px-8 pb-8 lg:p-0">
          <h1 className="mb-6 mt-0 text-5xl font-bold">{t('somethingsWrong.heading')}</h1>
          <h3 className="mb-1 mt-0 text-3xl font-medium">{t('somethingsWrong.subheading')}</h3>
          <h5 className="mb-12 mt-0 text-xl font-normal text-text-secondary">{t('somethingsWrong.message')}</h5>
          <p className="mb-3 font-medium">{t('somethingsWrong.subheading2')}</p>
          <div className="flex flex-col gap-6 md:flex-row">
            <div className="flex w-full items-center justify-between gap-4 rounded-md bg-white px-6 py-4 text-left shadow-md md:w-1/2">
              <div>
                <p className="text-sm font-medium">{t('somethingsWrong.cta1Heading')}</p>
                <p className="text-sm text-text-secondary">{t('somethingsWrong.cta1Message')}</p>
              </div>
              <button
                className="rounded-md p-4 hover:bg-gray-100"
                onClick={() => window.location.replace(loginUrl)}
                type="button"
              >
                <SyncIcon className="h-7 w-auto lg:block" fill={colors.blue[800]} />
              </button>
            </div>
            <div className="flex w-full items-center justify-between gap-4 rounded-md bg-white px-6 py-4 text-left shadow-md md:w-1/2">
              <div>
                <p className="text-sm font-medium">{t('somethingsWrong.cta2Heading')}</p>
                <p className="text-sm text-text-secondary">{t('somethingsWrong.cta2Message')}</p>
              </div>
              {/* Wrapped with extra boundaries to be extra safe, as more deps are used in here */}
              <QueryBoundaries>
                <IntercomSupportButtonForErrorPage />
              </QueryBoundaries>
            </div>
          </div>
        </div>
      </div>
      <div className="my-8 flex w-full flex-col items-center gap-4 text-center">
        <div className="text-sm text-text-secondary">{t('core.legalCopyright', { year })}</div>
        <div className="h-12 overflow-hidden">
          <TrustpilotWidget />
        </div>
      </div>
    </div>
  );
};

const IntercomSupportButtonForErrorPage = () => {
  const { openChat } = useIntercom();

  return (
    <>
      <Intercom />
      <button className="rounded-md p-4 hover:bg-gray-100" onClick={() => openChat()} type="button">
        <MessageIcon className="h-7 w-auto lg:block" fill={colors.blue[800]} />
      </button>
    </>
  );
};

export default SomethingsWrong;
