import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { takersMarketIsParticipating } from '@/utils/takersMarketIsParticipating';

export interface MarketDisabledAlertProps {
  takerMarket: TakerMarket;
}

export const MarketDisabledAlert = ({ takerMarket }: MarketDisabledAlertProps) => {
  const { t } = useTranslation();
  const isNYR = takerMarket.marketPricingType === 'PRICE_DISCOVERY';
  const isParticipating = takersMarketIsParticipating(takerMarket);

  let alertDescription = t('closedMarketMessaging.alertBanner.nameYourRate.setFutureOffer');
  if (isParticipating) {
    alertDescription = t('closedMarketMessaging.alertBanner.futureOfferActiveWhenMarketEnabled');
  } else {
    if (isNYR) {
      alertDescription = t('closedMarketMessaging.alertBanner.nameYourRate.setFutureOffer');
    } else {
      alertDescription = t('closedMarketMessaging.alertBanner.fixedAndVariableRate.setOfferWhenMarketEnabled');
    }
  }

  return (
    <div className="mb-8">
      <Alert
        description={alertDescription}
        full
        title={t('closedMarketMessaging.alertBanner.notAcceptingOffers', {
          marketName: getTakerMarketName(takerMarket),
        })}
        type="warning"
        variant="outlined"
      />
    </div>
  );
};
