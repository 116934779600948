import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Alert, cn, HelperText, Label, TextInput, useSnackbar } from '@c2fo/liquidity';
import { PercentIcon } from '@c2fo/liquidity/icons';
import Modal, { ModalProps } from '@/enterprise/components/Modal';
import { OfferConfigDetails, OfferConfigDetailsItem } from '@/enterprise/components/OfferConfigDetails';
import { OfferDuration } from '@/enterprise/components/OfferDuration';
import { OfferReviewDetails } from '@/enterprise/components/OfferReviewDetails';
import { useConfirmAgreementsWhenRequired } from '@/enterprise/data/useAgreements';
import useSubmitPriceDiscoveryOffer from '@/enterprise/data/useSubmitPriceDiscoveryOffer';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import getTakerMarketName from '@/utils/getTakerMarketName';
import localeRateToDomain from '@/utils/localeRateToDomain';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import { Agreement } from '../agreements/Agreement';
import {
  OfferLimits,
  SetOfferFormInputs,
  getRateFromTakerMarket,
  getRateTypeFromTakerMarket,
  validateRate,
} from './utils';

const NameYourRateSetOfferComponent = ({
  futureOffer,
  onClose,
  open,
  takerMarket,
  showReviewStep,
}: {
  futureOffer?: boolean;
  onClose: () => void;
  open: boolean;
  takerMarket: TakerMarket;
  showReviewStep?: boolean;
}) => {
  const initalStep = showReviewStep ? 2 : 1;
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions([takerMarket]);
  const [step, setStep] = useState<number>(initalStep);
  const { asCurrency, asNumber, asPercent } = useLocaleFormat();
  const { mutateAsync: submitPriceDiscoveryOffer, isPending: submitPriceDiscoveryOfferIsLoading } =
    useSubmitPriceDiscoveryOffer();
  const [isSetExpirationDateEnabled] = useFeature('enterprise-ui_enableSetExpirationDate');
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired([takerMarket]);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const isLoading = submitPriceDiscoveryOfferIsLoading;

  const offerLimits: OfferLimits = {
    disc: {
      low: 0,
      high: takerMarket.marketMaxDiscount ?? 30,
    },
    apr: {
      low: 0,
      high: takerMarket.marketMaxApr ?? 99,
    },
  };

  const defaultValues: SetOfferFormInputs = {
    rate: asNumber({ value: getRateFromTakerMarket(takerMarket), decimals: 2 }),
    rateType: getRateTypeFromTakerMarket(takerMarket),
    expireOn: takerMarket.disableAfterMarketClearsDate ?? null,
  };

  const {
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    register,
    setError,
    reset,
    control,
    watch,
  } = useForm<SetOfferFormInputs>({
    defaultValues,
  });

  useEffect(() => {
    if (takerMarket && !open) {
      reset(defaultValues);
      setStep(initalStep);
      setIsAgreementsChecked(false);
      setIsAgreementsErrorNotChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerMarket, open]);

  const onSubmit: SubmitHandler<SetOfferFormInputs> = async (data) => {
    if (!canEditOffers || !takerMarket) {
      return;
    }

    const { rate: localeRate, rateType, expireOn } = data;

    const rate = localeRateToDomain(localeRate);

    if (step === 1) {
      // step 1 - validate rate/rate type are set & send to step 2
      // validate the rate based on rate type and offer limits and send to step 2
      const validRate = validateRate({ rate, rateType, offerLimits });

      const advanceStep = () => {
        setStep(2);
        trackEnterpriseEvent('offer-next::clicked');
      };

      return !validRate
        ? setError('rate', {
            message:
              rateType === 'disc'
                ? t('offerSubmitDialog.errors.discRateBetween', {
                    low: asPercent({
                      value: offerLimits.disc.low / 100,
                      decimals: 2,
                    }),
                    high: asPercent({ value: offerLimits.disc.high / 100, decimals: 2 }),
                  })
                : t('offerSubmitDialog.errors.aprRateBetween', {
                    low: asPercent({
                      value: offerLimits.apr.low / 100,
                      decimals: 2,
                    }),
                    high: asPercent({ value: offerLimits.apr.high / 100, decimals: 2 }),
                  }),
          })
        : advanceStep();
    } else {
      // step 2 - submit the form

      try {
        await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
      } catch {
        setIsAgreementsErrorNotChecked(true);
        return;
      }

      // make sure rate is positive and rounded to 2 decimal places
      const normalizedRate = Math.abs(Math.round(parseFloat(rate!) * 100) / 100);
      const maxDiscount = rateType === 'disc' ? normalizedRate : takerMarket.offerConfig.maxDiscount;
      const maxApr = rateType === 'apr' ? normalizedRate : takerMarket.offerConfig.maxApr;

      const submitValues = {
        marketId: takerMarket.offerConfig.marketId,
        marketUuid: takerMarket.offerConfig.marketUuid,
        offerConfig: {
          uuid: takerMarket.offerConfig.uuid,
          isDiscountBidding: rateType === 'disc',
          maxDiscount,
          maxApr,
          exclusionSettings: takerMarket.offerConfig.exclusionSettings,
          expireOn,
        },
        supplierDivisionUuid: takerMarket.takerDivisionUuid,
        takerId: takerMarket.offerConfig.divisionId,
      };

      await submitPriceDiscoveryOffer(submitValues, {
        onSuccess: () => {
          showSnackbar({ message: t('offerSubmitDialog.offerSet') });
          trackEnterpriseEvent('offer::submitted', submitValues);
          onClose();
        },
        onError: () => showSnackbar({ message: t('offerSubmitDialog.offerSetError') }),
      });
    }
  };

  const trackRateChange = (rateType: SetOfferFormInputs['rateType']) =>
    trackEnterpriseEvent('offer-rate-type::changed', { rateType });

  const trackRateTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as SetOfferFormInputs['rateType'];
    trackRateChange(value);
  };

  const expireOnValue = watch('expireOn');

  const offerConfigItems = useMemo(() => {
    const items: OfferConfigDetailsItem[] = [
      {
        label: t('taker.dictionary.availableAR.label'),
        value: asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency),
      },
    ];

    if (step === 2) {
      items.push({
        label: t('taker.dictionary.offer.label'),
        value: `${asPercent({
          value: parseFloat(localeRateToDomain(getValues('rate'))!) / 100,
          decimals: 2,
        })} ${getValues('rateType').toUpperCase()}`,
      });

      if (expireOnValue) {
        items.push({
          label: t('taker.dictionary.expirationDate.label'),
          value: trimDateTimestamp(expireOnValue),
        });
      }
    }

    return items;
  }, [
    asCurrency,
    asPercent,
    expireOnValue,
    getValues,
    step,
    t,
    takerMarket.currency,
    takerMarket.eligibleInvoiceAmount,
  ]);

  const modalConfig: Partial<ModalProps> =
    step === 1
      ? {
          cancelText: t('core.cancel'),
          okButtonVariant: 'primary',
          okText: t('core.next'),
          onCancel: onClose,
          onOk: handleSubmit(onSubmit),
        }
      : {
          cancelText: showReviewStep ? t('core.cancel') : t('core.back'),
          okButtonVariant: 'cta',
          okText: t('core.submit'),
          onCancel: showReviewStep ? onClose : () => setStep(initalStep),
          onOk: handleSubmit(onSubmit),
        };

  return (
    <>
      <Modal
        fullWidthButtons
        loading={isLoading}
        onClose={onClose}
        open={open}
        subTitle={getTakerMarketName(takerMarket)}
        title={futureOffer ? t('core.setOfferLabelFuture') : t('core.setOfferLabel')}
        {...modalConfig}
      >
        <div className="space-y-6">
          {takerMarket.eligibleInvoiceAmount <= 0 && (
            <Alert
              variant="outlined"
              type="warning"
              full
              title={t('offerSubmitDialog.zeroApWarningTitle')}
              description={t('offerSubmitDialog.zeroApWarningDescription')}
            />
          )}
          <OfferConfigDetails items={offerConfigItems} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* offer form */}
            <div className={cn('space-y-6', { hidden: step === 2 })}>
              <div className="space-y-1">
                <Label htmlFor="rate">{t('core.offerRate')}</Label>
                <TextInput
                  id="rate"
                  endIcon={PercentIcon}
                  hasError={!!errors.rate}
                  placeholder={t('offer.enterRate')}
                  type="text"
                  {...register('rate')}
                />
                {errors.rate && errors.rate.message && <HelperText variant="error">{errors.rate.message}</HelperText>}
              </div>
              <div className="pb-2">
                <div className="font-medium">{t('core.rateType')}</div>
                <div className="mt-2 grid grid-cols-1">
                  <fieldset>
                    <legend className="sr-only">{t('core.rateType')}</legend>
                    <div className="space-y-3">
                      <Controller
                        name="rateType"
                        control={control}
                        render={({ field }) => (
                          <div className="relative flex items-start">
                            <div className="flex h-6 w-4 shrink-0 items-center">
                              <input
                                {...field}
                                id="disc"
                                aria-labelledby="disc-description"
                                type="radio"
                                checked={field.value === 'disc'}
                                value="disc"
                                className="h-4 w-4 cursor-pointer text-secondary-600 accent-secondary-500 focus:ring-secondary-600"
                                onChange={(e) => {
                                  field.onChange(e);
                                  trackRateTypeChange(e);
                                }}
                              />
                            </div>
                            <div className="ml-3">
                              <label id="disc-description" htmlFor="disc" className="cursor-pointer font-medium">
                                {t('core.discount')}
                              </label>
                              <p id={`${t('core.discount')}-description`} className="text-sm text-gray-800">
                                {t('offer.flatRateMessage')}
                              </p>
                            </div>
                          </div>
                        )}
                      />
                      {!takerMarket.isDiscountMarket && (
                        <Controller
                          name="rateType"
                          control={control}
                          render={({ field }) => (
                            <div className="relative flex items-start">
                              <div className="flex h-6 w-4 shrink-0 items-center">
                                <input
                                  {...field}
                                  id="apr"
                                  aria-labelledby="apr-description"
                                  type="radio"
                                  checked={field.value === 'apr'}
                                  value="apr"
                                  className="h-4 w-4 cursor-pointer text-secondary-600 accent-secondary-500 focus:ring-secondary-600"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    trackRateTypeChange(e);
                                  }}
                                />
                              </div>
                              <div className="ml-3">
                                <label id="apr-description" htmlFor="apr" className="cursor-pointer font-medium">
                                  {t('core.apr')}
                                </label>
                                <p id={`${t('core.apr')}-description`} className="text-sm text-gray-800">
                                  {t('offer.recurringMessage')}
                                </p>
                              </div>
                            </div>
                          )}
                        />
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
              {isSetExpirationDateEnabled && (
                <>
                  <div className="border-t border-t-secondary-300" />
                  <OfferDuration
                    expireOn={watch('expireOn') ?? null}
                    setExpireOn={(isoDateString) => {
                      setValue('expireOn', isoDateString);
                    }}
                  />
                </>
              )}
            </div>
            {/* understand info */}
            <div className={cn('space-y-4', { hidden: step === 1 })}>
              <OfferReviewDetails takerMarket={takerMarket} />
              {isAgreementsErrorNotChecked && (
                <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
              )}
              <Agreement
                takersMarkets={[takerMarket]}
                onChange={(v) => {
                  setIsAgreementsChecked(v);
                  setIsAgreementsErrorNotChecked(false);
                }}
              />
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

const NameYourRateSetOffer = ({
  futureOffer,
  onClose,
  open,
  takerMarket,
  showReviewStep,
}: {
  futureOffer?: boolean;
  onClose: () => void;
  open: boolean;
  takerMarket?: TakerMarket | null;
  showReviewStep?: boolean;
}) => {
  return takerMarket ? (
    <NameYourRateSetOfferComponent
      futureOffer={futureOffer}
      onClose={onClose}
      open={open}
      takerMarket={takerMarket}
      showReviewStep={showReviewStep}
    />
  ) : null;
};

export default NameYourRateSetOffer;
