import { useSuspenseQuery } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/usercast/api/c2fo/market/pay-date-announcements

export interface PayDateAnnouncementMarket {
  id: number;
  makerDivisionName: string;
}

export interface PayDateAnnouncement {
  isLastDay: boolean;
  markets: PayDateAnnouncementMarket[];
  payDate: string;
}

const fetchPayDateAnnouncements = async (): Promise<PayDateAnnouncement[]> => {
  const data = await apiClient
    .get('usercast/api/c2fo/market/pay-date-announcements')
    .json<{ data: PayDateAnnouncement[] }>();

  return data?.data || [];
};

const usePayDateAnnouncements = <TData = PayDateAnnouncement[]>(select?: (data: PayDateAnnouncement[]) => TData) => {
  return useSuspenseQuery({
    queryKey: ['pay-date-announcements'],
    queryFn: () => fetchPayDateAnnouncements(),
    select,
  });
};

export default usePayDateAnnouncements;
