import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import cloudDownload from '@/enterprise/assets/clouddownload.svg?url';
import download from '@/enterprise/assets/download.svg?url';
import downloadError from '@/enterprise/assets/downloaderror.svg?url';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';
import { getCurrentAuthUser, useToken } from '@/utils/token';
import { sleep } from './utils';

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

// request award file: usercast + '/api/c2fo/taker/consolidated-history/award/archive'
// award file status: usercast + `/api/c2fo/taker/consolidated-history/award/:authServiceUuid/archive/status`
// get award file: usercast + `/api/c2fo/taker/consolidated-history/award/archive?startDate&endDate&makerIds`

interface AwardFileStatus {
  isComplete: boolean;
  isError: boolean;
}

function generateSearchParams({
  startDate,
  endDate,
  makerIds,
  token,
}: {
  startDate: string;
  endDate: string;
  makerIds?: string[];
  token?: string | null;
}) {
  const searchParams = new URLSearchParams();
  searchParams.set('startDate', startDate);
  searchParams.set('endDate', endDate);
  makerIds?.forEach((makerId) => searchParams.append('makerIds', makerId));
  token && searchParams.set('token', token);
  return searchParams;
}

async function checkAwardFileStatus(startDate: string, endDate: string, makerIds?: string[]) {
  const currentAuthUser = getCurrentAuthUser();
  const searchParams = generateSearchParams({ startDate, endDate, makerIds });

  const { isComplete, isError } = await apiClient
    .get(`usercast/api/c2fo/taker/consolidated-history/award/${currentAuthUser?.uuid ?? ''}/archive/status`, {
      searchParams,
    })
    .json<{
      data: AwardFileStatus | AwardFileStatus[];
    }>()
    .then((response) => {
      if (Array.isArray(response?.data)) {
        return {
          isComplete: response?.data.every((r) => r.isComplete),
          isError: response?.data.some((r) => r.isError),
        };
      }

      return (
        response.data ?? {
          isComplete: true,
          isError: false,
        }
      );
    });

  return {
    isComplete,
    isError,
  };
}

export function useRequestAwardFile() {
  return useMutation({
    mutationKey: ['requestAwardFile'],
    mutationFn: async ({
      startDate,
      endDate,
      makerIds,
    }: {
      startDate: string;
      endDate: string;
      makerIds?: string[];
    }) => {
      const check = await checkAwardFileStatus(startDate, endDate, makerIds);

      if (check.isComplete) {
        return check;
      }

      await apiClient.post(`usercast/api/c2fo/taker/consolidated-history/award/archive`, {
        json: {
          startDate,
          endDate,
          makerIds,
        },
      });

      // do 3 checks of the award file status.
      for (let i = 0; i < 3; i++) {
        const check = await checkAwardFileStatus(startDate, endDate, makerIds);
        if (check.isError) {
          throw new Error('We could not process your request.');
        }

        if (check.isComplete) {
          return check;
        }

        // wait 2.5 seconds before trying again
        await sleep(2500);
      }

      return {
        isComplete: false,
        isError: false,
      };
    },
  });
}

export interface AwardArchiveDownloadProps {
  startDate: string;
  endDate: string;
  makerIds?: string[];
  disabled: boolean;
}

export default function AwardArchiveDownload({ disabled, startDate, endDate, makerIds }: AwardArchiveDownloadProps) {
  const { data: { isComplete } = {}, mutate, isPending, isError, isSuccess } = useRequestAwardFile();
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const { token, tokenContent } = useToken();
  const email = tokenContent?.payload.user.emailAddress;
  const [downloadUrl, setDownloadUrl] = useState('');

  // only update the modal download url when the user requests to download from the page so both urls are in sync
  const updateDownloadUrl = () => {
    const url = new URL(`${prefixUrl}/usercast/api/c2fo/taker/consolidated-history/award/archive`);
    const searchParams = generateSearchParams({
      startDate,
      endDate,
      makerIds,
      token,
    });
    url.search = searchParams.toString();
    setDownloadUrl(url.toString());
  };

  useEffect(() => {
    if (!isPending && (isSuccess || isError)) {
      setModalOpen(true);
    }
  }, [isSuccess, isError, isPending, setModalOpen]);

  const { modalTitle, modalDescription, modalCancelButtonText, showModalDownloadButton, modalImage } = useMemo(() => {
    let modalTitle = t('maker.requestArchivePendingHeader');
    let modalDescription = t('maker.requestArchivePendingText', { email });
    let modalCancelButtonText = t('core.close');
    let showModalDownloadButton = false;
    let modalImage = cloudDownload;

    if (isError) {
      modalTitle = t('taker.consolidatedHistory.dialogErrorTitle');
      modalDescription = t('taker.consolidatedHistory.dialogErrorBody');
      modalImage = downloadError;
    } else if (isSuccess && isComplete) {
      modalTitle = t('core.authenticatedDownload.title');
      modalDescription = t('core.authenticatedDownload.description');
      modalCancelButtonText = t('core.cancel');
      modalImage = download;
      showModalDownloadButton = true;
    }

    return {
      modalTitle,
      modalDescription,
      modalCancelButtonText,
      showModalDownloadButton,
      modalImage,
    };
  }, [t, email, isComplete, isSuccess, isError]);

  return (
    <div>
      <Button
        onClick={() => {
          mutate({
            startDate,
            endDate,
            makerIds,
          });
          trackEnterpriseEvent('history-award-download::clicked');
          updateDownloadUrl();
        }}
        loading={isPending}
        size="sm"
        variant="primary"
        disabled={disabled}
      >
        {t('core.downloadCSV')}
      </Button>
      <Modal onClose={() => setModalOpen(false)} open={modalOpen} size="sm">
        <ModalTitleInfo>{modalTitle}</ModalTitleInfo>
        <ModalContent center>
          <img src={modalImage} alt="download" className="h-16 w-16" />
          <div>{modalDescription}</div>
        </ModalContent>
        <ModalActions>
          {showModalDownloadButton && (
            <a href={downloadUrl} onClick={() => setModalOpen(false)} target="_self">
              <Button variant="primary">{t('core.download')}</Button>
            </a>
          )}
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            {modalCancelButtonText}
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
}
