import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import { TechnicalDifficultyIcon } from '@c2fo/liquidity/icons';
import invoices from '@/enterprise/assets/invoicesgrey.svg?url';
import { useInvoiceLayoutOutletContext } from '@/enterprise/components/InvoiceLayout';
import NoDataState from '@/enterprise/components/NoDataState';
import { TableSortDirection } from '@/enterprise/components/Table';
import { AwardedInvoiceFetchOptions, AwardedInvoiceSort } from '@/enterprise/data/useAwardedInvoices';
import AwardedInvoiceTable from '@/enterprise/features/awardedInvoiceTable/AwardedInvoiceTable';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useUrlState from '@/utils/useUrlState';

export type AwardedInvoiceSortKey = Exclude<AwardedInvoiceSort, `-${string}`>;

export interface AwardedInvoiceTableSort {
  key: AwardedInvoiceSortKey;
  direction: TableSortDirection;
}

const AwardedInvoices = () => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const [urlState, setUrlState] = useUrlState<AwardedInvoiceFetchOptions>({
    page: { page: 1, limit: 100 },
    sort: '-newPayDate',
  });
  const { takerMarket } = useInvoiceLayoutOutletContext();

  const sort: AwardedInvoiceTableSort = {
    key: (urlState.sort?.replace(/^-/, '') as AwardedInvoiceSortKey) ?? 'newPayDate',
    direction: urlState.sort?.includes('-') ? 'desc' : 'asc',
  };

  const handleSort = (key: AwardedInvoiceSortKey) => {
    setUrlState((f) => ({
      ...f,
      sort: f.sort === `-${key}` ? key : `-${key}`,
      page: {
        ...f.page!,
        page: 1,
      },
    }));

    trackEnterpriseEvent('invoice-sort::clicked', { key });
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = Number(e.target.value);

    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, limit, page: 1 },
    }));

    trackEnterpriseEvent('invoice-page-size::clicked', { size: limit });
  };

  const handleNextChange = () => {
    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, page: f.page!.page + 1 },
    }));

    trackEnterpriseEvent('invoice-page::clicked');
  };

  const handlePrevChange = () => {
    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, page: f.page!.page - 1 },
    }));

    trackEnterpriseEvent('invoice-page::clicked');
  };

  return (
    <div className="flex gap-4 border-t">
      <div className="w-full overflow-auto">
        <QueryBoundaries
          ErrorComponent={() => (
            <NoDataState
              className="my-10"
              icon={<TechnicalDifficultyIcon className="h-24 w-24" />}
              title={t('invoices.errorLoadingInvoices')}
              message={t('invoices.errorLoadingInvoicesDescription')}
              action={
                <Button size="sm" onClick={() => window.location.reload()}>
                  {t('somethingsWrong.cta1Heading')}
                </Button>
              }
            />
          )}
          LoadingComponent={() => (
            <NoDataState
              className="my-10"
              icon={<img src={invoices} alt="invoices" className="h-28 w-28" />}
              message={t('invoices.fetchingAwardedInvoices')}
            />
          )}
        >
          <AwardedInvoiceTable
            handleSort={handleSort}
            options={urlState}
            pagination={{
              currentPage: urlState.page?.page ?? 1,
              onNextChange: handleNextChange,
              onPageSizeChange: (e) => handlePageSizeChange(e),
              onPrevChange: handlePrevChange,
              pageSize: urlState.page?.limit ?? 100,
            }}
            sort={sort}
            takerMarket={takerMarket}
          />
        </QueryBoundaries>
      </div>
    </div>
  );
};

export default AwardedInvoices;
