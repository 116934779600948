import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { PriceDiscoveryOfferInput } from '@/generated/gql/graphql';
import { paths } from '@/generated/supplier-experience-api';
import useTakerMarkets, { OfferConfig, TakerMarket } from '@/enterprise/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import useFeature from '@/lib/features';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useDataDog from '@/reporting/DataDog';

export const SUBMIT_PRICE_DISCOVERY_OFFER = graphql(`
  mutation SubmitPriceDiscoveryOffer($offer: PriceDiscoveryOfferInput!) {
    submitPriceDiscoveryOffer(offer: $offer)
  }
`);

type RelevantOfferConfig = Pick<
  OfferConfig,
  'uuid' | 'isDiscountBidding' | 'maxDiscount' | 'maxApr' | 'exclusionSettings'
> & { expireOn?: string | null };

const useSubmitPriceDiscoveryOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { addError } = useDataDog();
  const [enableSeaReads] = useFeature('enterprise-ui_enableSeaReads');
  const [enableSeaWrites] = useFeature('enterprise-ui_enableSeaWrites');
  const enableSeaApi = enableSeaReads && enableSeaWrites;

  return useMutation({
    mutationKey: ['submitPriceDiscoveryOffer'],
    mutationFn: async ({
      marketUuid,
      offerConfig,
      supplierDivisionUuid,
    }: {
      marketUuid: string;
      offerConfig: RelevantOfferConfig;
      supplierDivisionUuid: string;
    }) => {
      if (enableSeaApi) {
        const takerMarket = takerMarkets?.find(
          (meta) => meta.marketUuid === marketUuid && meta.takerDivisionUuid === supplierDivisionUuid
        );

        const rate = offerConfig.isDiscountBidding ? offerConfig.maxDiscount : offerConfig.maxApr;

        if (offerConfig && offerConfig.uuid) {
          return apiClient
            .patch('api/supplier-experience/offers/price-discovery', {
              json: {
                groupingKey: 'SUPPLIER_MARKET',
                status: 'active',
                offers: [
                  {
                    makerOrganizationUuid: takerMarket?.makerOrganizationUuid,
                    uuid: offerConfig.uuid,
                  },
                ],
                rateInformation: {
                  rateType: offerConfig.isDiscountBidding ? 'DISC' : 'APR',
                  rate: rate ?? 0,
                },
                expirationDate: offerConfig.expireOn,
              } satisfies paths['/offers/price-discovery']['patch']['requestBody']['content']['application/json'],
            })
            .json<paths['/offers/price-discovery']['patch']['responses']['204']['content']>();
        }

        return apiClient
          .post('api/supplier-experience/offers/price-discovery', {
            json: {
              groupingKey: 'SUPPLIER_MARKET',
              frequency: 'recurring',
              offerType: 'price-discovery',
              rateType: offerConfig.isDiscountBidding ? 'DISC' : 'APR',
              rate: rate ?? 0,
              expirationDate: offerConfig.expireOn ?? undefined,
              supplierMarkets: [
                {
                  marketUuid,
                  supplierDivisionUuid,
                },
              ],
            } satisfies paths['/offers/price-discovery']['post']['requestBody']['content']['application/json'],
          })
          .json<paths['/offers/price-discovery']['post']['responses']['201']['content']['application/json']>();
      }

      const offer: PriceDiscoveryOfferInput = {
        excludeNewInvoices: offerConfig.exclusionSettings.excludeNewInvoices,
        frequency: 'RECURRING',
        marketUuid,
        supplierDivisionUuid,
        expireOn: offerConfig.expireOn ? offerConfig.expireOn : undefined,
        ...(offerConfig.isDiscountBidding
          ? { maxDiscount: offerConfig.maxDiscount, rateType: 'DISC' }
          : { maxApr: offerConfig.maxApr, rateType: 'APR' }),
      };

      return gqlClient.request(SUBMIT_PRICE_DISCOVERY_OFFER, { offer });
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => ({
            ...(tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid
              ? {
                  ...tm,
                  offerConfig: {
                    ...tm.offerConfig,
                    ...variables.offerConfig,
                    isEnabled: true,
                  },
                  disableAfterMarketClearsDate: variables.offerConfig.expireOn ?? null,
                }
              : tm),
          }))
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }
    },
    onError: (error, variables) => {
      addError('useSubmitPriceDiscoveryOffer', {
        error,
        variables,
        seaOfferUuid: variables.offerConfig.uuid,
        featureFlags: {
          enableSeaReads,
          enableSeaWrites,
        },
      });
    },
  });
};

export default useSubmitPriceDiscoveryOffer;
