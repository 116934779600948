import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import useRefetchAndSubscribeForRules from '@/enterprise/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import { mapSeaRuleArgumentToRuleArray } from '@/enterprise/features/recurringRules/utils/utils';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';
import { formatSEAInvoiceRuleAsRecurringRule, RecurringRule } from './useRecurringRules';

export type SEAInvoiceRulesUpsertRequestBody =
  paths['/invoice-rules']['patch']['requestBody']['content']['application/json'];

export type SEAInvoiceRulesUpsertCriteriaBody = SEAInvoiceRulesUpsertRequestBody['criteria'];

export type SEAInvoiceRulesUpsertResponse =
  paths['/invoice-rules']['patch']['responses']['201']['content']['application/json'];

export interface RecurringRuleArgument {
  makerOrganizationUuid: string;
  takersMarkets: {
    marketId: number;
    marketUuid: string;
    takerId: number;
    takerUuid: string;
  }[];
  gteDpe?: number;
  lteDpe?: number;
  fromDueDate?: string;
  toDueDate?: string;
  gteInvoiceAmount?: number;
  lteInvoiceAmount?: number;
  excludedVoucherIds?: string[];
}

export interface SEARecurringRuleArgument {
  takersMarkets: {
    marketId: number;
    marketUuid: string;
    takerId: number;
    takerUuid: string;
    makerOrganizationUuid: string;
  }[];
  criteria: SEAInvoiceRulesUpsertCriteriaBody;
  existingRules: RecurringRule[];
}

const useCreateRecurringRule = () => {
  const { trackEnterpriseEvent } = useReporting();
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  const sendExistingRulesTrackEvents = (variables: SEARecurringRuleArgument) => {
    const { takersMarkets, existingRules, criteria } = variables;

    // Find rules from existingRules for each takerMarket
    const selectedTakerMarketsRules = takersMarkets
      .map((takerMarket) => {
        return existingRules.find(
          (rule) => rule.takerUuid === takerMarket.takerUuid && rule.marketUuid === takerMarket.marketUuid
        );
      })
      .filter((rule) => rule !== undefined);

    selectedTakerMarketsRules.forEach((rule) => {
      // for tracking, updating a rule with new criteria is considered a rule create
      const isUpdatedRule = Object.keys(rule ?? {})
        .filter((key) => ['amount', 'dpe', 'dueDate', 'excludedVoucherIds'].includes(key))
        .some((key) => Object.keys(formatSEAInvoiceRuleAsRecurringRule(criteria) ?? {}).includes(key));

      if (isUpdatedRule) {
        trackEnterpriseEvent('recurring-rules::updated', {
          makerOrganizationUuid: rule.makerOrganizationUuid,
          rules: mapSeaRuleArgumentToRuleArray(variables as SEARecurringRuleArgument),
          takerMarkets: [
            {
              marketId: rule.marketId,
              takerId: rule.takerId,
              marketUuid: rule.marketUuid,
              takerUuid: rule.takerUuid,
            },
          ],
        });
      } else {
        trackEnterpriseEvent('recurring-rules::created', {
          makerOrganizationUuid: rule.makerOrganizationUuid,
          rules: mapSeaRuleArgumentToRuleArray(variables as SEARecurringRuleArgument),
          takerMarkets: [
            {
              marketId: rule.marketId,
              takerId: rule.takerId,
              marketUuid: rule.marketUuid,
              takerUuid: rule.takerUuid,
            },
          ],
        });
      }
    });
  };

  const sendNewRulesTrackEvents = (variables: SEARecurringRuleArgument) => {
    const { takersMarkets, existingRules } = variables;

    // Find takerMarkets that do not have corresponding rules in existingRules
    const takerMarketsWithoutRules = takersMarkets.filter((takerMarket) => {
      return !existingRules.some(
        (rule) => rule.takerUuid === takerMarket.takerUuid && rule.marketUuid === takerMarket.marketUuid
      );
    });

    //group takerMarketsWithoutRules By makerOrganizationUuid to send those many created track events
    const takerMarketsWithoutRulesByMakerOrganizationUuid = takerMarketsWithoutRules.reduce(
      (acc, takerMarket) => {
        if (!acc[takerMarket.makerOrganizationUuid]) {
          acc[takerMarket.makerOrganizationUuid] = [];
        }

        acc[takerMarket.makerOrganizationUuid].push(takerMarket);
        return acc;
      },
      {} as Record<string, typeof takersMarkets>
    );

    Object.entries(takerMarketsWithoutRulesByMakerOrganizationUuid).forEach(([makerOrganizationUuid, takerMarkets]) => {
      trackEnterpriseEvent('recurring-rules::created', {
        makerOrganizationUuid,
        rules: mapSeaRuleArgumentToRuleArray(variables as SEARecurringRuleArgument),
        takerMarkets: takerMarkets.map((takerMarket) => ({
          marketId: takerMarket.marketId,
          takerId: takerMarket.takerId,
          marketUuid: takerMarket.marketUuid,
          takerUuid: takerMarket.takerUuid,
        })),
      });
    });
  };

  const sendDeletedRulesTrackEvents = async (
    variables: SEARecurringRuleArgument,
    upsertResponse: SEAInvoiceRulesUpsertResponse
  ) => {
    const { takersMarkets } = variables;

    const { data: groupedRulesResponse } = upsertResponse;

    const upsertedRules = groupedRulesResponse.flatMap((group) => group.rules);

    // find taker markets that dont have rules in response
    const deletedRules = takersMarkets.filter((takerMarket) => {
      return !upsertedRules.some(
        (rule) =>
          rule.supplierMarket.supplierDivisionUuid === takerMarket.takerUuid &&
          rule.supplierMarket.marketUuid === takerMarket.marketUuid
      );
    });

    // group deletedRules By makerOrganizationUuid to send those many deleted track events
    const deletedRulesByMakerOrganizationUuid = deletedRules.reduce(
      (acc, takerMarket) => {
        if (!acc[takerMarket.makerOrganizationUuid]) {
          acc[takerMarket.makerOrganizationUuid] = [];
        }

        acc[takerMarket.makerOrganizationUuid].push(takerMarket);
        return acc;
      },
      {} as Record<string, typeof takersMarkets>
    );

    Object.entries(deletedRulesByMakerOrganizationUuid).forEach(([makerOrganizationUuid, takerMarkets]) => {
      trackEnterpriseEvent('recurring-rules::deleted', {
        makerOrganizationUuid,
        takerMarkets: takerMarkets.map((takerMarket) => ({
          marketId: takerMarket.marketId,
          takerId: takerMarket.takerId,
          marketUuid: takerMarket.marketUuid,
          takerUuid: takerMarket.takerUuid,
        })),
      });
    });
  };

  return useMutation({
    mutationKey: ['createRecurringRule'],
    mutationFn: (rule: RecurringRuleArgument | SEARecurringRuleArgument) => {
      const { takersMarkets, criteria } = rule as SEARecurringRuleArgument;

      return apiClient
        .put(`api/supplier-experience/invoice-rules`, {
          json: {
            groupingKey: 'SUPPLIER_MARKET',
            supplierMarkets: takersMarkets.map((takerMarket) => ({
              marketUuid: takerMarket.marketUuid,
              supplierDivisionUuid: takerMarket.takerUuid,
            })),
            criteria: criteria,
          } satisfies SEAInvoiceRulesUpsertRequestBody,
        })
        .json<SEAInvoiceRulesUpsertResponse>();
    },
    onSuccess: (data, variables) => {
      const seaData = data as SEAInvoiceRulesUpsertResponse;
      const seaVariables = variables as SEARecurringRuleArgument;

      const { takersMarkets } = seaVariables;

      // sends created/updated track events for existing rules
      sendExistingRulesTrackEvents(seaVariables);

      // sends created track events for new rules
      sendNewRulesTrackEvents(seaVariables);

      // sends deleted track events for rules that were deleted
      sendDeletedRulesTrackEvents(seaVariables, seaData);

      refetchAndSubscribeRules({ takerMarkets: takersMarkets });
    },
  });
};

export default useCreateRecurringRule;
