import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toggle, useSnackbar } from '@c2fo/liquidity';
import { useIsAgreementRequired } from '@/enterprise/data/useAgreements';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useToggleOffer, { ToggleOfferParams } from '@/enterprise/data/useToggleOffer';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';
import NameYourRateSetOffer from './SetOffer';

const NameYourRateParticipationToggle = ({
  disabled = false,
  takerMarket,
}: {
  disabled?: boolean;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions([takerMarket]);
  const { mutateAsync: toggleOffer, isPending: toggleOfferIsPending } = useToggleOffer();
  const isAgreementRequired = useIsAgreementRequired([takerMarket.marketUuid]);
  const [isReviewOfferModalOpen, setIsReviewOfferModalOpen] = useState(false);

  const isDisabledDueToClosedMarket = !takerMarket.offerConfig.isEnabled && takerMarket.marketIsEnabled === false;
  const toggleDisabled = disabled || !canEditOffers || toggleOfferIsPending || isDisabledDueToClosedMarket;

  const onClick = async () => {
    // When a market has custom terms, the user must always go through the submit flow and accept them.
    if (isAgreementRequired && !takerMarket.offerConfig.isEnabled) {
      return setIsReviewOfferModalOpen(true);
    }

    const submitValues = {
      marketUuid: takerMarket.offerConfig.marketUuid,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
      seaUuid: takerMarket.offerConfig.uuid ?? undefined,
      frequency: takerMarket.offerConfig.frequency,
    } satisfies ToggleOfferParams;

    return await toggleOffer(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('offerSubmitDialog.participationUpdated') });
        trackEnterpriseEvent('participation::submitted', {
          ...submitValues,
          offerConfig: {
            ...takerMarket.offerConfig,
            isEnabled: !takerMarket.offerConfig.isEnabled,
          },
        });
        setIsReviewOfferModalOpen(false);
      },
      onError: () => showSnackbar({ message: t('offerSubmitDialog.participationError') }),
    });
  };

  return (
    <>
      <NameYourRateSetOffer
        open={isReviewOfferModalOpen}
        onClose={() => setIsReviewOfferModalOpen(false)}
        takerMarket={takerMarket}
        showReviewStep
      />
      <Toggle
        accessibilityLabel={t('core.toggleParticipation')}
        checked={takerMarket.offerConfig.isEnabled ?? false}
        disabled={toggleDisabled}
        loading={toggleOfferIsPending}
        onCheckedChange={onClick}
        size="lg"
      />
    </>
  );
};

export default NameYourRateParticipationToggle;
