import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useRestrictions from '@/utils/useRestrictions';

/**
 * displays an alert when the user cannot exclude invoices for preferred offers
 * by default, invoices for preferred offers cannot be excluded but this
 * can be overridden by account config -> canEditInvoices settings
 * Will not show if the taker market has excluded invoices as the messages contradict
 */

const PreferredOfferEditInvoiceAlert = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { getRestrictions } = useRestrictions();
  const { canEditInvoices } = getRestrictions([takerMarket]);
  const {
    offerConfig: { isEnabled: offerIsEnabled, offerType },
    takerExcludedInvoiceAmount,
  } = takerMarket;

  return !canEditInvoices && offerIsEnabled && offerType === 'PREFERRED_TERM' && takerExcludedInvoiceAmount === 0 ? (
    <div className="px-8 pb-0 pt-3">
      <Alert description={t('taker.cannotExcludeInvoices')} full type="info" variant="outlined" />
    </div>
  ) : null;
};

export default PreferredOfferEditInvoiceAlert;
