import { useTranslation } from 'react-i18next';
import { Button, useSnackbar } from '@c2fo/liquidity';
import useResetPassword from '@/accountSettings/data/useResetPassword';

const ResetPassword = ({ emailAddress }: { emailAddress: string }) => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const { mutateAsync, isPending } = useResetPassword();

  const handleResetPassword = async () => {
    if (emailAddress) {
      await mutateAsync(
        { emailAddress },
        {
          onSuccess: () => {
            showSnackbar({
              message: t('accountSettings.passwordResetSuccess', { emailAddress }),
            });
          },
          onError: () => {
            showSnackbar({
              message: t('accountSettings.passwordResetError', { emailAddress }),
            });
          },
        }
      );
    } else {
      showSnackbar({
        message: t('accountSettings.passwordResetError', { emailAddress }),
      });
    }
  };

  return (
    <Button onClick={handleResetPassword} loading={isPending} variant="ancillary">
      {t('accountSettings.resetPassword')}
    </Button>
  );
};

export default ResetPassword;
