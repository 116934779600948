import { useSuspenseQuery } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/broadcast/api/c2fo/marketing/buyers?active=true

export interface Customer {
  uuid: string;
  active: boolean;
  buyerEmailDomain: string | null;
  buyerLogoPngUrl: string;
  buyerPortalUrl: string | null;
  featured: boolean;
  imageUrl: string;
  lockupPngUrl: string;
  makerRouteKey: string;
  name: string;
}

const fetchCustomers = async (): Promise<Customer[]> => {
  const data = await apiClient.get('broadcast/api/c2fo/marketing/buyers?active=true').json<{ data: Customer[] }>();
  return data?.data.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
};

const useCustomers = <TData = Customer[]>(select?: (data: Customer[]) => TData) => {
  return useSuspenseQuery({
    queryKey: ['customers'],
    queryFn: () => fetchCustomers(),
    select,
  });
};

export default useCustomers;
