import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { Button, Chip, ChipProps, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';
import { EligibleInvoiceFetchFilters } from '@/enterprise/data/useEligibleInvoices';
import { RecurringRule } from '@/enterprise/data/useRecurringRules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import RulePreview from '@/enterprise/features/recurringRules/components/RulePreview';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';

interface RecurringRulesCountChipProps {
  count: number;
  excludedAmount?: string;
  onClick?: () => void;
  size?: ChipProps['size'];
}

const navParams = qs.stringify({
  limit: 100,
  order: 'amount',
  orderDirection: 'desc',
  page: 1,
  filter: { included: [false] } satisfies EligibleInvoiceFetchFilters,
});

export const RecurringRulesCountChip = ({
  count,
  excludedAmount,
  onClick,
  size = 'sm',
}: RecurringRulesCountChipProps) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={
        <div className="text-left">
          <div>
            {count} {count === 1 ? t('recurringRules.recurringRule') : t('recurringRules.title')}
          </div>
          {excludedAmount && <div>{t('invoiceSettings.excludedAmount', { amount: excludedAmount })}</div>}
        </div>
      }
      size={size}
      type="info"
      variant="outlined"
      {...(onClick && { onClick })}
    />
  );
};

const RecurringRuleExclusionChip = ({ rule, takerMarket }: { rule: RecurringRule; takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const { trackEnterpriseEvent } = useReporting();
  const [openModal, setOpenModal] = useState(false);

  const {
    takerExcludedInvoiceAmount: excludedAmount,
    takerExcludedInvoiceCount: excludedCount,
    currency,
  } = takerMarket;

  const handleOpenModal = () => {
    setOpenModal(true);
    trackEnterpriseEvent('recurring-rules::exclusion-modal::opened', {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return rule ? (
    <>
      <Modal onClose={handleCloseModal} open={openModal} size="sm">
        <ModalTitleInverse>
          <span className="flex flex-col">
            <span>{t('recurringRules.title')}</span>
            <span className="text-base font-normal text-text-secondary">{getTakerMarketName(takerMarket)}</span>
          </span>
        </ModalTitleInverse>
        <ModalContent className="space-y-4">
          {excludedCount === 0 && (
            <div>
              {t('recurringRules.divisionExcludedInvoiceDescriptionZero', {
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          {excludedCount === 1 && (
            <div>
              {t('recurringRules.divisionExcludedInvoiceDescription', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          {excludedCount > 1 && (
            <div>
              {t('recurringRules.divisionExcludedInvoiceDescriptionPlural', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          <RulePreview rule={rule} />
        </ModalContent>
        <ModalActions>
          <Button onClick={handleCloseModal} variant="secondary">
            {t('core.close')}
          </Button>
          <Link
            onClick={() => trackEnterpriseEvent('recurring-rules::exclusion-modal::redirected')}
            to={asEnterpriseLink(
              `markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible?${navParams}`
            )}
          >
            <Button variant="cta">{t('core.viewExcludedInvoices')}</Button>
          </Link>
        </ModalActions>
      </Modal>
      <RecurringRulesCountChip
        count={rule.count}
        excludedAmount={asCurrency(excludedAmount, currency)}
        onClick={handleOpenModal}
        size="xs"
      />
    </>
  ) : null;
};

export default RecurringRuleExclusionChip;
