import { keepPreviousData, useQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com/
// api/c2fo/taker/3563184/market/0c20157f-ba29-4efd-b8e6-93baacff1793/invoices/adjustments
// ?filter=%7B%7D&page=%7B%22page%22:1,%22limit%22:100%7D&sort=amount

export interface InvoiceAdjustment {
  id: number;
  invoiceId: string;
  dueDate: string;
  amount: number;
  takerId: number;
  makerId: number;
  created: string;
  updated: string;
  voucherId: string;
  currency: string;
  companyId: string;
  adjInvoiceId: string | null;
  transactionType: number;
  isPaid: boolean;
  isPastDue: boolean;
  eslapId: string | null;
  daysPaidEarly: number;
  earn: number;
  minDiscount: number;
  blendedReturnAmount: number;
  isInCashPool: boolean;
  clearedDate: string | null;
  isPendingClear: boolean;
  takerExcluded: boolean;
  makerExcluded: boolean;
  makerExcludedForToday: boolean;
  takerExcludedForToday: boolean;
  discountedAmount: number;
  isAwarded: boolean;
  isCleared: boolean;
  marketId: number;
  makerMarketClearId: number | null;
  takerMarketClearId: number | null;
  eslap: {
    account: string;
    amount: string;
    businessUnit: string;
    companyId: string;
    currency: string;
    deptId: string;
    divisionId: string;
    invoiceId: string;
    marketType: string;
    operatingUnit: string;
    paymentDueDate: string;
    transactionDate: string;
    transactionType: string;
    voucherId: string;
  };
  adjustmentAmount: number | null;
  isReserved: boolean;
  vatAmount: number | null;
  isIneligibleEp: boolean;
  isIneligibleAr: boolean;
  paymentDueDate: string;
  filesetConfigurationId: number;
  cashPoolSettingsUuid: string;
  fundingPartnerUuid: string | null;
  makerMarketCashPoolClearId: string | null;
  status: string;
}

type InvoiceAdjustmentAmount = '-25000,' | '-50000,-25000' | '-75000,-50000' | ',-75000';
type InvoiceAdjustmentStatus = 'matched' | 'unmatched';
export type InvoiceAdjustmentSort =
  | 'adjInvoiceId'
  | '-adjInvoiceId'
  | 'amount'
  | '-amount'
  | 'dueDate'
  | '-dueDate'
  | 'status'
  | '-status'
  | 'voucherId'
  | '-voucherId';

export interface InvoiceAdjustmentFetchFilters {
  amount?: InvoiceAdjustmentAmount[];
  status?: InvoiceAdjustmentStatus[];
}

export interface InvoiceAdjustmentFetchOptions {
  page: { page: number; limit: number };
  sort: InvoiceAdjustmentSort;
  filter?: InvoiceAdjustmentFetchFilters;
}

export const buildInvoiceAdjustmentFetchFilter = (filterOptions: InvoiceAdjustmentFetchFilters) => {
  if (!filterOptions) {
    return;
  }

  const filter = {
    ...(filterOptions.status && { status__eq: filterOptions.status }),
    ...(filterOptions.amount && { amount__between: filterOptions.amount }),
  };

  return { filter };
};

const fetchInvoiceAdjustments = async ({
  marketId,
  takerId,
  options,
}: {
  marketId: string;
  takerId: number;
  options: Partial<InvoiceAdjustmentFetchOptions>;
}): Promise<InvoiceAdjustment[]> => {
  const params = qs.stringify({
    page: options.page ?? { page: 1, limit: 100 },
    sort: options.sort ?? 'amount',
    ...(options.filter && buildInvoiceAdjustmentFetchFilter(options.filter)),
  });

  const data = await apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/invoices/adjustments?${params}`)
    .json<InvoiceAdjustment[]>();

  return data || [];
};

const useInvoiceAdjustments = <TData = InvoiceAdjustment[]>(
  params: {
    marketId: string;
    takerId: number;
    options: Partial<InvoiceAdjustmentFetchOptions>;
  },
  select?: (data: InvoiceAdjustment[]) => TData
) => {
  const { marketId, takerId, options } = params;

  return useQuery({
    // This is intentionally not useSuspenseQuery, loading states are handled in consumers.
    enabled: !!marketId && !!takerId,
    placeholderData: keepPreviousData,
    queryKey: ['invoice-adjustments', marketId, takerId, options],
    queryFn: () =>
      fetchInvoiceAdjustments({
        marketId,
        takerId,
        options,
      }),
    select,
  });
};

export default useInvoiceAdjustments;
