import { cn } from '@c2fo/liquidity';

const Card = ({
  actionSlot,
  content,
  subtitle,
  title,
}: {
  content: React.ReactNode;
  actionSlot?: React.ReactNode;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
}) => {
  return (
    <div>
      {title && (
        <div className="mb-4">
          <div className="flex min-h-12 items-center justify-between">
            <h4 className="m-0 font-serif text-2xl font-normal">{title}</h4>
            {actionSlot && <div>{actionSlot}</div>}
          </div>
          {subtitle && <div className="-mt-1 mb-6 text-text-secondary">{subtitle}</div>}
        </div>
      )}
      <div>{content}</div>
    </div>
  );
};

const CardContent = ({
  children,
  className,
  editForm = false,
}: {
  children: React.ReactNode;
  className?: string;
  editForm?: boolean;
}) => {
  return (
    <div
      className={cn(
        'rounded-md bg-white p-6 shadow-md',
        { 'border border-stroke bg-transparent shadow-none': editForm },
        className
      )}
    >
      {children}
    </div>
  );
};

const CardItem = ({
  icon,
  label,
  value,
}: {
  icon: React.ElementType;
  label: React.ReactNode;
  value: React.ReactNode;
}) => {
  const Icon = icon;

  return (
    <div className="flex flex-col items-start justify-between gap-4 border-b py-6 first:pt-0 last:border-b-0 last:pb-0 md:flex-row md:items-center">
      <div className="flex shrink-0 items-center gap-2 text-sm font-medium text-text-secondary">
        <Icon className="h-6 w-6 fill-text-secondary" />
        <span>{label}</span>
      </div>
      <div className="text-right text-xl">{value}</div>
    </div>
  );
};

export default { Card, CardContent, CardItem };
