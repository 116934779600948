import { TableSortDirection } from '@/enterprise/components/Table';
import { TakerMarketWithRecurringRule } from '@/enterprise/data/useRecurringRules';

export type SortKey =
  | 'takerMarketName'
  | 'totalAr'
  | 'ruleCount'
  | 'eligibleInvoiceAmount'
  | 'eligibleInvoiceCount'
  | 'takerExcludedInvoiceAmount'
  | 'takerExcludedInvoiceCount';
export type Sort = { key: SortKey; direction: TableSortDirection } | null;

const sortRulesTable = (rules: TakerMarketWithRecurringRule[] = [], sort: Sort) => {
  // should default to eligibleInvoiceAmount desc
  if (!sort?.key || !sort?.direction) {
    return rules;
  }

  return rules.sort((a, b) => {
    const divisionNameA = a.takerMarket.marketType
      ? `${a.takerMarket.makerDivisionName}-${a.takerMarket.legacyMarketId}`
      : a.takerMarket.makerDivisionName;
    const divisionNameB = b.takerMarket.marketType
      ? `${b.takerMarket.makerDivisionName}-${b.takerMarket.legacyMarketId}`
      : b.takerMarket.makerDivisionName;
    const totalArA = a.takerMarket.eligibleInvoiceAmount + a.takerMarket.takerExcludedInvoiceAmount;
    const totalArB = b.takerMarket.eligibleInvoiceAmount + b.takerMarket.takerExcludedInvoiceAmount;

    if (sort.key === 'takerMarketName') {
      return sort.direction === 'desc'
        ? divisionNameB.localeCompare(divisionNameA)
        : divisionNameA.localeCompare(divisionNameB);
    }

    if (sort.key === 'totalAr') {
      return sort.direction === 'desc'
        ? totalArB - totalArA || divisionNameA.localeCompare(divisionNameB)
        : totalArA - totalArB || divisionNameA.localeCompare(divisionNameB);
    }

    if (sort.key === 'ruleCount') {
      return sort.direction === 'desc'
        ? b.rule.count - a.rule.count || divisionNameA.localeCompare(divisionNameB)
        : a.rule.count - b.rule.count || divisionNameA.localeCompare(divisionNameB);
    }

    if (sort.key === 'eligibleInvoiceAmount') {
      return sort.direction === 'desc'
        ? b.takerMarket.eligibleInvoiceAmount - a.takerMarket.eligibleInvoiceAmount ||
            divisionNameA.localeCompare(divisionNameB)
        : a.takerMarket.eligibleInvoiceAmount - b.takerMarket.eligibleInvoiceAmount ||
            divisionNameA.localeCompare(divisionNameB);
    }

    if (sort.key === 'eligibleInvoiceCount') {
      return sort.direction === 'desc'
        ? b.takerMarket.eligibleInvoiceCount - a.takerMarket.eligibleInvoiceCount ||
            divisionNameA.localeCompare(divisionNameB)
        : a.takerMarket.eligibleInvoiceCount - b.takerMarket.eligibleInvoiceCount ||
            divisionNameA.localeCompare(divisionNameB);
    }

    if (sort.key === 'takerExcludedInvoiceAmount') {
      return sort.direction === 'desc'
        ? b.takerMarket.takerExcludedInvoiceAmount - a.takerMarket.takerExcludedInvoiceAmount ||
            divisionNameA.localeCompare(divisionNameB)
        : a.takerMarket.takerExcludedInvoiceAmount - b.takerMarket.takerExcludedInvoiceAmount ||
            divisionNameA.localeCompare(divisionNameB);
    }

    if (sort.key === 'takerExcludedInvoiceCount') {
      return sort.direction === 'desc'
        ? b.takerMarket.takerExcludedInvoiceCount - a.takerMarket.takerExcludedInvoiceCount ||
            divisionNameA.localeCompare(divisionNameB)
        : a.takerMarket.takerExcludedInvoiceCount - b.takerMarket.takerExcludedInvoiceCount ||
            divisionNameA.localeCompare(divisionNameB);
    }

    return sort.direction === 'desc'
      ? totalArB - totalArA || divisionNameA.localeCompare(divisionNameB)
      : totalArA - totalArB || divisionNameA.localeCompare(divisionNameB);
  });
};

export default sortRulesTable;
