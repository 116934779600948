import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  cn,
  Label,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
  useSnackbar,
} from '@c2fo/liquidity';
import { useReporting } from '@/reporting';
import useExchangeRate from '@/shared/data/useExchangeRate';
import useBuyersExpansion from '../data/useBuyersExpansion';
import useCreateBuyersExpansion from '../data/useCreateBuyersExpansion';
import useUpdateBuyersExpansion from '../data/useUpdateBuyersExpansion';

interface RequestNewCustomerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  updateUuid?: string;
}

interface NewCustomerForm {
  companyName: string;
  companyWebsite: string;
  currency?: string;
  outstandingAr?: string;
  additionalInformation?: string;
  isContactReference?: boolean;
}

const defaultValues: NewCustomerForm = {
  companyName: '',
  companyWebsite: '',
  currency: 'USD',
  outstandingAr: '',
  additionalInformation: '',
  isContactReference: false,
};

export const RequestNewCustomerModal = ({ open, setOpen, onSuccess, updateUuid }: RequestNewCustomerModalProps) => {
  const { trackMultiExperienceEvent } = useReporting();
  const { t } = useTranslation();
  const { data: exchangeRates } = useExchangeRate('USD');
  const { mutateAsync: createBuyersExpansion, isPending: isLoadingCreate } = useCreateBuyersExpansion();
  const { mutateAsync: updateBuyersExpansion, isPending: isLoadingUpdate } = useUpdateBuyersExpansion();
  const { data: buyersExpansions = [] } = useBuyersExpansion();
  const showSnackbar = useSnackbar();

  const currencies = useMemo(() => Object.keys(exchangeRates ?? {}), [exchangeRates]);

  const { register, reset, handleSubmit, formState, setValue, watch } = useForm<NewCustomerForm>({
    defaultValues,
  });

  useEffect(() => {
    const existing = buyersExpansions.find((buyer) => buyer.uuid === updateUuid);

    if (existing) {
      reset({
        companyName: existing.buyerName,
        companyWebsite: existing.websiteUrl ?? '',
        currency: existing.currency ?? 'USD',
        outstandingAr: existing.approximateAmountReceivable?.toString() ?? '',
        additionalInformation: existing.additionalInformation ?? '',
        isContactReference: existing.isContactReference ?? false,
      });
    }
  }, [buyersExpansions, reset, updateUuid]);

  const onSubmit: SubmitHandler<NewCustomerForm> = async (data) => {
    try {
      const payload = {
        buyerName: data.companyName,
        websiteUrl: data.companyWebsite,
        currency: data.currency,
        additionalInformation: data.additionalInformation,
        approximateAmountReceivable: data.outstandingAr ? parseInt(data.outstandingAr, 10) : undefined,
        isContactReference: data.isContactReference ?? false,
      };

      if (updateUuid) {
        await updateBuyersExpansion({ uuid: updateUuid, data: payload });
      } else {
        await createBuyersExpansion([payload]);
      }

      trackMultiExperienceEvent('find-your-customers::request-new-customer::submit::success', payload);

      onSuccess();
      setOpen(false);
      reset(defaultValues);
    } catch (error) {
      showSnackbar({ message: t('core.error') });
      trackMultiExperienceEvent('find-your-customers::request-new-customer::submit::error');
    }
  };

  const onCancel = () => {
    setOpen(false);
    reset(defaultValues);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="sm">
      <ModalTitle>{t('findYourCustomers.requestNewCustomer.modal.title')}</ModalTitle>
      <ModalContent className="flex flex-col gap-8">
        <div className="flex flex-col gap-1">
          <h2 className="m-0 p-0 text-xl">{t('findYourCustomers.requestNewCustomer.modal.subtitle')}</h2>
          <p className="m-0 p-0 text-sm text-text-secondary">
            {t('findYourCustomers.requestNewCustomer.modal.description')}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Label size="sm" htmlFor="companyName">
              {t('findYourCustomers.form.customerName.title')} ({t('core.required')})
            </Label>
            <TextInput
              className="w-full"
              hasError={!!formState.errors.companyName}
              id="companyName"
              {...register('companyName', { required: true })}
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label size="sm" htmlFor="companyWebsite">
              {t('findYourCustomers.form.website.title')} ({t('core.required')})
            </Label>
            <TextInput
              className="w-full"
              hasError={!!formState.errors.companyWebsite}
              id="companyWebsite"
              {...register('companyWebsite', {
                required: true,
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/,
                  message: t('findYourCustomers.form.website.error'),
                },
              })}
            />
            {!!formState.errors.companyWebsite?.message && (
              <p className="text-xs text-red-500">{formState.errors.companyWebsite?.message}</p>
            )}
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-3 sm:col-span-1">
              <Label size="sm" id="currencyLabel">
                Currency
              </Label>
              <Select onValueChange={(value) => setValue('currency', value)} value={watch('currency')}>
                <SelectTrigger aria-labelledby="currencyLabel">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {currencies.map((option) => (
                    <SelectItem key={option} value={option}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="col-span-3 sm:col-span-2">
              <Label size="sm" htmlFor="outstandingAr">
                {t('findYourCustomers.form.ar.title')}
              </Label>
              <TextInput
                type="number"
                className="w-full"
                hasError={!!formState.errors.outstandingAr}
                id="outstandingAr"
                {...register('outstandingAr')}
              />
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <Label size="sm" htmlFor="additionalInformation">
              {t('findYourCustomers.form.additionalInformation.title')}
            </Label>
            <TextInput
              className="w-full"
              hasError={!!formState.errors.additionalInformation}
              id="additionalInformation"
              {...register('additionalInformation')}
            />
          </div>

          <div className="flex items-center gap-2">
            <Checkbox
              color="primary"
              id="referenceCheckbox"
              checked={watch('isContactReference')}
              onChange={(event) => setValue('isContactReference', event.target.checked)}
              label={t('findYourCustomers.form.reference.title')}
            />
          </div>
        </form>
      </ModalContent>
      <ModalActions className="sm:items-center">
        <Button variant="secondary" onClick={onCancel} className={cn('min-w-28')}>
          {t('core.cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          className="min-w-28"
          loading={isLoadingCreate || isLoadingUpdate}
          disabled={isLoadingCreate || isLoadingUpdate}
        >
          {t('core.submit')}
        </Button>
      </ModalActions>
    </Modal>
  );
};
