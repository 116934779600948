import { useSuspenseQuery } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// Request URL: https://fss-gateway.c2fo.com/usercast/api/c2fo/user/me/recent-awards

export interface Award {
  clearAmount: number;
  clearTime: string;
  currency: string;
  id: number;
  isTpf: boolean;
  makerDivisionName: string;
  makerOrganizationName: string;
  marketUuid: string;
  payDate: string;
  pending: boolean;
  takerDivisionName: string;
  takerId: number;
  takerOrganizationName: string;
}

export async function fetchRecentAwards() {
  const data = await apiClient.get('usercast/api/c2fo/user/me/recent-awards').json<{ data: Award[] }>();
  return (data.data ?? []).sort((a, b) => b.clearTime.localeCompare(a.clearTime)).slice(0, 14);
}

const useRecentAwards = <TData = Award[]>(select?: (data: Award[]) => TData) => {
  return useSuspenseQuery({
    queryKey: ['recent-awards'],
    queryFn: fetchRecentAwards,
    select,
  });
};

export default useRecentAwards;
