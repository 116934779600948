import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { FeatureFlagProtectedRoute } from '@/shared/components/FeatureFlagProtectedRouting';
import FullPageLoader from '@/shared/components/FullPageLoader';
import NavigateWithRedirect from '@/shared/components/NavigateWithRedirect';
import { ExpandYourNetwork } from './components/ExpandYourNetwork';
import { RequestNewCustomers } from './components/RequestNewCustomers';

const Root = lazy(() => import('./FindYourCustomer'));

export const findYourCustomersBasename = 'find-your-customers';

/**
 * This allows the page, and all children, to be lazy loaded only when needed.
 */
const findYourCustomersRouteConfig: RouteObject = {
  path: findYourCustomersBasename,
  element: (
    <FeatureFlagProtectedRoute featureFlag="enterprise-ui_enableSeaFindYourCustomer">
      <Suspense fallback={<FullPageLoader />}>
        <Root />
      </Suspense>
    </FeatureFlagProtectedRoute>
  ),
  children: [
    {
      index: true,
      element: <NavigateWithRedirect to="expand-your-network" />,
    },
    {
      path: 'expand-your-network',
      element: <ExpandYourNetwork />,
    },
    {
      path: 'request-new-customers',
      element: <RequestNewCustomers />,
    },
    { path: '*', element: <NavigateWithRedirect to="expand-your-network" /> },
  ],
};

export default findYourCustomersRouteConfig;
