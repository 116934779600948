import { useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

type Response = paths['/buyers']['get']['responses']['200']['content']['application/json'];
type ResponseData = Response['data'];
export type Buyer = Response['data'][0];

const fetchData = async () => {
  const response = await apiClient
    .get(`api/supplier-experience/buyers`, {
      searchParams: qs.stringify({
        isActive: 'true',
      } satisfies paths['/buyers']['get']['parameters']['query']),
    })
    .json<Response>();

  return response.data;
};

const useBuyers = <TData = ResponseData>(select?: (data: ResponseData) => TData) => {
  return useSuspenseQuery({
    queryKey: ['buyers'],
    queryFn: () => fetchData(),
    select,
  });
};

export default useBuyers;
