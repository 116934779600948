import { useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';
import { EligibleInvoiceFetchFilters, buildEligibleInvoiceFetchFilter } from './useEligibleInvoices';

// https://fss-gateway.c2fo.com/api/c2fo/taker/3563872/market/b578662a-6125-4bb7-88cd-87835199ca57/invoices/eligible/stats

export interface EligibleInvoiceStats {
  count: number | null;
  dpeWeightedSum: number | null;
  earliestEarlyPayDepositDate: string | null;
  earliestOriginalDepositDate: string | null;
  subTotal: number | null;
}

const fetchEligibleInvoiceStats = async ({
  filter,
  marketId,
  takerId,
}: {
  marketId: string;
  takerId: number;
  filter?: Partial<EligibleInvoiceFetchFilters>;
}): Promise<EligibleInvoiceStats> => {
  let url = `api/c2fo/taker/${takerId}/market/${marketId}/invoices/eligible/stats`;

  if (filter) {
    const params = qs.stringify(buildEligibleInvoiceFetchFilter(filter));
    url += `?${params}`;
  }

  return apiClient.get(url).json<EligibleInvoiceStats>();
};

export const useEligibleInvoiceStats = (params: { marketId: string; takerId: number }) => {
  const { marketId, takerId } = params;

  return useSuspenseQuery({
    queryKey: ['eligible-invoice-stats', marketId, takerId],
    queryFn: () =>
      !!marketId && !!takerId
        ? fetchEligibleInvoiceStats({
            marketId,
            takerId,
          })
        : null,
  });
};

export const useFilteredEligibleInvoiceStats = (params: {
  marketId: string;
  takerId: number;
  filter?: Partial<EligibleInvoiceFetchFilters>;
}) => {
  const { marketId, takerId, filter } = params;

  return useSuspenseQuery({
    queryKey: ['eligible-invoice-stats-filtered', marketId, takerId, filter],
    queryFn: () =>
      !!marketId && !!takerId && !!filter
        ? fetchEligibleInvoiceStats({
            filter,
            marketId,
            takerId,
          })
        : null,
  });
};
