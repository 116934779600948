import { useQuery, useQueryClient } from '@tanstack/react-query';

const useSelectedCurrency = () => {
  const queryClient = useQueryClient();
  const { data: selectedCurrency } = useQuery({
    // This is intentionally not useSuspenseQuery, loading states are handled in consumers.
    queryKey: ['selected-currency'],
    queryFn: () => queryClient.getQueryData<string>(['selected-currency']),
    initialData: 'USD', // default initial data to USD if user selected currency store is not defined
  });
  return selectedCurrency ?? 'USD';
};

export default useSelectedCurrency;
