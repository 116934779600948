import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertProps, Button } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { AngleRightIcon } from '@c2fo/liquidity/icons';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/enterprise/components/Table';
import useProductInfo, { CapFinClientStatus } from '@/enterprise/data/useProductInfo';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import getToken from '@/utils/token';
import useLocaleFormat from '@/utils/useLocaleFormat';

const CapFinTableComponent = () => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const { data } = useProductInfo();

  const capFinClients = data?.rf ?? [];
  const showTable = capFinClients.some((client) => client.status === 'READY_FOR_RF_LOANS_STATUS');

  const getAlertType = useMemo(
    () =>
      (status: CapFinClientStatus): AlertProps['type'] => {
        if (status === 'BANK_ACCOUNT_START') {
          return 'warning';
        }

        if (status === 'IS_TERM_SHEET_ACCEPTED') {
          return 'success';
        }

        if (status === 'FIRST_TIME_STATUS') {
          return 'default';
        }

        if (status === 'BANK_ACCOUNT_ACTION_REQUIRED') {
          return 'error';
        }

        if (['AWAITING_C2FO_STATUS', 'AWAITING_CLIENT_STATUS'].includes(status)) {
          return 'info';
        }

        return 'default';
      },
    []
  );

  return capFinClients && capFinClients.length ? (
    <>
      <h3 className="mb-6 mt-10 font-medium">{t('capFin.c2foCapitalFinance')}</h3>
      <div className="rounded-md bg-white shadow-md">
        {showTable ? (
          <div className="w-full overflow-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t('capFin.client')}</TableHead>
                  <TableHead className="text-right">{t('capFin.creditLimit')}</TableHead>
                  <TableHead className="text-right">{t('capFin.outstanding')}</TableHead>
                  <TableHead className="text-right">{t('capFin.arBalance')}</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {capFinClients
                  .filter((client) => client.status !== 'REJECTED') // don't want to show rejected
                  .map((client) => {
                    const isFactoring = client.productType === 'FACTORING';
                    const readyForLoan = client.status === 'READY_FOR_RF_LOANS_STATUS';

                    return (
                      <TableRow
                        key={client.divisionUuid}
                        className="cursor-pointer transition-all duration-200 hover:bg-info-50/[.3]"
                        onClick={() =>
                          window.open(`${client.actionButtonUrl}?token=${getToken()}`, '_blank', 'noreferrer')
                        }
                        role="link"
                      >
                        <TableCell>{client.name}</TableCell>
                        {readyForLoan && (
                          <>
                            <TableCell className="text-right">{asCurrency(client.creditLimit, 'USD')}</TableCell>
                            <TableCell className="text-right">
                              {isFactoring ? '-' : asCurrency(client.outstandingAmount, 'USD')}
                            </TableCell>
                            <TableCell className="text-right">
                              {isFactoring ? '-' : asCurrency(client.arBalance, 'USD')}
                            </TableCell>
                          </>
                        )}
                        {[
                          'AWAITING_C2FO_STATUS',
                          'AWAITING_CLIENT_STATUS',
                          'BANK_ACCOUNT_ACTION_REQUIRED',
                          'BANK_ACCOUNT_START',
                          'FIRST_TIME_STATUS',
                          'IS_TERM_SHEET_ACCEPTED',
                        ].includes(client.status) && (
                          <TableCell colSpan={3}>
                            <Alert
                              description={client.messageText}
                              full
                              icon={false}
                              title={<div dangerouslySetInnerHTML={{ __html: client.messageHeading }} />}
                              type={getAlertType(client.status)}
                              variant="outlined"
                            />
                          </TableCell>
                        )}
                        <TableCell className="w-8">
                          <AngleRightIcon fill={colors.gray[500]} className="text-xl" />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
        {!showTable && capFinClients.length ? (
          <div className="flex items-center justify-between px-8 py-4">
            <div className="text-lg">{capFinClients[0]?.messageText}</div>
            <div>
              <a href={`${capFinClients[0]?.actionButtonUrl}?token=${getToken()}`} target="_blank" rel="noreferrer">
                <Button variant="secondary" size="sm">
                  {capFinClients[0]?.actionButtonText}
                </Button>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};

const CapFinTable = () => (
  <QueryBoundaries>
    <CapFinTableComponent />
  </QueryBoundaries>
);

export default CapFinTable;
