import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import {
  BriefcaseIcon,
  CalendarDayIcon,
  EarthEuropeIcon,
  EnvelopeIcon,
  HashtagIcon,
  IdBadgeIcon,
  LanguageIcon,
  LocationDotIcon,
  MobileNotchIcon,
  PhoneIntercomIcon,
} from '@c2fo/liquidity/icons';
import CardForm from '@/accountSettings/components/CardForm';
import CompanyProfileForm from '@/accountSettings/components/CompanyProfileForm';
import Header from '@/accountSettings/components/Header';
import LocationForm from '@/accountSettings/components/LocationForm';
import ResetPassword from '@/accountSettings/components/ResetPassword';
import UserProfileForm from '@/accountSettings/components/UserProfileForm';
import { formatDisplay, formatLabelFromOptions } from '@/accountSettings/utils/formatters';
import useFormDropdownOptions from '@/accountSettings/utils/useFormDropdownOptions';
import useUserAccountValues from '@/accountSettings/utils/useUserAccountValues';

const AccountSettings = () => {
  const { t } = useTranslation();
  const user = useUserAccountValues();
  const dropdownOptions = useFormDropdownOptions();
  const [openEditForms, setOpenEditForms] = useState({
    companyProfile: false,
    location: false,
    userProfile: false,
  });

  const userNames = [user.firstName, user.lastName];

  const handleFormToggle = (form: keyof typeof openEditForms, action: 'open' | 'close') => {
    setOpenEditForms((state) => ({ ...state, [form]: action === 'open' }));
  };

  return (
    <div>
      <Header
        actionSlot={<ResetPassword emailAddress={user.email} />}
        subTitle={user.email}
        title={t('core.accountSettings')}
      />
      <div className="space-y-12">
        {/* user profile form */}
        <CardForm.Card
          title={t('accountSettings.userProfile')}
          {...(openEditForms.userProfile
            ? {
                content: (
                  <CardForm.CardContent editForm>
                    <UserProfileForm
                      defaultValues={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        mobilePhoneNumber: user.secondaryPhoneNumber,
                        officePhoneNumber: user.primaryPhoneNumber,
                        role: user.role,
                      }}
                      onClose={() => handleFormToggle('userProfile', 'close')}
                    />
                  </CardForm.CardContent>
                ),
              }
            : {
                actionSlot: (
                  <Button onClick={() => handleFormToggle('userProfile', 'open')} variant="ancillary">
                    {t('core.edit')}
                  </Button>
                ),
                content: (
                  <>
                    <div className="-mb-14 flex items-end gap-4 px-6">
                      <div className="flex h-20 w-20 items-center justify-center rounded-full bg-amber-500 text-2xl uppercase">
                        {userNames[0]?.charAt(0)}
                      </div>
                      <div className="text-2xl font-bold">{userNames.join(' ')}</div>
                    </div>
                    <CardForm.CardContent>
                      <div className="pt-16">
                        <CardForm.CardItem
                          icon={IdBadgeIcon}
                          label={t('accountSettings.role')}
                          value={formatLabelFromOptions(dropdownOptions.roleOptions, user.role)}
                        />
                        <CardForm.CardItem
                          icon={PhoneIntercomIcon}
                          label={t('accountSettings.officePhoneNumber')}
                          value={formatDisplay(user.primaryPhoneNumber)}
                        />
                        <CardForm.CardItem
                          icon={MobileNotchIcon}
                          label={t('accountSettings.mobilePhoneNumber')}
                          value={formatDisplay(user.secondaryPhoneNumber)}
                        />
                      </div>
                    </CardForm.CardContent>
                  </>
                ),
              })}
        />
        {/* location form */}
        <CardForm.Card
          title={t('accountSettings.location')}
          {...(openEditForms.location
            ? {
                content: (
                  <CardForm.CardContent editForm>
                    <LocationForm
                      defaultValues={{
                        country: user.country,
                        useDefaultFormatting: !user.useCustomFormatting,
                        dateDisplayFormat: user.dateDisplayFormat,
                        locale: user.locale,
                        numberDisplayFormat: user.numberDisplayFormat,
                        postalCode: user.postalCode,
                      }}
                      onClose={() => handleFormToggle('location', 'close')}
                    />
                  </CardForm.CardContent>
                ),
              }
            : {
                actionSlot: (
                  <Button onClick={() => handleFormToggle('location', 'open')} variant="ancillary">
                    {t('core.edit')}
                  </Button>
                ),
                content: (
                  <CardForm.CardContent>
                    <CardForm.CardItem
                      icon={EarthEuropeIcon}
                      label={t('accountSettings.countryArea')}
                      value={formatLabelFromOptions(dropdownOptions.countryOptions, user.country)}
                    />
                    <CardForm.CardItem
                      icon={LocationDotIcon}
                      label={t('accountSettings.postalCode')}
                      value={formatDisplay(user.postalCode)}
                    />
                    <CardForm.CardItem
                      icon={LanguageIcon}
                      label={t('accountSettings.languagePreference')}
                      value={formatLabelFromOptions(dropdownOptions.localeOptions, user.locale)}
                    />
                    <CardForm.CardItem
                      icon={HashtagIcon}
                      label={t('accountSettings.numberAndDateFormatting')}
                      value={user.useCustomFormatting ? t('core.custom') : t('core.default')}
                    />
                  </CardForm.CardContent>
                ),
              })}
        />
        {/* company profile form */}
        <CardForm.Card
          title={t('accountSettings.companyProfile')}
          {...(openEditForms.companyProfile
            ? {
                content: (
                  <CardForm.CardContent editForm>
                    <CompanyProfileForm
                      defaultValues={{
                        arDepartmentEmailAddress: user.arDepartmentEmailAddress,
                        fiscalYearEnd: user.fiscalYearEnd,
                        industry: user.industry,
                      }}
                      onClose={() => handleFormToggle('companyProfile', 'close')}
                    />
                  </CardForm.CardContent>
                ),
              }
            : {
                actionSlot: (
                  <Button onClick={() => handleFormToggle('companyProfile', 'open')} variant="ancillary">
                    {t('core.edit')}
                  </Button>
                ),
                content: (
                  <CardForm.CardContent>
                    <CardForm.CardItem
                      icon={BriefcaseIcon}
                      label={t('accountSettings.industry')}
                      value={formatLabelFromOptions(dropdownOptions.industryOptions, user.industry)}
                    />
                    <CardForm.CardItem
                      icon={CalendarDayIcon}
                      label={t('accountSettings.fiscalYearEnd')}
                      value={formatLabelFromOptions(dropdownOptions.monthOptions, user.fiscalYearEnd)}
                    />
                    <CardForm.CardItem
                      icon={EnvelopeIcon}
                      label={t('accountSettings.arDepartmentEmailAddress')}
                      value={formatDisplay(user.arDepartmentEmailAddress)}
                    />
                  </CardForm.CardContent>
                ),
              })}
        />
      </div>
    </div>
  );
};

export default AccountSettings;
