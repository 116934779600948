import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Alert, Button, Label, TextInput, useSnackbar } from '@c2fo/liquidity';
import useCreateRequestedCustomerDivisionLink, {
  VendorIds,
} from '@/enterprise/data/useCreateRequestedCustomerDivisionLink';
import useCustomers, { Customer } from '@/enterprise/data/useCustomers';
import { useReporting } from '@/reporting';
import CustomerCard from './components/CustomerCard';
import CustomerGrid from './components/CustomerGrid';
import RequestCustomerModal from './components/RequestCustomerModal';

interface CustomerWithVenderIds extends Customer {
  vendorIds: VendorIds[];
}

const MarketSearchGrid = () => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const { data: customers } = useCustomers();
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<'select' | 'submit'>('select');
  const [selectedCustomers, setSelectedCustomers] = useState<CustomerWithVenderIds[]>([]);
  const [requestCustomerModalOpen, setRequestCustomerModalOpen] = useState<boolean>(false);
  const { mutate: createRequestedCustomerDivisionLink } = useCreateRequestedCustomerDivisionLink();

  const featuredCustomers = customers?.filter((customer) => customer.featured);
  const nonFeaturedCustomers = customers?.filter((customer) => !customer.featured);

  useEffectOnce(() => {
    trackEnterpriseEvent('market-search::visited-market-search');
  });

  const onCustomerSelect = (customer: Customer) =>
    setSelectedCustomers((prevSelectedCustomers) => {
      const found = prevSelectedCustomers.find((selectedCustomer) => selectedCustomer.uuid === customer.uuid);

      if (found) {
        return prevSelectedCustomers.filter((selectedCustomer) => selectedCustomer.uuid !== customer.uuid);
      } else {
        return [...prevSelectedCustomers, { ...customer, vendorIds: [{ value: '' }] }];
      }
    });

  const updateVendorId = (updatedCustomer: CustomerWithVenderIds, updatedValue: string, updatedVendorIdx: number) => {
    setSelectedCustomers((prevSelectedCustomers) =>
      prevSelectedCustomers.map((customer) => ({
        ...(customer.uuid === updatedCustomer.uuid
          ? {
              ...customer,
              vendorIds: [
                ...(customer.vendorIds ?? []).map((vendorId, idx) => {
                  if (idx === updatedVendorIdx) {
                    return { value: updatedValue };
                  }

                  return vendorId;
                }),
              ],
            }
          : customer),
      }))
    );
  };

  const addVendorId = (updatedCustomer: CustomerWithVenderIds) => {
    setSelectedCustomers((prevSelectedCustomers) =>
      prevSelectedCustomers.map((customer) => ({
        ...(customer.uuid === updatedCustomer.uuid
          ? { ...customer, vendorIds: [...(customer.vendorIds ?? []), { value: '' }] }
          : customer),
      }))
    );
  };

  const onSubmit = () => {
    setLoading(true);

    // remove any empty vendor ids
    const customerSubmitValues = selectedCustomers.map((customer) => ({
      customerName: customer.name,
      vendorIds: customer.vendorIds?.filter(({ value }) => value) ?? [],
    }));

    const trackEventCustomerMeta = selectedCustomers.map(({ uuid, featured, name }) => ({
      uuid,
      featured,
      name,
    }));

    customerSubmitValues.forEach((customer) => {
      createRequestedCustomerDivisionLink(customer, {
        onSuccess: () => {
          setLoading(false);
          showSnackbar({ message: t('taker.marketSearch.customerRequestDialog.toastSuccess') });
          setStep('select');
          setSelectedCustomers([]);

          trackEnterpriseEvent('market-search::submitted-division-link-request', {
            buyers: trackEventCustomerMeta,
            numRequested: customerSubmitValues.length,
          });
        },
        onError: () => {
          setLoading(false);
          showSnackbar({ message: t('taker.marketSearch.customerRequestDialog.toastFailure') });
        },
      });
    });
  };

  const openRequestCustomerModal = () => {
    setRequestCustomerModalOpen(true);
    trackEnterpriseEvent('market-search::opened-customers-request');
  };

  return (
    <>
      <RequestCustomerModal open={requestCustomerModalOpen} onClose={() => setRequestCustomerModalOpen(false)} />
      <div className="mb-20 sm:mb-0">
        {step === 'select' ? (
          <>
            <Alert
              action={{
                onClick: () => openRequestCustomerModal(),
                text: t('taker.marketSearch.customerRequestDialog.requestCustomers'),
              }}
              full
              title={t('taker.marketSearch.marketDivisionLinkText')}
              type="info"
            />
            <h3 className="my-8 font-serif font-normal">{t('divisionRecommendation.featuredPartners')}</h3>
            <CustomerGrid>
              {featuredCustomers?.map((customer) => (
                <CustomerCard
                  key={customer.uuid}
                  customer={customer}
                  onClick={() => onCustomerSelect(customer)}
                  selected={!!selectedCustomers.find(({ uuid }) => uuid === customer.uuid)}
                />
              ))}
            </CustomerGrid>
            <div className="my-10 h-[2px] w-full bg-gray-200" />
            <CustomerGrid>
              {nonFeaturedCustomers?.map((customer) => (
                <CustomerCard
                  key={customer.uuid}
                  customer={customer}
                  onClick={() => onCustomerSelect(customer)}
                  selected={!!selectedCustomers.find(({ uuid }) => uuid === customer.uuid)}
                />
              ))}
            </CustomerGrid>
          </>
        ) : (
          <>
            <div className="text-xl font-medium">{t('taker.marketSearch.titleEnterVendorId')}</div>
            <div>{t('taker.marketSearch.verifyInfo.continueLabel')}</div>
            <div className="mt-10 space-y-6">
              {selectedCustomers.map((customer) => (
                <div key={customer.uuid} className="rounded-md bg-white p-6 shadow-md">
                  <div className="mb-2 text-lg font-medium">{customer.name}</div>
                  <div className="flex flex-wrap items-center gap-4">
                    {customer.vendorIds?.map((vendorId, idx) => (
                      <div key={idx} className="flex w-full flex-col space-y-1 sm:w-52">
                        <Label htmlFor={`${vendorId.value}-${idx}`}>{t('maker.vendorId')}</Label>
                        <TextInput
                          id={`${vendorId.value}-${idx}`}
                          onChange={(e) => {
                            updateVendorId(customer, e.target.value, idx);
                          }}
                          type="text"
                          value={vendorId.value}
                        />
                      </div>
                    ))}
                    <Button className="sm:mt-8" onClick={() => addVendorId(customer)} size="sm" variant="secondary">
                      {t('taker.marketSearch.verifyInfo.addAnother')}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {selectedCustomers.length > 0 && (
          <div className="fixed inset-x-0 bottom-0 z-10 border-t bg-white">
            <div className="m-auto flex max-w-screen-2xl flex-col gap-4 p-4 sm:flex-row sm:items-center sm:justify-between sm:gap-0 lg:px-10">
              <div className="text-right">
                {t('taker.marketSearch.customerSelect.numSelected', { numSelected: selectedCustomers.length })}
              </div>
              <div className="h-[1px] bg-gray-200" />
              <div className="flex flex-row-reverse items-center justify-start gap-2">
                <Button
                  loading={loading}
                  onClick={step === 'submit' ? () => onSubmit() : () => setStep('submit')}
                  variant="primary"
                >
                  {step === 'submit' ? t('core.submit') : t('core.continue')}
                </Button>
                <Button
                  disabled={loading}
                  onClick={step === 'submit' ? () => setStep('select') : () => setSelectedCustomers([])}
                  variant="ancillary"
                >
                  {step === 'submit' ? t('core.back') : t('core.cancel')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MarketSearchGrid;
