import { asEnterpriseLink } from '@/utils/experienceLinkHelper';

const tabLinks = {
  NAME_YOUR_RATE: asEnterpriseLink('markets'),
  BENCHMARK: asEnterpriseLink('markets/variable'),
  PREFERRED: asEnterpriseLink('markets/preferred'),
  FIXED: asEnterpriseLink('markets/fixed'),
  INACTIVE: asEnterpriseLink('markets/inactive'),
};

export default tabLinks;
