import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { PersonalInfoInput, UserInput } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';
import { useToken } from '@/utils/token';

interface UpdateUserProfileArgs {
  firstName: PersonalInfoInput['firstName'];
  lastName: PersonalInfoInput['lastName'];
  primaryPhoneNumber: PersonalInfoInput['primaryPhoneNumber'];
  role: PersonalInfoInput['role'];
  secondaryPhoneNumber: PersonalInfoInput['secondaryPhoneNumber'];
}

const UPDATE_USER_PROFILE = graphql(`
  mutation UpdateUserProfile($users: [UserInput!]) {
    users(users: $users) {
      success
    }
  }
`);

const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();
  const { tokenContent } = useToken();
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? '';

  return useMutation({
    mutationKey: ['updateUserProfile'],
    mutationFn: (args: UpdateUserProfileArgs) => {
      const input = {
        users: [
          {
            id: authServiceUuid,
            personalInfo: {
              firstName: args.firstName,
              lastName: args.lastName,
              role: args.role,
              primaryPhoneNumber: args.primaryPhoneNumber,
              secondaryPhoneNumber: args.secondaryPhoneNumber,
            } satisfies PersonalInfoInput,
          } satisfies UserInput,
        ],
      };

      return gqlClient.request(UPDATE_USER_PROFILE, input);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['user-details'],
      });
    },
  });
};

export default useUpdateUserProfile;
