import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { FormatPreferencesInput, PersonalInfoInput, UserInput } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';
import { useToken } from '@/utils/token';

interface UpdateLocationArgs {
  address: PersonalInfoInput['address'];
  locale: PersonalInfoInput['locale'];
  formatPreferences: {
    dateDisplayFormat: FormatPreferencesInput['dateDisplayFormat'];
    numberDisplayFormat: FormatPreferencesInput['numberDisplayFormat'];
    useCustomFormatting: FormatPreferencesInput['useCustomFormatting'];
  };
}

const UPDATE_LOCATION = graphql(`
  mutation UpdateLocation($users: [UserInput!]) {
    users(users: $users) {
      success
    }
  }
`);

const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  const { tokenContent } = useToken();
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? '';

  return useMutation({
    mutationKey: ['updateLocation'],
    mutationFn: (args: UpdateLocationArgs) => {
      const input = {
        users: [
          {
            id: authServiceUuid,
            formatPreferences: {
              dateDisplayFormat: args.formatPreferences.dateDisplayFormat,
              numberDisplayFormat: args.formatPreferences.numberDisplayFormat,
              useCustomFormatting: args.formatPreferences.useCustomFormatting,
            } satisfies FormatPreferencesInput,
            personalInfo: {
              address: {
                ...args.address,
              },
              locale: args.locale,
            } satisfies PersonalInfoInput,
          } satisfies UserInput,
        ],
      };

      return gqlClient.request(UPDATE_LOCATION, input);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['user-details'],
      });
    },
  });
};

export default useUpdateLocation;
