import { useCallback } from 'react';
import { GetMeQuery } from '@/generated/gql/graphql';
import useUserDetails from '@/shared/data/useUserDetails';

const useUserHasIndiaDivisions = () => {
  const { data: userDivisons } = useUserDetails(useCallback((user: GetMeQuery) => user.me?.divisions, []));
  return (userDivisons ?? []).map((division) => division?.srm).some((srm) => srm?.team?.includes('IN-'));
};

export default useUserHasIndiaDivisions;
