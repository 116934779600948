import { useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import apiClient from '@/lib/apiClient';
import { buildInvoiceAdjustmentFetchFilter, InvoiceAdjustmentFetchOptions } from './useInvoiceAdjustments';

// https://fss-gateway.c2fo.com/
// api/c2fo/taker/3563147/market/61af100a-d391-4afe-832b-4d4a0e0bb8a1/invoices/adjustments/stats
// ?filter=%7B%7D

export interface InvoiceAdjustmentStats {
  count: number | null;
  subTotal: number | null;
}

const fetchInvoiceAdjustmentStats = async ({
  marketId,
  takerId,
  options,
}: {
  marketId: string;
  takerId: number;
  options: Partial<InvoiceAdjustmentFetchOptions>;
}): Promise<InvoiceAdjustmentStats> => {
  const params = qs.stringify({
    ...(options?.filter && buildInvoiceAdjustmentFetchFilter(options.filter)),
  });

  const getUrl = params
    ? `api/c2fo/taker/${takerId}/market/${marketId}/invoices/adjustments/stats?${params}`
    : `api/c2fo/taker/${takerId}/market/${marketId}/invoices/adjustments/stats`;

  return apiClient.get(getUrl).json<InvoiceAdjustmentStats>();
};

const useInvoiceAdjustmentStats = (params: {
  marketId: string;
  takerId: number;
  options: Partial<InvoiceAdjustmentFetchOptions>;
}) => {
  const { marketId, takerId, options } = params;

  return useSuspenseQuery({
    queryKey: ['invoice-adjustment-stats', marketId, takerId, options.filter],
    queryFn: () =>
      !!marketId && !!takerId
        ? fetchInvoiceAdjustmentStats({
            marketId,
            takerId,
            options,
          })
        : null,
  });
};

export default useInvoiceAdjustmentStats;
