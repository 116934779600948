import { useSuspenseQuery } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

export interface TakerMarketStat {
  acceptedAprWeightedAvg: number;
  acceptedDiscountWeightedAvg: number;
  acceptedDiscountedAmount: number;
  acceptedDpeWeightedAvg: number;
  acceptedDpeWeightedSum: number;
  acceptedEarn: number;
  acceptedInvoiceAmount: number;
  acceptedInvoiceCount: number;
  bidWasPlacedBeforeLastCalc?: boolean;
  creditAvailable?: boolean;
  currency: string;
  eligibleDpeWeightedAvg: number;
  eligibleDpeWeightedSum: number;
  eligibleInvoiceAmount: number;
  eligibleInvoiceCount: number;
  hasAcceptedFundingPartnerTerms: boolean;
  ineligibleEpInvoiceAmount: number;
  isAccountVerified?: boolean;
  isAgreementSigned?: boolean;
  isBankAccountComplete?: boolean;
  isDiscountDefaultBidType?: boolean;
  isDiscountMarket?: boolean;
  isFinancialUploaded?: boolean;
  isFinancialsComplete?: boolean;
  isFundingPartnerMarket?: boolean;
  isInfoComplete?: boolean;
  isParticipating?: boolean;
  isTpf?: boolean;
  isUnderwritingApproved?: boolean;
  isUnderwritingDisapproved?: boolean;
  lastMarketCalcStartTime?: string;
  legacyMarketId: number;
  makerDivisionName: string;
  makerExcludedForTodayInvoiceAmount: number;
  makerExcludedInvoiceAmount: number;
  makerId: number;
  makerOrganizationName: string;
  makerOrganizationUuid: string;
  marketId: string;
  marketIsEnabled: boolean;
  marketMaxApr: number;
  marketMaxDiscount: number;
  marketNextClearTime: string;
  marketTimezone: string;
  marketType: string;
  marketUuid: string;
  minimumLoanAmount?: number;
  notAcceptedDpeWeightedAvg: number;
  notAcceptedDpeWeightedSum: number;
  notAcceptedInvoiceAmount: number;
  notAcceptedInvoiceCount: number;
  payDate: string;
  takerConfigId: number;
  takerConfigIsDiscountBidding: boolean;
  takerConfigIsEnabled: boolean;
  takerConfigMaxApr?: number;
  takerConfigMaxDiscount?: number;
  takerDivisionName: string;
  takerExcludedForTodayInvoiceAmount: number;
  takerExcludedInvoiceAmount: number;
  takerId: number;
  takerMakerId: string;
  takerOrganizationName: string;
  takerUuid: string;
  totalOutstandingLoans?: number;
}

// https://fss-gateway.c2fo.com/
// api/c2fo/taker/3563184/market/9e6db030-3f8c-4daf-89da-2a99d3f0e7c3/stats

const fetchTakerMarketStats = async ({
  marketId,
  takerId,
}: {
  marketId: string;
  takerId: number;
}): Promise<TakerMarketStat | null> => {
  if (!takerId || !marketId) {
    return null;
  }

  return apiClient.get(`api/c2fo/taker/${takerId}/market/${marketId}/stats`).json<TakerMarketStat>();
};

const useTakerMarketStats = (params: { marketId: string; takerId: number }) => {
  const { marketId, takerId } = params;

  return useSuspenseQuery({
    queryKey: ['taker-market-stats', marketId, takerId],
    queryFn: () =>
      fetchTakerMarketStats({
        marketId,
        takerId,
      }),
  });
};

export default useTakerMarketStats;
