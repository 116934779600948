export const Features = {
  earlyPay_offerBuilderRevamp: 'earlyPay_offerBuilderRevamp',
} as const;

/**
 * treatment definitions by env
 * utilized for determining how to serve treatment if split fails to load
 */
const features =
  import.meta.env.VITE_ENV === 'prod'
    ? {
        [Features['earlyPay_offerBuilderRevamp']]: 'on',
      }
    : {
        [Features['earlyPay_offerBuilderRevamp']]: 'on',
      };

export default features;
