import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

const useCreateBuyersExpansion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['create-buyers-expansion'],
    mutationFn: async (data: paths['/buyers/expansion']['post']['requestBody']['content']['application/json']) => {
      const response = await apiClient
        .post('api/supplier-experience/buyers/expansion', {
          json: data,
        })
        .json<paths['/buyers/expansion']['post']['responses']['200']['content']['application/json']>();

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['buyers-expansion'],
      });
    },
  });
};

export default useCreateBuyersExpansion;
