import { AwardedInvoice } from '@/enterprise/data/useAwardedInvoices';
import { IneligibleInvoice } from '@/enterprise/data/useIneligibleInvoices';
import { InvoiceAdjustment } from '@/enterprise/data/useInvoiceAdjustments';

const getInvoiceDisplay = (invoice: Partial<IneligibleInvoice & AwardedInvoice & InvoiceAdjustment>) => {
  if (invoice.isCoveringAdjustments) {
    return {
      color: 'text-orange-500',
      translation: 'taker.invoiceStatus.isAdjusted',
    };
  }

  if (invoice.isPastDue) {
    return {
      color: 'text-red-500',
      translation: 'taker.invoiceStatus.isPastDue',
    };
  }

  if (invoice.makerExcluded || invoice.makerExcludedForToday) {
    return {
      color: 'text-gray-500',
      translation: 'taker.invoiceStatus.isMakerExcluded',
    };
  }

  if (invoice.isPendingClear) {
    return {
      color: 'text-gray-500',
      translation: 'taker.isPendingClear',
    };
  }

  if (invoice.isCleared) {
    return {
      color: 'text-gray-500',
      translation: 'taker.isCleared',
    };
  }

  if (invoice.isAwarded) {
    return {
      color: 'text-gray-500',
      translation: 'taker.isCleared',
    };
  }

  return {
    color: 'text-text-primary',
    translation: 'taker.invoiceStatus.reservedByCustomer',
  };
};

export default getInvoiceDisplay;
