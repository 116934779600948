import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { ExchangeRates } from '@/shared/data/useExchangeRates';
import convertCurrency from '@/utils/convertCurrency';
import { takersMarketIsParticipating } from '@/utils/takersMarketIsParticipating';

type PrimaryBuyer =
  | {
      eligibleInvoiceAmount: number;
      makerOrganizationName: string;
      makerOrganizationUuid: string;
      takerDivisionName: string;
    }
  | Record<string, never>;

interface StatsByCurrency {
  acceptedDpeWeightedAvg: number;
  acceptedDpeWeightedSum: number;
  acceptedEarn: number;
  acceptedInvoiceAmount: number;
  byBuyer: {
    [makerOrganizationUuid: string]: PrimaryBuyer;
  };
  eligibleDpeWeightedAvg: number;
  eligibleDpeWeightedSum: number;
  eligibleInvoiceAmount: number;
  notAcceptedDpeWeightedAvg: number;
  notAcceptedDpeWeightedSum: number;
  notAcceptedInvoiceAmount: number;
  notParticipatingDpeWeightedAvg: number;
  notParticipatingInvoiceAmount: number;
  takerExcludedInvoiceAmount: number;
}

export interface AggregateTakerMarketStats {
  aggregateStats: {
    acceptedInvoiceCount: number;
    acceptedPercent: number;
    eligibleInvoiceCount: number;
    hasNeverPlacedOffer: boolean;
    notAcceptedInvoiceCount: number;
    notAcceptedPercent: number;
    notParticipatingDivisionCount: number;
    notParticipatingInvoiceCount: number;
    numberOfTakerMarkets: number;
    takerExcludedInvoiceCount: number;
  };
  byCurrency: {
    [currency: string]: StatsByCurrency;
  };
  primaryBuyer: PrimaryBuyer;
}

const normalizeStats = (
  takerMarkets: TakerMarket[] = [],
  exchangeRates: ExchangeRates = {}
): AggregateTakerMarketStats => {
  // map, aggregate, and convert currency for all taker market stats for each available currency
  const byCurrency = Object.keys(exchangeRates).reduce<{
    [currency: string]: StatsByCurrency;
  }>((byCurrencyMap, currency) => {
    byCurrencyMap[currency] = takerMarkets.reduce<StatsByCurrency>(
      (takerMarketStatsMap, takerMarket) => {
        // rollup by currency with exchange rate applied
        takerMarketStatsMap.acceptedEarn += convertCurrency({
          amount: takerMarket.acceptedEarn,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.acceptedInvoiceAmount += convertCurrency({
          amount: takerMarket.acceptedInvoiceAmount,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.acceptedDpeWeightedSum += convertCurrency({
          amount: takerMarket.acceptedDpeWeightedSum,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.eligibleInvoiceAmount += convertCurrency({
          amount: takerMarket.eligibleInvoiceAmount,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.eligibleDpeWeightedSum += convertCurrency({
          amount: takerMarket.eligibleDpeWeightedSum,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.notAcceptedInvoiceAmount += convertCurrency({
          amount: takerMarket.notAcceptedInvoiceAmount,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.notAcceptedDpeWeightedSum += convertCurrency({
          amount: takerMarket.notAcceptedDpeWeightedSum,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.takerExcludedInvoiceAmount += convertCurrency({
          amount: takerMarket.takerExcludedInvoiceAmount,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.notParticipatingInvoiceAmount += convertCurrency({
          amount:
            takerMarket.eligibleInvoiceAmount -
            takerMarket.acceptedInvoiceAmount -
            takerMarket.notAcceptedInvoiceAmount,
          exchangeRates,
          from: takerMarket.currency,
          to: currency,
        });
        takerMarketStatsMap.acceptedDpeWeightedAvg =
          takerMarketStatsMap.acceptedDpeWeightedSum / takerMarketStatsMap.acceptedInvoiceAmount || 0;
        takerMarketStatsMap.eligibleDpeWeightedAvg =
          takerMarketStatsMap.eligibleDpeWeightedSum / takerMarketStatsMap.eligibleInvoiceAmount || 0;
        takerMarketStatsMap.notAcceptedDpeWeightedAvg =
          takerMarketStatsMap.notAcceptedDpeWeightedSum / takerMarketStatsMap.notAcceptedInvoiceAmount || 0;
        takerMarketStatsMap.notParticipatingDpeWeightedAvg =
          (takerMarketStatsMap.eligibleDpeWeightedSum -
            takerMarketStatsMap.acceptedDpeWeightedSum -
            takerMarketStatsMap.notAcceptedDpeWeightedSum) /
            (takerMarketStatsMap.eligibleInvoiceAmount -
              (takerMarketStatsMap.acceptedInvoiceAmount + takerMarketStatsMap.notAcceptedInvoiceAmount)) || 0;

        // rollup by buyer with exchange rate applied
        takerMarketStatsMap.byBuyer[takerMarket.makerOrganizationUuid] = {
          makerOrganizationName: takerMarket.makerOrganizationName,
          makerOrganizationUuid: takerMarket.makerOrganizationUuid,
          takerDivisionName: takerMarket.takerDivisionName,
          eligibleInvoiceAmount:
            convertCurrency({
              amount: takerMarket.eligibleInvoiceAmount,
              exchangeRates,
              from: takerMarket.currency,
              to: currency,
            }) + (takerMarketStatsMap.byBuyer[takerMarket.makerOrganizationUuid]?.eligibleInvoiceAmount || 0),
        };

        return takerMarketStatsMap;
      },
      {
        acceptedDpeWeightedAvg: 0,
        acceptedDpeWeightedSum: 0,
        acceptedEarn: 0,
        acceptedInvoiceAmount: 0,
        byBuyer: {},
        eligibleDpeWeightedAvg: 0,
        eligibleDpeWeightedSum: 0,
        eligibleInvoiceAmount: 0,
        notAcceptedDpeWeightedAvg: 0,
        notAcceptedDpeWeightedSum: 0,
        notAcceptedInvoiceAmount: 0,
        notParticipatingDpeWeightedAvg: 0,
        notParticipatingInvoiceAmount: 0,
        takerExcludedInvoiceAmount: 0,
      }
    );

    return byCurrencyMap;
  }, {});

  // aggregate stats across all taker markets
  const aggregateStats = takerMarkets.reduce(
    (aggregateStatsMap, takerMarket) => {
      aggregateStatsMap.acceptedInvoiceCount += takerMarket.acceptedInvoiceCount;
      aggregateStatsMap.eligibleInvoiceCount += takerMarket.eligibleInvoiceCount;
      aggregateStatsMap.notAcceptedInvoiceCount += takerMarket.notAcceptedInvoiceCount;
      aggregateStatsMap.notParticipatingInvoiceCount +=
        takerMarket.eligibleInvoiceCount - takerMarket.acceptedInvoiceCount - takerMarket.notAcceptedInvoiceCount;
      aggregateStatsMap.takerExcludedInvoiceCount += takerMarket.takerExcludedInvoiceCount;

      if (!takersMarketIsParticipating(takerMarket)) {
        aggregateStatsMap.notParticipatingDivisionCount++;
      }

      return aggregateStatsMap;
    },
    {
      acceptedInvoiceCount: 0,
      eligibleInvoiceCount: 0,
      notAcceptedInvoiceCount: 0,
      notParticipatingDivisionCount: 0,
      notParticipatingInvoiceCount: 0,
      takerExcludedInvoiceCount: 0,
    }
  );

  // calculate accepted/not accepted percent/primary buyer from first taker market byCurrency stat
  const firstStatKey = Object.keys(byCurrency)[0];
  const firstStat = byCurrency[firstStatKey];
  const acceptedPercent =
    firstStat?.acceptedInvoiceAmount / (firstStat?.acceptedInvoiceAmount + firstStat?.notAcceptedInvoiceAmount) || 0;
  const notAcceptedPercent = 1 - acceptedPercent || 0;

  const primaryBuyer = Object.values(firstStat?.byBuyer || {}).reduce<PrimaryBuyer>(
    (highestInvoiceAmountBuyer, buyer) =>
      (highestInvoiceAmountBuyer?.eligibleInvoiceAmount || 0) > buyer.eligibleInvoiceAmount
        ? highestInvoiceAmountBuyer
        : buyer,
    {}
  );

  const hasNeverPlacedOffer = takerMarkets.every((takerMarket) =>
    takerMarket.offerConfig ? !takerMarket.offerConfig.id : true
  );

  const numberOfTakerMarkets = takerMarkets.length;

  return {
    aggregateStats: {
      ...aggregateStats,
      acceptedPercent,
      hasNeverPlacedOffer,
      notAcceptedPercent,
      numberOfTakerMarkets,
    },
    byCurrency,
    primaryBuyer,
  };
};

export default normalizeStats;
