import { TakerMarket } from '@/enterprise/data/useTakerMarkets';

export const takersMarketIsParticipating = (takerMarket: TakerMarket) => {
  // if a bid of zero is set, their participation is defined as off
  const offerConfig = takerMarket.offerConfig || {};
  let participating = offerConfig.isEnabled;

  if (offerConfig.isEnabled) {
    if (offerConfig.isDiscountBidding) {
      participating = offerConfig.maxDiscount !== 0;
    } else {
      participating = offerConfig.maxApr !== 0;
    }
  }

  return participating;
};
