import { Link, useLocation } from 'react-router-dom';
import { cn, cva } from '@c2fo/liquidity';

interface LinkTabItem {
  key: string;
  label: string | JSX.Element;
  link: string;
  matcher: string;
  matchExact?: boolean;
}

const linkTabStyle = cva(
  [
    'border-b-2',
    'border-transparent',
    'duration-200',
    'p-4',
    'text-text-secondary',
    'transition-all',
    'whitespace-nowrap',
    'hover:text-text-primary',
  ],
  {
    variants: {
      variant: {
        primary: ['hover:bg-gray-200', 'hover:border-gray-200'],
        secondary: ['hover:bg-deepBlue-50', 'hover:border-b-deepBlue-50'],
      },
      active: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        active: true,
        className: ['bg-gray-200', 'border-primary-500', 'text-text-primary', 'hover:border-primary-500'],
      },
      {
        variant: 'secondary',
        active: true,
        className: ['border-secondary-500', 'text-text-primary', 'hover:bg-transparent', 'hover:border-secondary-500'],
      },
    ],
  }
);

const LinkTabs = ({
  containerClassName,
  className,
  items,
  variant,
}: {
  containerClassName?: string;
  className?: string;
  items: LinkTabItem[];
  variant: 'primary' | 'secondary';
}) => {
  const { pathname } = useLocation();

  return (
    <div className={cn('flex items-center gap-1 overflow-auto font-medium capitalize', containerClassName)}>
      {items.map((item) => {
        const path = `/${item.matcher}`;
        const active = item.matchExact ? pathname === path : pathname.includes(path);

        return (
          <Link
            key={item.key}
            className={linkTabStyle({ active, className, variant })}
            preventScrollReset
            to={item.link}
          >
            {item.label}
          </Link>
        );
      })}
    </div>
  );
};

export default LinkTabs;
