import { useSuspenseQuery } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';
import groupTakerMarketsByMakerOrg from '@/utils/groupTakerMarketsByMakerOrg';
import useTakerMarkets, { TakerMarket } from './useTakerMarkets';

const fetchPlatformOffersForMaker = async ({ makerOrganizationUuid }: { makerOrganizationUuid: string }) => {
  return (
    (
      await apiClient.get(`api/platform/maker-organizations/${makerOrganizationUuid}/offers`, {
        headers: {
          'c2fo-application-name': 'enterprise-ui',
        },
      })
    )
      /**
       * NOTE: It is possible to generate this type via codegen from the platform OpenAPI schema.
       * However, we view calling platform directly as a temporary solution until our own SEA service is ready.
       * Therefore, we are opting not to integrate platform api codegen at this time.
       */
      .json<{
        data: [
          {
            identifier?: string; // Absent if not active
            status: 'active' | 'paused' | 'inactive';
            marketUuid: string;
            divisionUuid: string;
            makerOrganizationUuid: string;
          },
        ];
      }>()
  );
};

export const fetchPlatformOffers = async ({ takerMarkets }: { takerMarkets?: TakerMarket[] }) => {
  if (!takerMarkets || takerMarkets.length === 0) {
    return [];
  }

  const takerMarketsByMakerOrg = groupTakerMarketsByMakerOrg(takerMarkets ?? []);
  const makerOrganizationUuids = Object.keys(takerMarketsByMakerOrg);
  const response = await Promise.all(
    makerOrganizationUuids.map((makerOrganizationUuid) => fetchPlatformOffersForMaker({ makerOrganizationUuid }))
  );
  const offers = response
    .flatMap((r) => r.data)
    .filter((offer) => {
      return takerMarkets.some(
        (takerMarket) =>
          takerMarket.takerDivisionUuid === offer.divisionUuid && takerMarket.marketUuid === offer.marketUuid
      );
    });

  return offers;
};

export const usePlatformOffers = () => {
  const { data: takerMarkets } = useTakerMarkets();

  return useSuspenseQuery({
    queryKey: ['platform-offers'],
    queryFn: () => fetchPlatformOffers({ takerMarkets }),
  });
};
