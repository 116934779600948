import { ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { SplitFactoryProvider, useSplitTreatments } from '@splitsoftware/splitio-react';
import enterpriseFeatureFlags, { Features as EnterpriseFeatures } from '@/enterprise/features';
import FullPageLoader from '@/shared/components/FullPageLoader';
import useUserDetails from '@/shared/data/useUserDetails';
import smbFeatureFlags, { Features as SmbFeatures } from '@/smb/features';
import { getTokenContent } from '@/utils/token';

const DEFAULT_SPLIT_KEY = 'no-divisions-user';

export const Features = {
  ...EnterpriseFeatures,
  ...SmbFeatures,
} as const;

const features = {
  ...enterpriseFeatureFlags,
  ...smbFeatureFlags,
};

export type Feature = keyof typeof Features;

export function FeaturesProvider({ children }: { children: ReactNode }) {
  const config: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: import.meta.env.VITE_SPLIT_IO_KEY ?? 'localhost',
      key: DEFAULT_SPLIT_KEY,
    },
    impressionListener: {
      logImpression(impressionData) {
        datadogRum.addFeatureFlagEvaluation(impressionData.impression.feature, impressionData.impression.treatment);
      },
    },
    features,
    startup: {
      readyTimeout: 3,
      requestTimeoutBeforeReady: 3,
      retriesOnFailureBeforeReady: 5,
    },
  };

  return (
    <SplitFactoryProvider config={config} updateOnSdkTimedout updateOnSdkUpdate>
      {({ isReady, isTimedout }) => {
        // loading
        if (!isReady && !isTimedout) {
          return <FullPageLoader />;
        }

        // good to go
        return <div>{children}</div>;
      }}
    </SplitFactoryProvider>
  );
}

function getFeatureTreatment<T = Record<string, unknown>>(
  treatments: SplitIO.TreatmentsWithConfig,
  featureName: Feature
) {
  const feature = treatments[featureName];

  let treatment = feature.treatment;

  /**
   * if split fails to load and does not return a treatment, "control" will be returned
   * fallback to utilizing treatment override by env if defined
   */
  if (treatment === 'control' && features[featureName]) {
    treatment = features[featureName];
  }

  return [treatment === 'on', feature.config ? (JSON.parse(feature.config) as T) : undefined] as const;
}

/**
 * Check if a Split treatment's value is "on".
 * @returns A tuple containing whether the treatment is on and the config object, or undefined if it exists.
 */
export default function useFeature(featureName: Feature, attributes?: SplitIO.Attributes) {
  const { data: accountId } = useUserDetails(({ me }) => me?.account?.id);
  const splitKey = accountId ?? DEFAULT_SPLIT_KEY;

  const { treatments } = useSplitTreatments({
    names: [featureName],
    attributes: { ...attributes, trafficType: 'account' },
    splitKey,
  });

  return getFeatureTreatment(treatments, featureName);
}

export function useFeatureForUser(featureName: Feature, attributes?: SplitIO.Attributes) {
  const token = getTokenContent();
  const splitKey = token?.payload?.user?.uuid ?? DEFAULT_SPLIT_KEY;

  const { treatments } = useSplitTreatments({
    names: [featureName],
    attributes: { ...attributes, trafficType: 'user' },
    splitKey,
  });

  return getFeatureTreatment(treatments, featureName);
}
