import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Checkbox, Label, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import CardForm from '@/accountSettings/components/CardForm';
import Header from '@/accountSettings/components/Header';
import useForgetUser from '@/accountSettings/data/useForgetUser';
import { useReporting } from '@/reporting';
import unlink from '@/shared/assets/unlink.svg?url';
import logout from '@/utils/logout';

const RightToBeForgotten = () => {
  const { t } = useTranslation();
  const { trackMultiExperienceEvent } = useReporting();
  const { mutateAsync, isPending } = useForgetUser();
  const [hasError, setHasError] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onClose = () => {
    setShowConfirmModal(false);
    setEnableSubmit(false);
    setHasError(false);
  };

  const handleForgetUser = async () => {
    await mutateAsync(undefined, {
      onSuccess: async () => {
        await trackMultiExperienceEvent('prefs::rtbf::logout');
        await logout();
      },
      onError: () => {
        setHasError(true);
      },
    });
  };

  return (
    <>
      <div>
        <Header title={t('accountSettings.rightToBeForgotten')} />
        <CardForm.Card
          content={
            <CardForm.CardContent className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
              <div className="space-y-2">
                <p>{t('accountSettings.rightToBeForgottenText1')}</p>
                <p>{t('accountSettings.rightToBeForgottenText2')}</p>
              </div>
              <div className="shrink-0">
                <Button onClick={() => setShowConfirmModal(true)} variant="destructive">
                  {t('accountSettings.rightToBeForgottenForgetMe')}
                </Button>
              </div>
            </CardForm.CardContent>
          }
        />
      </div>
      <Modal open={showConfirmModal} onClose={onClose} size="sm">
        <ModalTitleInfo>{t('accountSettings.rightToBeForgottenAreYouSure')}</ModalTitleInfo>
        <ModalContent className="space-y-6">
          <div className="flex justify-center">
            <img src={unlink} alt="unlink" className="h-20 w-20" />
          </div>
          <p className="space-x-1">
            <span>{t('accountSettings.rightToBeForgottenText3')}</span>
            <span className="font-medium">{t('accountSettings.rightToBeForgottenText4')}</span>
          </p>
          <div className="flex gap-4">
            <Checkbox id="understandCheckbox" color="primary" onClick={() => setEnableSubmit(!enableSubmit)} />
            <Label className="cursor-pointer" htmlFor="understandCheckbox">
              {t('accountSettings.rightToBeForgottenText5')}
            </Label>
          </div>
          {hasError && <Alert description={t('accountSettings.rightToBeForgottenError')} full type="error" />}
        </ModalContent>
        <ModalActions>
          <Button disabled={isPending} onClick={onClose} variant="secondary">
            {t('core.cancel')}
          </Button>
          <Button disabled={!enableSubmit} loading={isPending} onClick={handleForgetUser} variant="destructive">
            {t('accountSettings.rightToBeForgottenForgetMe')}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default RightToBeForgotten;
