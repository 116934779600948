import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Label,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
  useSnackbar,
} from '@c2fo/liquidity';
import useOptOutAllCommunicationPreferences from '@/accountSettings/data/useOptOutAllCommunicationPreferences';
import { useReporting } from '@/reporting';

interface UnsubscribeFormInputs {
  unsubscribeComment: string;
  unsubscribeReason: string;
}

const defaultValues = {
  unsubscribeReason: '',
  unsubscribeComment: '',
};

const unsubscribeOptions = [
  { label: 'accountSettings.notificationSettingsUnsubscribeOption1', value: 'emailsNotWanted' },
  { label: 'accountSettings.notificationSettingsUnsubscribeOption2', value: 'tooManyEmails' },
  { label: 'accountSettings.notificationSettingsUnsubscribeOption3', value: 'incorrectContact' },
  { label: 'accountSettings.notificationSettingsUnsubscribeOption4', value: 'irrelevantContent' },
  { label: 'accountSettings.notificationSettingsUnsubscribeOption5', value: 'neverSignedUp' },
  { label: 'accountSettings.notificationSettingsUnsubscribeOption6', value: 'other' },
];

const Unsubscribe = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const { trackMultiExperienceEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<UnsubscribeFormInputs>({ defaultValues });
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const { mutateAsync, isPending } = useOptOutAllCommunicationPreferences();

  const onSubmit: SubmitHandler<UnsubscribeFormInputs> = async (data) => {
    await mutateAsync(undefined, {
      onSuccess: () => {
        trackMultiExperienceEvent('notifs::unsubscribe-all', {
          unsubscribeReason: data.unsubscribeReason,
          unsubscribeComment: data.unsubscribeComment,
        });
        setShowUnsubscribeModal(false);
        showSnackbar({ message: t('accountSettings.notificationSettingsUnsubscribeSuccess') });
        reset();
      },
      onError: () => {
        showSnackbar({ message: t('accountSettings.notificationSettingsUnsubscribeError') });
      },
    });
  };

  return (
    <>
      <Modal open={showUnsubscribeModal} onClose={() => setShowUnsubscribeModal(false)} size="sm">
        <ModalTitle>{t('accountSettings.notificationSettingsUnsubscribe')}</ModalTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent className="space-y-6">
            <div>{t('accountSettings.notificationSettingsUnsubscribeModalText')}</div>
            <div className="w-full space-y-1">
              <Label htmlFor="unsubscribeReason" required>
                {t('accountSettings.notificationSettingsUnsubscribeModalReason')}
              </Label>
              <Controller
                control={control}
                name="unsubscribeReason"
                render={({ field }) => (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger
                      aria-label={t('accountSettings.notificationSettingsSelectAReason')}
                      hasError={!!errors.unsubscribeReason}
                    >
                      <SelectValue placeholder={t('accountSettings.notificationSettingsSelectAReason')} />
                    </SelectTrigger>
                    <SelectContent>
                      {unsubscribeOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {t(option.label)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="w-full space-y-1">
              <Label htmlFor="unsubscribeComment">
                {t('accountSettings.notificationSettingsUnsubscribeModalComment')}
              </Label>
              <TextInput id="unsubscribeComment" className="w-full" {...register('unsubscribeComment')} />
            </div>
          </ModalContent>
          <ModalActions className="flex flex-row-reverse sm:flex-row-reverse sm:justify-start">
            <Button loading={isPending} type="submit" variant="cta">
              {t('accountSettings.notificationSettingsUnsubscribe')}
            </Button>
            <Button disabled={isPending} onClick={() => setShowUnsubscribeModal(false)} variant="secondary">
              {t('core.cancel')}
            </Button>
          </ModalActions>
        </form>
      </Modal>
      <Button disabled={disabled} onClick={() => setShowUnsubscribeModal(true)} variant="destructive">
        {t('accountSettings.notificationSettingsUnsubscribe')}
      </Button>
    </>
  );
};

export default Unsubscribe;
