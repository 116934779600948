import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { add } from 'date-fns';
import { Button, cn } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CalendarLinesIcon } from '@c2fo/liquidity/icons';
import { useReporting } from '@/reporting';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import DatePicker from './DatePicker';

export type OfferDurationType = 'no-expiration' | 'expiration-date';

interface OfferDurationProps {
  expireOn: string | null; // Expecting ISO date string (e.g. "2024-07-29T00:00:00.000Z")
  setExpireOn: (isoDateString: string | null) => void;
  isAlwaysOpen?: boolean;
  isBgTransparent?: boolean;
}

export const OfferDuration = ({ expireOn, setExpireOn, isAlwaysOpen, isBgTransparent }: OfferDurationProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(isAlwaysOpen ?? false);
  const [expirationType, setExpirationType] = useState<OfferDurationType>('no-expiration');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { trackEnterpriseEvent } = useReporting();

  const toggleAccordion = () => {
    if (open === false) {
      trackEnterpriseEvent('offer::duration::opened');
    }

    setOpen(!open);
  };

  useEffect(() => {
    if (expireOn) {
      setOpen(true);
      setExpirationType('expiration-date');
    }
  }, [expireOn]);

  const onSelectDate = (date: Date | undefined) => {
    setDatePickerOpen(false);
    if (date) {
      // NOTE: We have decided to set the date selected to UTC midnight
      // https://honeycomb.jira.com/wiki/spaces/ENG/pages/4339892227/Expiration+Dates+Overview
      const isoDateTime = dateAsIsoString(date) + 'T00:00:00.000Z';
      trackEnterpriseEvent('offer::duration::expiration-date::selected');
      setExpireOn(isoDateTime);
    }
  };

  return (
    <div
      className={cn('overflow-hidden rounded', {
        'bg-deepBlue-50': !isBgTransparent,
        'bg-transparent': isBgTransparent,
      })}
    >
      <div className="flex items-center justify-between px-4 py-3">
        <p className="text-base font-medium">{t('expirationDate.offerDuration')}</p>
        <Button
          variant="ancillary"
          size="sm"
          className={cn('p-2 text-sm font-bold', {
            hidden: isAlwaysOpen,
          })}
          onClick={toggleAccordion}
        >
          {open ? t('core.hide') : t('core.show')}
        </Button>
      </div>
      {open && (
        <div className="p-4 pt-0">
          <fieldset className="flex flex-col gap-3">
            <legend className="sr-only">{t('taker.dictionary.expirationDate.label')}</legend>

            <div className="flex items-start space-x-3">
              <div className="pt-1">
                <input
                  name="expiration"
                  id="no-expiration"
                  aria-labelledby="no-expiration-label"
                  type="radio"
                  checked={expirationType === 'no-expiration'}
                  value="no-expiration"
                  className="h-4 w-4 cursor-pointer text-secondary-600 accent-secondary-500 focus:ring-secondary-600"
                  onChange={() => {
                    setExpirationType('no-expiration');
                    setExpireOn(null);
                    trackEnterpriseEvent('offer::duration::no-expiration::clicked');
                  }}
                />
              </div>
              <div>
                <label id="no-expiration-label" htmlFor="no-expiration" className="text-base font-bold">
                  {t('expirationDate.noExpiration.label')}
                </label>
                <p className="text-sm text-secondary-400">{t('expirationDate.noExpiration.description')}</p>
              </div>
            </div>

            <div className="flex items-start space-x-3">
              <div className="pt-1">
                <input
                  name="expiration"
                  id="expiration-date"
                  aria-labelledby="expiration-date-label"
                  type="radio"
                  checked={expirationType === 'expiration-date'}
                  value="expiration-date"
                  className="h-4 w-4 cursor-pointer text-secondary-600 accent-secondary-500 focus:ring-secondary-600"
                  onChange={() => {
                    setExpirationType('expiration-date');
                    trackEnterpriseEvent('offer::duration::expiration-date::clicked');
                  }}
                />
              </div>
              <div className="w-full pr-6">
                <label id="expiration-date-label" htmlFor="expiration-date" className="text-base font-bold">
                  {t('taker.dictionary.expirationDate.label')}
                </label>
                <p className="text-sm text-secondary-400">{t('expirationDate.description')}</p>
                {expirationType === 'expiration-date' && (
                  <div className="mt-2">
                    <DatePicker
                      disabledOptions={{ before: add(new Date(), { days: 1 }) }}
                      open={datePickerOpen}
                      onOpenChange={() => {
                        setDatePickerOpen(!datePickerOpen);
                      }}
                      onSelect={onSelectDate}
                      selected={expireOn ? trimDateTimestamp(expireOn) : null}
                      triggerClassName="w-full"
                      trigger={
                        <div
                          aria-label={t('core.selectDate')}
                          className="flex h-12 w-full items-center justify-between rounded-md border bg-white px-4"
                          role="button"
                        >
                          {expireOn ? trimDateTimestamp(expireOn) : t('core.selectDate')}
                          <CalendarLinesIcon className="h-6 w-6" fill={colors.text.secondary} />
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </div>
      )}
    </div>
  );
};
