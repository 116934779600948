import { ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInfo, Popover, Spinner } from '@c2fo/liquidity';
import { TypingIcon } from '@c2fo/liquidity/icons';
import { EnterpriseOfferType } from '@/generated/gql/graphql';
import { enterpriseBasename } from '@/constants';
import successSvg from '@/enterprise/assets/success.svg?url';
import { useConfirmAgreementsWhenRequired } from '@/enterprise/data/useAgreements';
import useDraftOffer from '@/enterprise/data/useDraftOffer';
import useSubmitPreferredOffer from '@/enterprise/data/useSubmitPreferredOffer';
import { Agreement } from '@/enterprise/features/agreements/Agreement';
import RuleChips from '@/enterprise/features/recurringRules/components/RuleChips';
import useGetRecurringRulesForTakerMarkets from '@/enterprise/features/recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import { RecurringRulesCountChip } from '@/enterprise/features/takerMarketTable/components/RecurringRuleExclusionChip';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { DraftOfferProps } from '../common/DraftOffer.types';
import { DraftOfferTemplate } from '../common/DraftOfferTemplate';
import { useTakerMarketsInDraftOffer } from '../common/useTakerMarketsInDraftOffer';

export const DraftOfferPreferred = ({ draftOfferUuid }: DraftOfferProps) => {
  const { data: draftOfferData } = useDraftOffer(draftOfferUuid);
  const { takerMarkets: takerMarketsInOffer, stats } = useTakerMarketsInDraftOffer(draftOfferUuid);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFinalApprovalModalOpen, setIsFinalApprovalModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { trackEnterpriseEvent } = useReporting();
  const { asDateString } = useLocaleFormat();
  const { mutateAsync: submitPreferredDraftOffer, isPending: isSubmittingDraftOffer } = useSubmitPreferredOffer();
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired(takerMarketsInOffer);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();

  const draftOfferTrackEventData: {
    draftOfferUuid: string;
    type: EnterpriseOfferType;
  } = {
    draftOfferUuid,
    type: 'PREFERRED',
  };

  useEffectOnce(() => {
    trackEnterpriseEvent('draft-offer::viewed', draftOfferTrackEventData);
  });

  const formattedTermEndDate = useMemo(() => {
    if (!draftOfferData?.termExpirationDate) {
      return t('core.na');
    }

    const date = asDateString(trimDateTimestamp(draftOfferData.termExpirationDate), {
      month: 'long',
      year: 'numeric',
    });

    return date;
  }, [asDateString, draftOfferData?.termExpirationDate, t]);

  const displayRowContent: { title: ReactNode; content: ReactNode }[] = [
    {
      title: t('draftOffer.labels.offerType'),
      content: t('core.offerType.preferred'),
    },
    {
      title: t('draftOffer.labels.rate'),
      content: <span className="uppercase">{stats.formattedRate}</span>,
    },
    {
      title: t('draftOffer.labels.term'),
      content: formattedTermEndDate,
    },
  ];

  const onCancel = () => {
    trackEnterpriseEvent('draft-offer::cancel', draftOfferTrackEventData);
    navigate(`/${enterpriseBasename}`);
  };

  const onApprove = () => {
    setIsFinalApprovalModalOpen(true);
  };

  const onFinalApproval = async () => {
    try {
      setIsSubmitError(false);

      trackEnterpriseEvent('draft-offer::submit', draftOfferTrackEventData);

      if (!draftOfferUuid || !draftOfferData) {
        throw new Error('Not enough draft offer data available to submit the offer.');
      }

      try {
        await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
      } catch {
        setIsAgreementsErrorNotChecked(true);
        return;
      }

      await submitPreferredDraftOffer({
        draftId: draftOfferUuid,
        // We're using this to preemptively update the react query cache for takers markets
        setOfferConfigQueryData: {
          isEnabled: true,
          offerType: 'PREFERRED_TERM',
          maxApr: draftOfferData.rateType === 'APR' ? draftOfferData.rate : null,
          maxDiscount: draftOfferData.rateType === 'DISC' ? draftOfferData.rate : null,
          isDiscountBidding: draftOfferData.rateType === 'DISC' ? true : false,
          disableAfterMarketClearsDate: draftOfferData.termExpirationDate,
        },
        rateInformation: draftOfferData.rateInformation,
        supplierMarkets: draftOfferData.supplierMarkets,
      });

      setIsSuccessModalOpen(true);
      setIsFinalApprovalModalOpen(false);

      trackEnterpriseEvent('draft-offer::submit::success', draftOfferTrackEventData);
    } catch (error) {
      console.error(error);
      setIsSubmitError(true);
      trackEnterpriseEvent('draft-offer::submit::error', {
        ...draftOfferTrackEventData,
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  };

  const onViewDetails = () => {
    trackEnterpriseEvent('draft-offer::view-details', draftOfferTrackEventData);
    navigate(asEnterpriseLink('markets/preferred'));
  };

  return (
    <div>
      <DraftOfferTemplate
        cardSlot={
          <div className="space-y-4">
            <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
              {displayRowContent.map((rowData, i) => (
                <div key={i} className="flex items-center justify-between gap-4 p-4">
                  <p className="text-sm">{rowData.title}</p>
                  <p className="text-sm font-semibold">{rowData.content}</p>
                </div>
              ))}
            </div>
            <div className="space-y-2">
              <p className="text-base font-bold ">{t('core.customers')}</p>
            </div>
            <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
              {takerMarketsInOffer.map((tm) => {
                const [recurringRule] = getRecurringRulesForTakerMarkets([tm]);

                return (
                  <div key={tm.id} className="items-center justify-between gap-4 p-4 md:flex">
                    <p className="text-sm font-semibold">
                      <span className="flex flex-col gap-3">
                        <span key={tm.id}>
                          <span className="block">{tm.makerDivisionName}</span>
                          <span className="block text-xs font-normal">{getTakerMarketDivisionTitle(tm)?.title}</span>
                        </span>
                      </span>
                    </p>
                    {recurringRule && (
                      <div className="flex items-center justify-between gap-2 p-2 pl-0">
                        <Popover>
                          <Popover.Trigger>
                            <RecurringRulesCountChip count={recurringRule.count} />
                          </Popover.Trigger>
                          <Popover.Content
                            variant="dark"
                            arrow
                            side="top"
                            className="max-w-sm sm:max-w-xl"
                            collisionPadding={16}
                          >
                            <QueryBoundaries
                              LoadingComponent={() => (
                                <div className="flex items-center justify-center">
                                  <Spinner className="h-12 w-12 fill-white" />
                                </div>
                              )}
                            >
                              <RuleChips inverse recurringRule={recurringRule} withLabel />
                            </QueryBoundaries>
                          </Popover.Content>
                        </Popover>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <Agreement
              takersMarkets={takerMarketsInOffer}
              onChange={(v) => {
                setIsAgreementsChecked(v);
                setIsAgreementsErrorNotChecked(false);
              }}
            />
          </div>
        }
        actionSlot={
          <>
            <Button className="w-1/3" onClick={onCancel} variant="secondary">
              {t('core.cancel')}
            </Button>
            <Button className="w-1/3" onClick={onApprove} variant="cta">
              {t('draftOffer.approve')}
            </Button>
          </>
        }
      />

      {/* Final Confirmation Modal */}
      <Modal open={isFinalApprovalModalOpen} onClose={() => setIsFinalApprovalModalOpen(false)} size="sm">
        <ModalTitleInfo>{t('draftOffer.finalApproval')}</ModalTitleInfo>
        <ModalContent center>
          <TypingIcon className="h-24 w-auto lg:block" />
          <p className="max-w-xs text-center text-base">{t('draftOffer.confirmation.body.preferred')}</p>
          {isSubmitError && <Alert type="error" variant="filled" description={t('draftOffer.error')} full />}
          {isAgreementsErrorNotChecked && (
            <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
          )}
        </ModalContent>
        <ModalActions>
          <Button className="shrink" full variant="secondary" onClick={() => setIsFinalApprovalModalOpen(false)}>
            {t('core.cancel')}
          </Button>
          <Button
            className="shrink"
            full
            variant="cta"
            onClick={onFinalApproval}
            loading={isSubmittingDraftOffer}
            disabled={isSubmittingDraftOffer}
          >
            {t('draftOffer.approve')}
          </Button>
        </ModalActions>
      </Modal>

      {/* Success Modal */}
      <Modal open={isSuccessModalOpen} size="sm">
        <ModalTitleInfo>{t('draftOffer.success.title.preferred')}</ModalTitleInfo>
        <ModalContent center>
          <img src={successSvg} alt="success" />
          <p className="max-w-xs text-center text-base">{t('draftOffer.success.body.preferred')}</p>
        </ModalContent>
        <ModalActions className="sm:justify-center">
          <Button variant="cta" onClick={onViewDetails} className="sm:w-1/2">
            {t('draftOffer.viewDetails')}
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
};
