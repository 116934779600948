import { Spinner } from '@c2fo/liquidity';

const FullPageLoader = () => {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <Spinner className="h-12 w-12 fill-primary-500" />
    </div>
  );
};

export default FullPageLoader;
