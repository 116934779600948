import { useTranslation } from 'react-i18next';
import Skeleton from '@/enterprise/components/Skeleton';
import CustomerGrid from '@/enterprise/features/marketSearch/components/CustomerGrid';
import MarketSearchGrid from '@/enterprise/features/marketSearch/MarketSearchGrid';
import QueryBoundaries from '@/shared/components/QueryBoundaries';

const MarketSearch = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-8">
        <h1 className="m-0 text-4xl">{t('registration.anonymousCallToAction')}</h1>
        <div className="pt-2">{t('taker.marketSearch.title')}</div>
      </div>
      <QueryBoundaries
        LoadingComponent={() => (
          <CustomerGrid>
            {Array(40)
              .fill(1)
              .map((_item, idx) => (
                <Skeleton key={idx} height="h-[196px]" />
              ))}
          </CustomerGrid>
        )}
      >
        <MarketSearchGrid />
      </QueryBoundaries>
    </div>
  );
};

export default MarketSearch;
