import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

const useUpdateBuyersExpansion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['update-buyers-expansion'],
    mutationFn: async ({
      uuid,
      data,
    }: {
      uuid: string;
      data: paths['/buyers/expansion/{uuid}']['put']['requestBody']['content']['application/json'];
    }) => {
      return apiClient
        .put(`api/supplier-experience/buyers/expansion/${uuid}`, {
          json: data,
        })
        .json<paths['/buyers/expansion/{uuid}']['put']['responses']['204']['content']>();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['buyers-expansion'],
      });
    },
  });
};

export default useUpdateBuyersExpansion;
