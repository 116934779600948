import { useQueryClient } from '@tanstack/react-query';
import useTakerMarkets from '@/enterprise/data/useTakerMarkets';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useCurrencies from '@/shared/data/useCurrencies';
import useExchangeRates from '@/shared/data/useExchangeRates';
import useUserDetails from '@/shared/data/useUserDetails';
import convertCurrency from '@/utils/convertCurrency';

interface CurrencyMap {
  currency: string;
  eligibleInvoiceAmount: number;
}

/**
 * sets the default selected currency based on the user's taker markets
 * by rolling up eligibile invoice amount, converting all to USD and
 * utilizing the highest normalized eligible invoice amount
 * defaults to setting selected currency to USD
 */
const DefaultCurrencyInitializerComponent = () => {
  const queryClient = useQueryClient();
  const currencies = useCurrencies();
  const { data: exchangeRates = {} } = useExchangeRates();
  const { data: preferredAggregateCurrencyFormat } = useUserDetails(
    (data) => data.me?.formatPreferences?.aggregateCurrencyFormat
  );

  const { data: highestEligibleInvoiceAmountCurrency } = useTakerMarkets((takerMarkets) => {
    // rollup and convert to a common currency (USD) before comparing
    const totalByCurrency = takerMarkets.reduce<CurrencyMap[]>((acc, takerMarket) => {
      const found = acc.find((a) => a.currency === takerMarket.currency);
      const eligibleInvoiceAmountUsd = convertCurrency({
        amount: takerMarket.eligibleInvoiceAmount,
        exchangeRates,
        from: takerMarket.currency,
        to: 'USD',
      });

      if (found) {
        found.eligibleInvoiceAmount += eligibleInvoiceAmountUsd;
      } else {
        acc.push({
          currency: takerMarket.currency,
          eligibleInvoiceAmount: eligibleInvoiceAmountUsd,
        });
      }

      return acc;
    }, []);

    // get highest normalized currency by converted eligible invoice amount
    const highestByCurrency = totalByCurrency.reduce((prev, curr) => {
      return prev && prev.eligibleInvoiceAmount > curr.eligibleInvoiceAmount ? prev : curr;
    }, {} as CurrencyMap);

    return highestByCurrency?.currency ?? 'USD';
  });

  // set the default user selected currency to the either preferred aggregate currency
  // or highest normalized eligible invoice amount
  // if preferred currency is not in the list of available currencies, default to highest normalized currency
  const defaultCurrency =
    preferredAggregateCurrencyFormat && currencies.includes(preferredAggregateCurrencyFormat)
      ? preferredAggregateCurrencyFormat
      : highestEligibleInvoiceAmountCurrency;

  queryClient.setQueryData<string>(['selected-currency'], defaultCurrency);

  return null;
};

const DefaultCurrencyInitializer = () => (
  <QueryBoundaries>
    <DefaultCurrencyInitializerComponent />
  </QueryBoundaries>
);

export default DefaultCurrencyInitializer;
