import { useSuspenseQuery } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// https://fss-gateway.c2fo.com
// /api/c2fo/taker/3563147/market/61af100a-d391-4afe-832b-4d4a0e0bb8a1/invoices/awarded/stats
// ?filter=%7B%7D

export interface AwardedInvoiceStats {
  count: number | null;
  subTotal: number | null;
}

const fetchAwardedInvoiceStats = async ({
  marketId,
  takerId,
}: {
  marketId: string;
  takerId: number;
}): Promise<AwardedInvoiceStats> => {
  return apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/invoices/awarded/stats`)
    .json<AwardedInvoiceStats>();
};

const useAwardedInvoiceStats = (params: { marketId: string; takerId: number }) => {
  const { marketId, takerId } = params;

  return useSuspenseQuery({
    queryKey: ['awarded-invoice-stats', marketId, takerId],
    queryFn: () =>
      !!marketId && !!takerId
        ? fetchAwardedInvoiceStats({
            marketId,
            takerId,
          })
        : null,
  });
};

export default useAwardedInvoiceStats;
