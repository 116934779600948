import { Suspense } from 'react';
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration, useLocation } from 'react-router-dom';
import DefaultCurrencyInitializer from '@/enterprise/components/DefaultCurrencyInitializer';
import enterpriseRouteConfig from '@/enterprise/Router';
import { FeaturesProvider } from '@/lib/features';
import Hydrate from '@/lib/Hydrate';
import TokenGuard from '@/lib/TokenGuard';
import { DataDogProvider } from '@/reporting/DataDog';
import ExperienceRedirect from '@/shared/components/ExperienceRedirect';
import FullPageLoader from '@/shared/components/FullPageLoader';
import InactivityMonitor from '@/shared/components/InactivityMonitor';
import AuthImpersonation from '@/shared/pages/AuthImpersonation';
import AuthMagicLogin from '@/shared/pages/AuthMagicLogin';
import SomethingsWrong from '@/shared/pages/SomethingsWrong';
import smbRouteConfig from '@/smb/Router';

const SupplierSmbPathRedirect = () => {
  const { pathname } = useLocation();

  return pathname.includes('smb') ? <Outlet /> : <ExperienceRedirect />;
};

const browserRouter = createBrowserRouter([
  {
    children: [
      // unauthenticated routes
      {
        path: 'enterprise/auth',
        children: [
          {
            index: true,
            element: <ExperienceRedirect />,
          },
          {
            path: 'magic-login',
            element: <AuthMagicLogin />,
          },
          {
            path: 'impersonate',
            element: <AuthImpersonation />,
          },
          {
            path: '*',
            element: <ExperienceRedirect />,
          },
        ],
      },
      // authenticated routes
      {
        errorElement: <SomethingsWrong />,
        element: (
          // IMPORTANT: These providers wrap both the Enterprise and SMB experiences, they MUST work for both.
          <Suspense fallback={<FullPageLoader />}>
            <TokenGuard>
              <Hydrate>
                <FeaturesProvider>
                  <DataDogProvider>
                    <InactivityMonitor />
                    <ScrollRestoration />
                    {/* TODO: optimize DefaultCurrencyInitializer for both experiences */}
                    <DefaultCurrencyInitializer />
                    <Outlet />
                  </DataDogProvider>
                </FeaturesProvider>
              </Hydrate>
            </TokenGuard>
          </Suspense>
        ),
        children: [
          {
            path: '/',
            element: <ExperienceRedirect />,
          },
          {
            path: '/enterprise',
            element: <ExperienceRedirect />,
          },
          {
            path: '/supplier',
            element: <ExperienceRedirect />,
            children: [
              {
                path: '*',
                element: <SupplierSmbPathRedirect />,
              },
            ],
          },
          enterpriseRouteConfig,
          smbRouteConfig,
          { path: '*', element: <SomethingsWrong /> },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={browserRouter} />;
};

export default Router;
