import { useSuspenseQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { GetPreferredOfferConfigQuery, QueryGetPreferredOfferConfigArgs } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';
import useUserDetails from '@/shared/data/useUserDetails';
import { usePreferredOfferIsActive } from '@/utils/usePreferredOfferIsActive';

const GET_PREFERRED_OFFER_CONFIG = graphql(`
  query GetPreferredOfferConfig($accountId: String!) {
    getPreferredOfferConfig(accountId: $accountId) {
      accountId
      canEditInvoices
    }
  }
`);

function fetchPreferredOfferConfig(variables: QueryGetPreferredOfferConfigArgs): Promise<GetPreferredOfferConfigQuery> {
  return gqlClient.request(GET_PREFERRED_OFFER_CONFIG, variables);
}

export default function useAccountPreferredConfig() {
  const { data: userData } = useUserDetails();
  const hasActivePreferredOffer = usePreferredOfferIsActive();

  const accountId = userData?.me?.account?.id;

  return useSuspenseQuery({
    queryKey: ['preferred-offer-config', accountId, hasActivePreferredOffer],
    queryFn: () =>
      !!accountId && hasActivePreferredOffer ? fetchPreferredOfferConfig({ accountId: accountId! }) : null,
    select: (data) => data?.getPreferredOfferConfig,
  });
}
