import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

export type CreateBuyersLinkingRequestsInput =
  paths['/buyers/linking-requests']['post']['requestBody']['content']['application/json'];

const useCreateBuyersLinkingRequests = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['create-buyers-linking-requests'],
    mutationFn: async (data: CreateBuyersLinkingRequestsInput) => {
      const response = await apiClient
        .post('api/supplier-experience/buyers/linking-requests', {
          json: data,
        })
        .json<paths['/buyers/linking-requests']['post']['responses']['200']['content']['application/json']>();

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['buyers-linking-requests'],
      });
    },
  });
};

export default useCreateBuyersLinkingRequests;
