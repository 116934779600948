/**
 * Takes a number string expected to be a currency amount in any locale & converts to number format compatible with expected domain rate format
 * 3,111.10 becomes 3111.10
 * 9.000,00 becomes 9000.00
 */
const localeCurrencyAmountToDomain = (value: string | null) => {
  return value
    ? value
        // remove all commas/periods if they occur before 3 or more numbers
        // i.e. would change 3.111 to 3111 but would leave 3.11 or 3.1 as is
        .replace(/[.,](?=\d{3,})/g, '')
        .replace(/\s/g, '') // remove all whitespace
        .replace(/,/g, '.') // replace last comma with period for intl decimals if applicable
    : null;
};

export default localeCurrencyAmountToDomain;
