import { Outlet, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LabelWithBadge from '@/enterprise/components/LabelWithBadge';
import { MarketLayoutOutletContext } from '@/enterprise/components/MarketLayout';
import { usePendingClears } from '@/enterprise/data/useClearHistory';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useFeature from '@/lib/features';
import LinkTabs from '@/shared/components/LinkTabs';
import QueryBoundaries from '@/shared/components/QueryBoundaries';

export interface MarketHistoryLayoutOutletContext {
  readonly takerMarket?: TakerMarket;
  readonly urlParams?: {
    marketId: string;
    takerId: number;
  };
}
export function useMarketHistoryLayoutOutletContext() {
  return useOutletContext<MarketHistoryLayoutOutletContext>();
}

const MarketHistoryLayoutComponent = () => {
  const { t } = useTranslation();
  const marketContext = useOutletContext<MarketLayoutOutletContext>();
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');

  const { data: pendingData } = usePendingClears(marketContext.urlParams.takerId, marketContext.urlParams.marketId, {
    order: 'payDate',
    orderDirection: 'desc',
  });

  const outlet = (
    <Outlet
      {...(marketContext && {
        context: {
          takerMarket: marketContext.takerMarket,
          urlParams: {
            marketId: marketContext.urlParams.marketId,
            takerId: marketContext.urlParams.takerId,
          },
        } satisfies MarketHistoryLayoutOutletContext,
      })}
    />
  );

  if (!enableAwardsEnhancement) {
    return outlet;
  }

  return (
    <div>
      <div className="rounded-md bg-white shadow-md">
        <div className="border-b px-4">
          <LinkTabs
            items={[
              {
                key: 'approved',
                label: t('core.approved'),
                link: 'approved',
                matcher: 'approved',
              },
              {
                key: 'pending',
                label: (
                  <LabelWithBadge label={t('core.pendingApproval')} badgeCount={pendingData && pendingData.length} />
                ),
                link: 'pending',
                matcher: 'pending',
              },
            ]}
            variant="secondary"
          />
        </div>
        {outlet}
      </div>
    </div>
  );
};

const MarketHistoryLayout = () => (
  <QueryBoundaries>
    <MarketHistoryLayoutComponent />
  </QueryBoundaries>
);

export default MarketHistoryLayout;
