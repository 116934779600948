import { cn } from '@c2fo/liquidity';
import { Customer } from '@/enterprise/data/useCustomers';

const CustomerCard = ({
  customer,
  onClick,
  selected,
}: {
  customer: Customer;
  onClick: () => void;
  selected: boolean;
}) => (
  <button
    className={cn(
      'flex flex-col items-center rounded-md border-2 border-transparent bg-white p-4 shadow-md transition-all duration-200',
      { 'border-primary-500': selected },
      { 'hover:shadow-xl': !selected }
    )}
    onClick={onClick}
    type="button"
  >
    <div
      className="h-32 w-32 bg-contain bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${customer.imageUrl})`,
      }}
    />
    <div className="pt-2">{customer.name}</div>
  </button>
);

export default CustomerCard;
