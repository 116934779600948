import { useTranslation } from 'react-i18next';
import { BenchmarkRateInfo } from '@/generated/gql/graphql';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import handleNaN from '@/utils/handleNaN';
import useLocaleFormat from '@/utils/useLocaleFormat';

interface VariableRateDetails {
  formattedDiscountAmount?: string;
  formattedEstimatedRate?: string;
  estimatedRate?: number;
  formattedReferenceRate?: string;
  formattedSpread?: string;
  rateLabel?: string;
  rateName?: string;
}

const defaultVariableRateDetails: VariableRateDetails = {
  formattedDiscountAmount: undefined,
  formattedEstimatedRate: undefined,
  estimatedRate: undefined,
  formattedReferenceRate: undefined,
  formattedSpread: undefined,
  rateLabel: undefined,
  rateName: undefined,
};

const useVariableRateDetails = () => {
  const { t } = useTranslation();
  const { asCurrency, asPercent } = useLocaleFormat();

  const getVariableRateDetails = (takerMarket: TakerMarket | null): VariableRateDetails => {
    if (!takerMarket) {
      return { ...defaultVariableRateDetails };
    }

    const rateInfo = takerMarket.rateInfo as BenchmarkRateInfo;

    const eligibleDpeWeightedSum = takerMarket.eligibleDpeWeightedSum ?? 0;
    const eligibleInvoiceAmount = takerMarket.eligibleInvoiceAmount ?? 0;
    const estimatedRate = rateInfo?.estimatedRate ?? 0;
    const rateName = rateInfo?.rateName;
    const referenceRate = rateInfo?.referenceRate ?? 0;
    const totalSpread = rateInfo?.totalSpread ?? 0;

    // convert APR to discount to calculate discount amount
    const weightedAverageDpe = eligibleDpeWeightedSum / eligibleInvoiceAmount;
    const equivalentDisc = (estimatedRate ?? 0) * (weightedAverageDpe / 360);
    const discountAmount = eligibleInvoiceAmount * (equivalentDisc / 100);

    const formattedDiscountAmount = asCurrency(handleNaN(0 - discountAmount), takerMarket.currency);
    const formattedEstimatedRate = `${asPercent({ value: handleNaN(estimatedRate / 100), decimals: 2 })} ${t(
      'core.apr'
    )}`;
    const formattedReferenceRate = asPercent({ value: handleNaN(referenceRate / 100), decimals: 2 });
    const formattedSpread = asPercent({ value: handleNaN(totalSpread / 100), decimals: 2 });

    const rateLabel = rateName ? `${rateName} + ${formattedSpread}` : `${formattedSpread}`;

    return {
      ...defaultVariableRateDetails,
      formattedDiscountAmount,
      formattedEstimatedRate,
      estimatedRate,
      formattedReferenceRate,
      formattedSpread,
      rateLabel,
      rateName,
    };
  };

  return {
    getVariableRateDetails,
  };
};

export default useVariableRateDetails;
