import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import cloudDownload from '@/enterprise/assets/clouddownload.svg?url';
import { useToken } from '@/utils/token';

export interface RequestCreditNoteModalProps {
  open: boolean;
  onClose: () => void;
}

const RequestCreditNoteModal = ({ open, onClose }: RequestCreditNoteModalProps) => {
  const { t } = useTranslation();
  const { tokenContent } = useToken();
  const email = tokenContent?.payload.user.emailAddress;

  return (
    <Modal onClose={onClose} open={open} size="sm">
      <ModalTitleInfo>{t('maker.requestArchivePendingHeader')}</ModalTitleInfo>
      <ModalContent center>
        <img src={cloudDownload} alt="download" className="h-24 w-24" />
        <div className="sm:max-w-md">{t('maker.requestArchivePendingText', { email })}</div>
      </ModalContent>
      <ModalActions className="sm:justify-center">
        <Button variant="primary" onClick={onClose} className="sm:w-1/2">
          {t('core.close')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default RequestCreditNoteModal;
