import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Calendar, DateRange, Label, Popover, TextInput } from '@c2fo/liquidity';
import { parseDate } from '@/enterprise/features/history/utils';
import useLocaleFormat from '@/utils/useLocaleFormat';

// Function to validate the selected "from" and "to" range
const isValidDateRange = ({ from, to }: InputRange) => {
  if (!from || !to) {
    return false;
  }

  // Validate "from" and "to" dates are both able to be parsed as valid dates
  const validDate = from && !isNaN(Date.parse(from)) && to && !isNaN(Date.parse(to));

  // Validate the "from" date is less than or equal to the "to" date
  const validRange = from && to && from <= to;

  return !!(validDate && validRange);
};

interface StringDateRange {
  startDate: string;
  endDate: string;
}

interface InputRange {
  from?: string;
  to?: string;
}

export interface RangePickerProps {
  /**
   * The class name to apply to the root element.
   */
  className?: string;
  /**
   * The value of the date range.
   */
  value: StringDateRange;
  /**
   * Event fired when the date range changes.
   */
  onChange: (range: StringDateRange | undefined) => void;
  /**
   * Whether the popover is open. This is controlled external
   * to allow for it to be programmatically closed in certain scenarios.
   */
  open?: boolean;
  /**
   * Event fired when the popover is opened or closed.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Element used for triggering the RangerPicker. Defaults to "Custom Range".
   */
  trigger?: ReactNode;
}

/**
 * A component that allows the user to select a custom date range.
 */
export default function RangePicker({ value, onChange, className, open, onOpenChange, trigger }: RangePickerProps) {
  // NOTE: react-day-picker defines ranges with from/to properties.
  // Our legacy code uses startDate/endDate. This component
  // is a bridge between the two.
  const [dateRange, setDateRange] = useState<InputRange | undefined>();
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();

  const calendarRange = {
    from: dateRange?.from ? parseDate(dateRange.from) : undefined,
    to: dateRange?.to ? parseDate(dateRange.to) : undefined,
  };

  // Update the date range values when the outside value changes
  useEffect(() => {
    setDateRange({ from: value.startDate, to: value.endDate });
  }, [value]);

  // Validates the text date range whenever it changes
  useEffect(() => {
    setIsValid(isValidDateRange(dateRange ?? {}));
  }, [dateRange]);

  const { asISODateString } = useLocaleFormat();
  const handleInputChange = (type: keyof DateRange) => (e: ChangeEvent<HTMLInputElement>) => {
    const dateValue = e.currentTarget.value;
    // Update the date range state for from and to text components
    setDateRange((prevState) => ({
      ...prevState,
      [type]: dateValue,
    }));
  };

  const handleRangeSelect = (range: DateRange | undefined) => {
    // Update the "from" and "to" date range based on the dates selected in Calendar component
    const from = range?.from ? asISODateString(range.from) : '';
    const to = range?.to ? asISODateString(range.to) : '';

    setDateRange({
      from,
      to,
    });
  };

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger className={className}>
        {trigger ?? <span className="capitalize">{t('core.customRange')}</span>}
      </Popover.Trigger>
      <Popover.Content align="start" className="p-4" sideOffset={5} variant="light">
        <div className="flex-col: flex flex-nowrap gap-2 sm:flex-row">
          <div className="flex flex-col gap-3">
            <div className="space-y-1">
              <Label htmlFor="from" className="capitalize">
                {t('core.from')}
              </Label>
              <TextInput
                id="from"
                name="from"
                onChange={handleInputChange('from')}
                pattern="\d{2}/\d{2}/\d{4}"
                size="sm"
                type="text"
                value={dateRange?.from}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="to" className="capitalize">
                {t('core.to')}
              </Label>
              <TextInput
                id="to"
                name="to"
                onChange={handleInputChange('to')}
                pattern="\d{2}/\d{2}/\d{4}"
                size="sm"
                type="text"
                value={dateRange?.to}
              />
            </div>
            <div className="flex justify-start gap-2">
              <Button variant="secondary" size="sm" type="button" onClick={() => onOpenChange?.(false)}>
                {t('core.cancel')}
              </Button>
              <Button
                variant="primary"
                size="sm"
                type="button"
                disabled={!isValid}
                onClick={() => {
                  onChange({
                    startDate: dateRange?.from ? asISODateString(dateRange.from) : '',
                    endDate: dateRange?.to ? asISODateString(dateRange.to) : '',
                  });
                  onOpenChange?.(false);
                }}
              >
                {t('core.apply')}
              </Button>
            </div>
          </div>
          <div>
            <Calendar
              mode="range"
              numberOfMonths={2}
              onSelect={handleRangeSelect}
              pagedNavigation
              selected={calendarRange}
            />
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
}
