import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@tanstack/react-query';
import useUserDetails from '@/shared/data/useUserDetails';

const validImageUrl = (url: string) => {
  const img = new Image();
  img.src = url;

  return new Promise<boolean>((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

const useSrmImage = (url: string) =>
  useSuspenseQuery({
    queryKey: ['srm-image', url],
    queryFn: () => validImageUrl(url),
  });

const fallbackImage =
  'https://c2fo-static.s3-us-west-2.amazonaws.com/app/srm-images/srm-transparent/customerservice@c2fo.com.png';

const useSrm = () => {
  const { t } = useTranslation();
  const { data: srm } = useUserDetails((userDetails) => {
    const srm = userDetails.me?.account?.srm;
    return srm && srm.emailAddress
      ? {
          calendarLink: srm?.calendarLink ?? null,
          emailAddress: srm.emailAddress,
          image: `https://c2fo-static.s3-us-west-2.amazonaws.com/app/srm-images/srm-transparent/${srm.emailAddress}.png`,
          name: srm?.firstName && srm?.lastName ? `${srm.firstName} ${srm.lastName}` : null,
          phoneNumber: srm?.phoneNumber ? srm.phoneNumber : null,
        }
      : {
          calendarLink: 'https://outlook.office365.com/owa/calendar/C2FOSupplierSupportTeam@c2fo.com/bookings/',
          emailAddress: 'support-amer@c2fo.com',
          image: fallbackImage,
          name: t('srm.accountSupport'),
          phoneNumber: '(866) 463-6565',
        };
  });

  const { data: validSrmImageUrl = false } = useSrmImage(srm?.image ?? fallbackImage);

  const image = validSrmImageUrl && srm?.image ? srm.image : fallbackImage;

  return { ...srm, image };
};

export default useSrm;
