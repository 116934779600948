import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Checkbox, cn, Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import ChecklistFilter from '@/enterprise/components/ChecklistFilter';
import Skeleton from '@/enterprise/components/Skeleton';
import { Table, TableBody, TableDisplaySkeletonRows } from '@/enterprise/components/Table';
import TabSection from '@/enterprise/components/TabSection';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import HistoryChart, { HistoryChartDatum } from './HistoryChart';
import HistoryFilter from './HistoryFilter';
import HistoryStatsBar, { HistoryStatsBarProps } from './HistoryStatsBar';
import HistoryTable, { HistoryTableDatum } from './HistoryTable';

export interface HistoryProps<TAwarded extends HistoryTableDatum, TPending extends HistoryTableDatum> {
  /**
   * The currency to display the data in.
   */
  currency: string;
  /**
   * The start date of the date range.
   */
  startDate: string;
  /**
   * The end date of the date range.
   */
  endDate: string;
  /**
   * A callback that is called when the date range changes.
   */
  onDateRangeChange: (startDate: string, endDate: string, includeFuturePayDates: boolean) => void;
  /**
   * The sort to use for the table.
   */
  sort: string;
  /**
   * A callback that is called when the sort changes.
   */
  onSortChange: (sort: string) => void;
  /**
   * The page to display.
   */
  page: number;
  /**
   * A callback that is called when the page changes.
   */
  onPageChange: (page: number) => void;
  /**
   * The limit to use for the table.
   */
  limit: number;
  /**
   * A callback that is called when the limit changes.
   */
  onLimitChange: (limit: number) => void;
  /**
   * The customers to display. If provided, the customer filter will be displayed.
   * If not provided, the customer filter will not be displayed.
   */
  customers?: Array<{ value: string; label: string }>;
  /**
   * The selected customers.
   */
  selectedCustomers?: string[];
  /**
   * The callback that is called when the customer filter is changed.
   * It is called with the selected customers.
   */
  onCustomersChange?: (selected: string[]) => void;
  /**
   * The data to display in the chart.
   */
  chartData: HistoryChartDatum[];
  /**
   * The data to display in the awarded table.
   */
  awardedData?: TAwarded[];
  /**
   * The data to display in the pending table.
   */
  pendingData?: TPending[];
  /**
   * The stats to display in the stats bar.
   */
  stats?: HistoryStatsBarProps | undefined;
  /**
   * The type of history the table should display.
   */
  historyType: 'consolidated' | 'market';
  /**
   * The loading state of pending data set.
   */
  isFetchingPendingData: boolean;
  /**
   * The loading state of awarded data set.
   */
  isFetchingAwardedData: boolean;
  isPendingAwardedData: boolean;
  /**
   * The selected date range and filters returned award history.
   */
  rangeHasClearHistory: boolean;
  /**
   * Whether to include future pay dates in the returned history.
   */
  includeFuturePayDates: boolean;
  /**
   * callback that is called when the include future pay dates checkbox is changed
   */
  onIncludeFuturePayDatesChange: (includeFuturePayDates: boolean) => void;
}

/**
 * This component displays the history view for both the consolidated and the market history pages.
 * Each page provides their own data and callbacks.
 */
export default function History<TAwarded extends HistoryTableDatum, TPending extends HistoryTableDatum>({
  currency,
  startDate,
  endDate,
  onDateRangeChange,
  sort,
  onSortChange,
  page,
  onPageChange,
  limit,
  onLimitChange,
  chartData,
  awardedData = [],
  pendingData,
  stats,
  customers = [],
  selectedCustomers,
  onCustomersChange,
  historyType,
  isFetchingPendingData = false,
  isPendingAwardedData = false,
  isFetchingAwardedData = false,
  rangeHasClearHistory,
  includeFuturePayDates,
  onIncludeFuturePayDatesChange,
}: HistoryProps<TAwarded, TPending>) {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');
  const [disableFuturePayDates, setDisableFuturePayDates] = useState(false);

  return (
    <>
      <div className="flex">
        {/* FILTERS */}
        <div className="w-64 shrink-0 border-r">
          <div className="px-8 py-6">
            <div className="pb-4 text-lg">{t('core.filters')}</div>
            <div className="space-y-6">
              {/* history filter */}
              <div className="text-sm">
                <div className="pb-3 text-base font-medium">{t('taker.history.payDateRanges')}</div>
                <div className="space-y-3">
                  <HistoryFilter
                    value={{ startDate, endDate }}
                    onChange={({ range: { startDate, endDate } }) => {
                      const today = trimDateTimestamp(new Date().toISOString());

                      if (endDate < today) {
                        setDisableFuturePayDates(true);
                        onDateRangeChange(startDate, endDate, false);
                      } else {
                        setDisableFuturePayDates(false);
                        onDateRangeChange(startDate, endDate, true);
                      }

                      trackEnterpriseEvent('history-filter::clicked', { startDate, endDate });
                    }}
                  />
                </div>
              </div>
              {enableAwardsEnhancement && (
                <Checkbox
                  id={'includeFuturePayDates'}
                  color="primary"
                  label={
                    <span className="flex">
                      <span className={cn('text-sm', { 'text-text-disabled': disableFuturePayDates })}>
                        {t('taker.history.includeFuturePayDates')}
                      </span>
                      {disableFuturePayDates && (
                        <Popover>
                          <Popover.Trigger>
                            <InfoCircleIcon fill={colors.primary[500]} />
                          </Popover.Trigger>
                          <Popover.Content arrow side="bottom">
                            <div className="text-sm">{t('taker.history.includeFuturePayDatesDisabledLabel')}</div>
                          </Popover.Content>
                        </Popover>
                      )}
                    </span>
                  }
                  checked={includeFuturePayDates}
                  disabled={disableFuturePayDates}
                  onChange={(e) => onIncludeFuturePayDatesChange(e.target.checked)}
                />
              )}
              {!enableAwardsEnhancement && (
                <Alert
                  variant="outlined"
                  type="info"
                  title={t('core.didyouknow')}
                  description={t('core.futureAwardFilesDescription')}
                  icon={false}
                />
              )}
              {/* customer filter */}
              {!!customers?.length && (
                <div className="text-sm">
                  <div className="pb-3 font-medium">{t('core.customers')}</div>
                  <div className="space-y-3">
                    <ChecklistFilter
                      selected={selectedCustomers}
                      options={customers}
                      onChange={(selected) => {
                        onCustomersChange?.(selected);
                        trackEnterpriseEvent('history-customer::clicked');
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* TABLE */}
        <div className="flex w-full flex-col overflow-hidden">
          {rangeHasClearHistory && stats && (
            <div>
              <HistoryStatsBar
                clearedDiscountWeightedAvg={stats.clearedDiscountWeightedAvg}
                clearedEarn={stats.clearedEarn}
                clearedInvoiceAmount={stats.clearedInvoiceAmount}
                currency={currency}
              />
              <div className="border-y p-6">
                <HistoryChart data={chartData} currency={currency} />
              </div>
            </div>
          )}
          {!enableAwardsEnhancement && (
            <TabSection>
              {!!pendingData?.length && (
                <TabSection.Tab name="pending" title={t('core.pending')}>
                  <QueryBoundaries LoadingComponent={() => <Skeleton />}>
                    <HistoryTable
                      data={pendingData}
                      currency={currency}
                      sort={sort}
                      setSort={onSortChange}
                      pageIndex={page}
                      pageSize={limit}
                      onPageChange={onPageChange}
                      onPageSizeChange={onLimitChange}
                      displayType="pending"
                      historyType={historyType}
                      loading={isFetchingPendingData}
                    />
                  </QueryBoundaries>
                </TabSection.Tab>
              )}
              <TabSection.Tab name="awarded" title={t('core.awarded')}>
                <QueryBoundaries LoadingComponent={() => <Skeleton />}>
                  {isPendingAwardedData ? (
                    <Table>
                      <TableBody>
                        <TableDisplaySkeletonRows colSpan={1} />
                      </TableBody>
                    </Table>
                  ) : (
                    <HistoryTable
                      data={awardedData}
                      currency={currency}
                      sort={sort}
                      setSort={onSortChange}
                      pageIndex={page}
                      pageSize={limit}
                      onPageChange={onPageChange}
                      onPageSizeChange={onLimitChange}
                      displayType="awarded"
                      historyType={historyType}
                      loading={isFetchingAwardedData}
                      customerFilterSelected={selectedCustomers && selectedCustomers.length > 0}
                    />
                  )}
                </QueryBoundaries>
              </TabSection.Tab>
            </TabSection>
          )}
          {enableAwardsEnhancement && (
            <QueryBoundaries LoadingComponent={() => <Skeleton />}>
              {isPendingAwardedData ? (
                <Table>
                  <TableBody>
                    <TableDisplaySkeletonRows colSpan={1} />
                  </TableBody>
                </Table>
              ) : (
                <HistoryTable
                  data={awardedData}
                  currency={currency}
                  sort={sort}
                  setSort={onSortChange}
                  pageIndex={page}
                  pageSize={limit}
                  onPageChange={onPageChange}
                  onPageSizeChange={onLimitChange}
                  displayType="awarded"
                  historyType={historyType}
                  loading={isFetchingAwardedData}
                  customerFilterSelected={selectedCustomers && selectedCustomers.length > 0}
                />
              )}
            </QueryBoundaries>
          )}
        </div>
      </div>
    </>
  );
}
