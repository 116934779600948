import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@c2fo/liquidity';
import { TechnicalDifficultyIcon } from '@c2fo/liquidity/icons';
import invoices from '@/enterprise/assets/invoicesgrey.svg?url';
import { useInvoiceLayoutOutletContext } from '@/enterprise/components/InvoiceLayout';
import NoDataState from '@/enterprise/components/NoDataState';
import { TableSortDirection } from '@/enterprise/components/Table';
import {
  InvoiceAdjustmentFetchFilters,
  InvoiceAdjustmentFetchOptions,
  InvoiceAdjustmentSort,
} from '@/enterprise/data/useInvoiceAdjustments';
import InvoiceAdjustmentTable from '@/enterprise/features/invoiceAdjustmentTable/InvoiceAdjustmentTable';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import addOrRemoveFromArray from '@/utils/addOrRemoveFromArray';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useUrlState from '@/utils/useUrlState';

export type InvoiceAdjusmentSortKey = Exclude<InvoiceAdjustmentSort, `-${string}`>;

export interface InvoiceAdjusmentTableSort {
  key: InvoiceAdjusmentSortKey;
  direction: TableSortDirection;
}

const InvoiceAdjustments = () => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const { asCurrency } = useLocaleFormat();
  const [urlState, setUrlState] = useUrlState<InvoiceAdjustmentFetchOptions>({
    page: { page: 1, limit: 100 },
    sort: 'amount',
  });
  const { takerMarket } = useInvoiceLayoutOutletContext();

  const sort: InvoiceAdjusmentTableSort = {
    key: (urlState.sort?.replace(/^-/, '') as InvoiceAdjusmentSortKey) ?? 'amount',
    direction: urlState.sort?.includes('-') ? 'desc' : 'asc',
  };

  const handleSort = (key: InvoiceAdjusmentSortKey) => {
    setUrlState((f) => ({
      ...f,
      sort: f.sort === `-${key}` ? key : `-${key}`,
      page: {
        ...f.page!,
        page: 1,
      },
    }));

    trackEnterpriseEvent('invoice-sort::clicked', { key });
  };

  const handleFilter = ({ key, value }: { key: keyof InvoiceAdjustmentFetchFilters; value: string }) => {
    setUrlState((f) => ({
      ...f,
      filter: {
        ...f.filter,
        [key]: addOrRemoveFromArray(urlState.filter?.[key] ?? [], value),
      },
      page: {
        ...f.page!,
        page: 1,
      },
    }));

    trackEnterpriseEvent('invoice-filter::clicked', { key, value });
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = Number(e.target.value);

    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, limit, page: 1 },
    }));

    trackEnterpriseEvent('invoice-page-size::clicked', { size: limit });
  };

  const handleNextChange = () => {
    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, page: f.page!.page + 1 },
    }));

    trackEnterpriseEvent('invoice-page::clicked');
  };

  const handlePrevChange = () => {
    setUrlState((f) => ({
      ...f,
      page: { ...f.page!, page: f.page!.page - 1 },
    }));

    trackEnterpriseEvent('invoice-page::clicked');
  };

  return (
    <div className="flex border-t">
      {/* FILTERS */}
      <div className="w-44 shrink-0 border-r lg:w-64">
        <div className="px-8 py-6">
          <div className="pb-6 font-medium">{t('core.filters')}</div>
          <div className="space-y-6">
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.status')}</div>
              <div className="space-y-3">
                <Checkbox
                  checked={(urlState?.filter?.status ?? []).includes('matched')}
                  color="primary"
                  id="matched"
                  label={t('taker.invoices.adjustment.statusMatched')}
                  onChange={() => handleFilter({ key: 'status', value: 'matched' })}
                  value="matched"
                />
                <Checkbox
                  checked={(urlState?.filter?.status ?? []).includes('unmatched')}
                  color="primary"
                  id="unmatched"
                  label={t('taker.invoices.adjustment.statusUnmatched')}
                  onChange={() => handleFilter({ key: 'status', value: 'unmatched' })}
                  value="unmatched"
                />
              </div>
            </div>
            <div className="text-sm">
              <div className="pb-3 font-medium">{t('core.adjustmentAmount')}</div>
              <div className="space-y-3">
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('-25000,')}
                  color="primary"
                  id="-25000,"
                  label={t('core.rangeFilterLowest', { max: `(${asCurrency(25000, takerMarket.currency)})` })}
                  onChange={() => handleFilter({ key: 'amount', value: '-25000,' })}
                  value="-25000,"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('-50000,-25000')}
                  color="primary"
                  id="-50000,-25000"
                  label={t('core.rangeFilterMiddle', {
                    min: `(${asCurrency(25000, takerMarket.currency)})`,
                    max: `(${asCurrency(50000, takerMarket.currency)})`,
                  })}
                  onChange={() => handleFilter({ key: 'amount', value: '-50000,-25000' })}
                  value="-50000,-25000"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes('-75000,-50000')}
                  color="primary"
                  id="-75000,-50000"
                  label={t('core.rangeFilterMiddle', {
                    min: `(${asCurrency(50000, takerMarket.currency)})`,
                    max: `(${asCurrency(75000, takerMarket.currency)})`,
                  })}
                  onChange={() => handleFilter({ key: 'amount', value: '-75000,-50000' })}
                  value="-75000,-50000"
                />
                <Checkbox
                  checked={(urlState?.filter?.amount ?? []).includes(',-75000')}
                  color="primary"
                  id=",-75000"
                  label={t('core.rangeFilterHighest', { min: `(${asCurrency(75000, takerMarket.currency)})` })}
                  onChange={() => handleFilter({ key: 'amount', value: ',-75000' })}
                  value=",-75000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TABLE */}
      <div className="flex w-full flex-col overflow-auto">
        <QueryBoundaries
          ErrorComponent={() => (
            <NoDataState
              icon={<TechnicalDifficultyIcon className="h-24 w-24" />}
              title={t('invoices.errorLoadingInvoices')}
              message={t('invoices.errorLoadingInvoicesDescription')}
              action={
                <Button size="sm" onClick={() => window.location.reload()}>
                  {t('somethingsWrong.cta1Heading')}
                </Button>
              }
            />
          )}
          LoadingComponent={() => (
            <NoDataState
              icon={<img src={invoices} alt="invoices" className="h-28 w-28" />}
              message={t('invoices.fetchingInvoiceAdjustments')}
            />
          )}
        >
          <InvoiceAdjustmentTable
            handleSort={handleSort}
            options={urlState}
            pagination={{
              currentPage: urlState.page?.page ?? 1,
              onNextChange: handleNextChange,
              onPageSizeChange: (e) => handlePageSizeChange(e),
              onPrevChange: handlePrevChange,
              pageSize: urlState.page?.limit ?? 100,
            }}
            sort={sort}
            takerMarket={takerMarket}
          />
        </QueryBoundaries>
      </div>
    </div>
  );
};

export default InvoiceAdjustments;
