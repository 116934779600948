import { useSuspenseQuery } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

export interface MarketClearTimeData {
  marketUuid: string;
  supplierDivisionUuid: string;
  marketNextClearTime: string | null;
}

const fetchMarketClearTimes = async () => {
  const response = await apiClient
    .get(`api/supplier-experience/supplier-markets`)
    .json<paths['/supplier-markets']['get']['responses']['200']['content']['application/json']>();

  const allSupplierMarkets = response.data.supplierMarketGroups
    .map((smg) => smg.supplierMarkets[0])
    .map((sm) => {
      return {
        marketUuid: sm.market.uuid,
        supplierDivisionUuid: sm.supplier.uuid,
        marketNextClearTime: sm.market.nextClearTime,
      } satisfies MarketClearTimeData;
    });

  return allSupplierMarkets;
};

const useMarketClearTimes = <TData = MarketClearTimeData[]>(select?: (data: MarketClearTimeData[]) => TData) => {
  return useSuspenseQuery({
    queryKey: ['market-next-clear-time'],
    queryFn: () => fetchMarketClearTimes(),
    select,
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });
};

export default useMarketClearTimes;
