/**
 * feature flags namespace "enterprise-ui_"
 */
export const Features = {
  'enterprise-ui_enableSetExpirationDate': 'enterprise-ui_enableSetExpirationDate',
  'enterprise-ui_enableRecurringRules': 'enterprise-ui_enableRecurringRules',
  'enterprise-ui_enableRecurringRulesForAccounts': 'enterprise-ui_enableRecurringRulesForAccounts',
  'enterprise-ui_fedRateBanner': 'enterprise-ui_fedRateBanner',
  'enterprise-ui_enableSeaReads': 'enterprise-ui_enableSeaReads',
  'enterprise-ui_enableSeaWrites': 'enterprise-ui_enableSeaWrites',
  'enterprise-ui_enableSeaDraftOffers': 'enterprise-ui_enableSeaDraftOffers',
  'enterprise-ui_enablePendingAndFutureAwardsEnhancement': 'enterprise-ui_enablePendingAndFutureAwardsEnhancement',
  'enterprise-ui_enableSeaFindYourCustomer': 'enterprise-ui_enableSeaFindYourCustomer',
  'enterprise-ui_enableAccountSettings': 'enterprise-ui_enableAccountSettings',
  'enterprise-ui_enableSmbExperience': 'enterprise-ui_enableSmbExperience',
} as const;

/**
 * treatment definitions by env
 * utilized for determining how to serve treatment if split fails to load
 */
const features =
  import.meta.env.VITE_ENV === 'prod'
    ? {
        [Features['enterprise-ui_enableSetExpirationDate']]: 'off',
        [Features['enterprise-ui_enableRecurringRules']]: 'off',
        [Features['enterprise-ui_enableRecurringRulesForAccounts']]: 'off',
        [Features['enterprise-ui_fedRateBanner']]: 'off',
        [Features['enterprise-ui_enableSeaReads']]: 'on',
        [Features['enterprise-ui_enableSeaWrites']]: 'off',
        [Features['enterprise-ui_enableSeaDraftOffers']]: 'off',
        [Features['enterprise-ui_enablePendingAndFutureAwardsEnhancement']]: 'off',
        [Features['enterprise-ui_enableSeaFindYourCustomer']]: 'off',
        [Features['enterprise-ui_enableAccountSettings']]: 'off',
        [Features['enterprise-ui_enableSmbExperience']]: 'off',
      }
    : {
        [Features['enterprise-ui_enableSetExpirationDate']]: 'on',
        [Features['enterprise-ui_enableRecurringRules']]: 'on',
        [Features['enterprise-ui_enableRecurringRulesForAccounts']]: 'on',
        [Features['enterprise-ui_fedRateBanner']]: 'on',
        [Features['enterprise-ui_enableSeaReads']]: 'on',
        [Features['enterprise-ui_enableSeaWrites']]: 'on',
        [Features['enterprise-ui_enableSeaDraftOffers']]: 'on',
        [Features['enterprise-ui_enablePendingAndFutureAwardsEnhancement']]: 'on',
        [Features['enterprise-ui_enableSeaFindYourCustomer']]: 'on',
        [Features['enterprise-ui_enableAccountSettings']]: 'on',
        [Features['enterprise-ui_enableSmbExperience']]: 'on',
      };

export default features;
