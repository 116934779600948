import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { gqlClient } from '@/lib/gqlClient';

const OPT_OUT_ALL_COMMUNICATION_PREFERENCES = graphql(`
  mutation OptOutAllCommunicationPreferences {
    optOutAllCommunicationPreferences {
      channels
    }
  }
`);

const useOptOutAllCommunicationPreferences = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['optOutAllCommunicationPreferences'],
    mutationFn: () => {
      return gqlClient.request(OPT_OUT_ALL_COMMUNICATION_PREFERENCES);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['communication-preferences'],
      });
    },
  });
};

export default useOptOutAllCommunicationPreferences;
