import { useTranslation } from 'react-i18next';
import { RecurringRuleCategory, RecurringRuleCriteria } from '@/enterprise/data/useRecurringRules';

interface RuleFormContent {
  description: string;
  existingAlert: string;
  existingTitle: string;
  options: {
    label: string;
    value: RecurringRuleCriteria;
  }[];
  review: Partial<Record<RecurringRuleCriteria, string>>;
}

const useRuleFormContent = () => {
  const { t } = useTranslation();

  const ruleFormContent: Record<RecurringRuleCategory, RuleFormContent> = {
    amount: {
      description: t('recurringRules.invoiceAmount.description'),
      existingAlert: 'recurringRules.invoiceAmount.existingAlert',
      existingTitle: 'recurringRules.invoiceAmount.existingTitle',
      options: [
        { label: t('recurringRules.invoiceAmount.optionGreater.label'), value: 'gteInvoiceAmount' },
        { label: t('recurringRules.invoiceAmount.optionLess.label'), value: 'lteInvoiceAmount' },
      ],
      review: {
        gteInvoiceAmount: 'recurringRules.invoiceAmount.optionGreater.review.description',
        lteInvoiceAmount: 'recurringRules.invoiceAmount.optionLess.review.description',
      },
    },
    dpe: {
      description: t('recurringRules.dpe.description'),
      existingAlert: 'recurringRules.dpe.existingAlert',
      existingTitle: 'recurringRules.dpe.existingTitle',
      options: [
        { label: t('recurringRules.dpe.optionGreater.label'), value: 'gteDpe' },
        { label: t('recurringRules.dpe.optionLess.label'), value: 'lteDpe' },
      ],
      review: {
        gteDpe: 'recurringRules.dpe.optionGreater.review.description',
        lteDpe: 'recurringRules.dpe.optionLess.review.description',
      },
    },
    dueDate: {
      description: t('recurringRules.dueDate.description'),
      existingAlert: 'recurringRules.dueDate.existingAlert',
      existingTitle: 'recurringRules.dueDate.existingTitle',
      options: [
        { label: t('recurringRules.dueDate.optionLess.label'), value: 'toDueDate' },
        { label: t('recurringRules.dueDate.optionGreater.label'), value: 'fromDueDate' },
      ],
      review: {
        fromDueDate: 'recurringRules.dueDate.optionGreater.review.description',
        toDueDate: 'recurringRules.dueDate.optionLess.review.description',
      },
    },
    invoiceId: {
      description: t('recurringRules.invoiceId.description'),
      existingAlert: 'recurringRules.invoiceId.existingAlert',
      existingTitle: 'recurringRules.invoiceId.existingTitle',
      options: [{ label: t('recurringRules.invoiceId.option.label'), value: 'excludedVoucherIds' }],
      review: {
        excludedVoucherIds: 'recurringRules.invoiceId.review.description',
      },
    },
  };

  const getRuleFormContent = (category: RecurringRuleCategory): RuleFormContent => ruleFormContent[category];

  return { getRuleFormContent };
};

export default useRuleFormContent;
