import { useCallback } from 'react';
import { GetMeQuery } from '@/generated/gql/graphql';
import useUserDetails from '@/shared/data/useUserDetails';
import { isProd } from '@/utils/env';
import { useToken } from '@/utils/token';

/**
 * Suppliers that log in with new experience enabled on their account configuration
 * should have a limited experience in the enterprise app. However, internal users or
 * impersonation sessions do not have the same limitations.
 */
const useNewExperienceEnabled = () => {
  const isProdEnv = isProd();
  const { isImpersonationSession, isC2foUserSession } = useToken();
  const { data: newExperienceEnabled } = useUserDetails(
    useCallback((user: GetMeQuery) => user.me?.account?.newExperienceEnabled, [])
  );

  // only check NSE account config in PROD because account configs are minimal
  // in lower environments and we don't want to disable which experience is available
  // for certain use cases in lower environments
  if (isProdEnv) {
    if (isImpersonationSession || isC2foUserSession) {
      return false;
    }

    // if user does not have an account config, default new experience enabled to true
    return newExperienceEnabled ?? true;
  }

  return false;
};

export default useNewExperienceEnabled;
