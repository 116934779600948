import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  cn,
  HelperText,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
} from '@c2fo/liquidity';
import DatePicker from '@/enterprise/components/DatePicker';
import { RecurringRuleCriteria } from '@/enterprise/data/useRecurringRules';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import useRecurringRuleFormContext from '../utils/useRecurringRuleFormContext';
import useRuleFormContent from '../utils/useRuleFormContent';
import { RecurringRuleFormError } from '../utils/useValidateRuleForm';

interface CriteriaSelectProps {
  errors: RecurringRuleFormError[];
  setErrors: Dispatch<SetStateAction<RecurringRuleFormError[]>>;
  toCurrency: string;
}

const CriteriaSelect = ({ errors, setErrors, toCurrency }: CriteriaSelectProps) => {
  const { t } = useTranslation();
  const { control, getValues, watch } = useRecurringRuleFormContext();
  const [category, criteria] = getValues(['category', 'criteria']);
  const { fields, update } = useFieldArray({
    control,
    name: 'criteria',
  });
  const { getRuleFormContent } = useRuleFormContent();
  const [dueBeforeDatePickerOpen, setDueBeforeDatePickerOpen] = useState<boolean>(false);
  const [dueAfterDatePickerOpen, setDueAfterDatePickerOpen] = useState<boolean>(false);
  const { options: criteriaOptions } = getRuleFormContent(category);

  const toggleDatePicker = (ruleType: RecurringRuleCriteria) => {
    if (ruleType === 'toDueDate') {
      return setDueBeforeDatePickerOpen(!dueBeforeDatePickerOpen);
    }

    return setDueAfterDatePickerOpen(!dueAfterDatePickerOpen);
  };

  return (
    <div className="space-y-1">
      <div className="font-medium">{t('recurringRules.ruleCriteria')}</div>
      <div className="space-y-6">
        {fields.map((item, index) => {
          const hasError = errors.find((error) => error.type === criteria[index].type);

          return (
            <div key={item.id}>
              <div className={cn('flex flex-wrap items-center gap-4', { 'pb-6': category === 'invoiceId' })}>
                {/* display "and" for criteria other than the first one */}
                {index !== 0 && <div className="font-medium">{t('invoiceSettings.modal.ruleConjunction')}</div>}
                {/* rule type select */}
                <div className={cn('w-full grow lg:max-w-xl', { 'lg:max-w-lg': category === 'invoiceId' })}>
                  <Controller
                    control={control}
                    name={`criteria.${index}.type` as const}
                    render={({ field }) => (
                      <Select
                        onValueChange={(value) => {
                          const criteriaValue = value as RecurringRuleCriteria;

                          if (['fromDueDate', 'toDueDate'].includes(criteriaValue)) {
                            update(index, { type: criteriaValue, value: dateAsIsoString() });
                          } else {
                            update(index, { type: criteriaValue, value: '' });
                          }
                        }}
                        value={field.value}
                      >
                        <SelectTrigger aria-label={t('invoiceSettings.modal.criteriaSelect')}>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {criteriaOptions.map(({ label, value }) => (
                            <SelectItem key={value} value={value}>
                              {label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
                <div className="flex items-center gap-4">
                  {/* rule value input */}
                  <Controller
                    control={control}
                    name={`criteria.${index}.value`}
                    render={({ field }) => {
                      // return date picker for due date
                      if (category === 'dueDate') {
                        return (
                          <DatePicker
                            className="z-50"
                            onOpenChange={() => toggleDatePicker(criteria[index].type)}
                            onSelect={(date) => {
                              const dateString = dateAsIsoString(date);
                              field.onChange(dateString);
                              toggleDatePicker(criteria[index].type);
                            }}
                            open={
                              criteria[index].type === 'toDueDate' ? dueBeforeDatePickerOpen : dueAfterDatePickerOpen
                            }
                            selected={field.value}
                            trigger={
                              <div
                                aria-label={criteria[index].type}
                                className={cn(
                                  'flex h-12 w-32 items-center rounded border border-stroke bg-white px-4 py-3 text-text-primary',
                                  { 'border-red-600': hasError }
                                )}
                                role="button"
                              >
                                {field.value}
                              </div>
                            }
                            disabledOptions={{ before: new Date() }}
                          />
                        );
                      }

                      // return input for invoice id with character count
                      if (category === 'invoiceId') {
                        return (
                          <div className="relative">
                            <TextInput
                              {...field}
                              aria-label={criteria[index].type}
                              className="w-48"
                              hasError={!!hasError}
                              onChange={(e) => {
                                field.onChange(e.target.value);

                                if (e.target.value.length > 15) {
                                  setErrors([{ type: 'excludedVoucherIds', message: '' }]);
                                } else {
                                  setErrors(errors.filter((error) => error.type !== 'excludedVoucherIds'));
                                }
                              }}
                            />
                            <div className="absolute right-0 mt-1">
                              <HelperText {...(hasError && { variant: 'error' })}>
                                {watch('criteria')[index].value.length}/15
                              </HelperText>
                            </div>
                          </div>
                        );
                      }

                      return (
                        <TextInput
                          {...field}
                          aria-label={criteria[index].type}
                          className="w-32"
                          hasError={!!hasError}
                          onChange={(e) => {
                            field.onChange(e.target.value);

                            if (hasError) {
                              setErrors(errors.filter((error) => error.type !== criteria[index].type));
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {/* amount input addon */}
                  {category === 'amount' && <div>{toCurrency}</div>}
                  {/* dpe input addon */}
                  {category === 'dpe' && <div>{t('core.days')}</div>}
                  {/* invoice id input addon */}
                  {category === 'invoiceId' && <div>{t('core.characters')}</div>}
                </div>
              </div>
              {/* error message */}
              {hasError && hasError.message && <p className="mt-1 text-sm text-red-600">{hasError.message}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CriteriaSelect;
